export const VALIDATION_EMPTY = "EMPTY";

export const VALIDATION_EMAIL = "EMAIL";
export const VALIDATION_PHONE = "PHONE";
export const VALIDATION_INITIALS = "INITIALS";
export const VALIDATION_NAME = "NAME";
export const VALIDATION_COMPANY_NAME = "COMPANY_NAME";
export const VALIDATION_POSTAL_CODE = "POSTAL";
export const VALIDATION_RSIN = "RSIN";
export const VALIDATION_KVK = "KVK";
export const VALIDATION_FINANCE_DURATION = "FINANCE_DURATION";
export const VALIDATION_DEPOSIT_AMOUNT = "DEPOSIT_AMOUNT"
export const VALIDATION_LOAN_DURATION = "LOAN_DURATION";
export const VALIDATION_LOAN_PART_DURATION = "LOAN_PART_DURATION";
export const VALIDATION_LOAN_PART_VALUE = "LOAN_PART_VALUE";
export const VALIDATION_DESIRED_FINANCING = "DESIRED_FINANCING";