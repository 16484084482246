import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import { useKeycloak } from '@react-keycloak/web';
import { useState } from 'react';
import Background from 'components/background/Background';

function App() {
  const [getClassName, setClassName] = useState('intro_container intro_page');
  const { keycloak } = useKeycloak();
  const startClick = () => {
    setClassName('intro_container intro');
    setTimeout(() => {
      keycloak.login({ redirectUri: window.location.href + '/user_dashboard' });
    }, 1000);
  };

  return (
    <div id="app_base" className={getClassName} onClick={startClick}>
      <Background ellipseType="small" showBackground={true} />
    </div>
  );
}

export default App;
