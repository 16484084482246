import "./Footer.css";
import ellipse_mini_light_blue from "assets/ellipse_mini_light_blue.svg";
import ellipse_mini_dark_blue from "assets/ellipse_mini_dark_blue.svg";
import ellipse_mini_orange from "assets/ellipse_mini_orange.svg";
import { useTranslation } from "react-i18next";

type FooterProps = {
  value: number;
  clickOnNavStepHandler: (stepNumber: number) => void;
};

const Footer = ({ value, clickOnNavStepHandler }: FooterProps): JSX.Element => {
  const { t } = useTranslation(undefined, { keyPrefix: "headers" });

  return (
    <div className="footer">
      <div className="footer_container">
        <div className="footer_step">
          {getNavigationStep(0, value, clickOnNavStepHandler)}
          <p className="wizard_navigation_text">{t("applicants")}</p>
        </div>
        <div className="footer_step">
          {getNavigationStep(1, value, clickOnNavStepHandler)}
          <p className="wizard_navigation_text">{t("collaterals")}</p>
        </div>
        <div className="footer_step">
          {getNavigationStep(2, value, clickOnNavStepHandler)}
          <p className="wizard_navigation_text">{t("financing")}</p>
        </div>
      </div>
      <div className="wizard_progress_bar" />
      <div className={getProgressClass(value)}></div>
    </div>
  );
};

const getProgressClass = (currentWizardNumber: number): string => {
  return "wizard_progress_bar_progress_step_" + (currentWizardNumber + 1);
};

const getNavigationStep = (
  stepNumber: number,
  currentWizardNumber: number,
  clickOnNavStepHandler: (stepNumber: number) => void
) => {
  if (stepNumber === currentWizardNumber) {
    //current
    return navigationStepOrange(stepNumber);
  }
  if (stepNumber > currentWizardNumber) {
    //not yet done
    return navigationStepLightBlue(stepNumber);
  }
  if (stepNumber < currentWizardNumber) {
    //done
    return navigationStepDarkBlue(stepNumber, clickOnNavStepHandler);
  }
};

const navigationStepOrange = (stepNumber: number) => {
  return (
    <div className="wizard_navigation_image">
      <p className="wizard_navigation_step_number">{stepNumber + 1}</p>
      <img src={ellipse_mini_orange} alt="" />
    </div>
  );
};

const navigationStepLightBlue = (stepNumber: number) => {
  return (
    <div className="wizard_navigation_image">
      <p className="wizard_navigation_step_number">{stepNumber + 1}</p>
      <img src={ellipse_mini_light_blue} alt="" />
    </div>
  );
};

const navigationStepDarkBlue = (
  stepNumber: number,
  clickOnNavStepHandler: (stepNumber: number) => void
) => {
  return (
    <div
      className="wizard_navigation_image"
      onClick={() => clickOnNavStepHandler(stepNumber)}
    >
      <p className="wizard_navigation_step_number">{stepNumber + 1}</p>
      <img src={ellipse_mini_dark_blue} alt="" />
    </div>
  );
};

export default Footer;
