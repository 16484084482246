export const mapToKeycloakGroup = (role: string | undefined) => {
	switch (role) {
		case "admin":
			return "admins";
		case "dcmf-medewerker":
			return "dcmf-medewerkers";
		case "user":
		default:
			return "users";
	}
};
