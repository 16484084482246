import { CircularProgress } from '@mui/material';
import './modal.css';
import { useTranslation } from 'react-i18next';

export const AddUserModal = ({
  handleClose,
  handleSubmit,
  show,
  children,
  disabled,
}: {
  handleClose: any;
  handleSubmit: any;
  show: any;
  children: any;
  disabled: boolean;
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'user-management' });
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <h2 className="modal-title">{t('add-title')}</h2>
        <form>
          <div className="modal-content">{children}</div>
          <div className="modal-button-row">
            {disabled ? (
              <div className="modal-button-loading">
                <CircularProgress size={32} color="info" style={{ color: 'white' }} />
              </div>
            ) : (
              <input type="button" value={t('add-confirm')} className="modal-button-positive" onClick={handleSubmit} disabled={disabled} />
            )}
            <button className="modal-button-negative" type="button" onClick={handleClose} disabled={disabled}>
              {t('add-cancel')}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};
