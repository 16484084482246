export class RateDataModel {
  ltv: Number = 0;
  dscr: Number = 0;
  icr: Number = 0;
  maxLtv: Number = 0;
  minDscr: Number = 0;
  minIcr: Number = 0;
  ltvInterestOnly: number = 0;
  totalValue: number = 0;
}
