import { NAME_MAX_LENGTH, NAME_SUFFIX_DOTS } from 'constants/constants';
import { v4 as uuidv4 } from 'uuid';

export class PersonModel {
  gender: string = 'Man';
  initials: string = '';
  lastName: string = '';
  lastNameInsertion: string = '';
  birthday: string = '';
  nationality: string = 'NL';
  key: string = uuidv4();

  isSignee: string = 'NO_OPTION_SELECTED';
  isRelationPerson: boolean = false;
  isJointlyAndSeverallyLiable: string = 'Ja';
}
