import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './css/ObjectForm.css';
import React, { useEffect, useState } from 'react';
import InputField from 'components/form/InputField';
import RadioGroup from 'components/form/RadioGroup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { COLLATERAL_DATA } from 'constants/constants';
import { CollateralModel } from 'models/collateralModel';
import ValutaField from 'components/form/ValutaField';
import { notify } from 'components/toast/notify.component';
import { DefaultToastContainer } from 'components/toast/toast-container.component';
import { COLLATERAL_DROPDOWN_OPTIONS, COLLATERAL_DROPDOWN_OPTIONS_FREE_TARIFF, COLLATERAL_DROPDOWN_OPTIONS_SUBTYPES, COLLATERAL_RENTAL_CONTRACTS_COUNT } from 'constants/dropdownListData';
import Dropdown from 'components/form/Dropdown';
import { DropdownOptionsModel } from 'models/dropdownOptionsModel';
import {
  COLLATERAL_COMBINATION_NOT_ALLOWED,
  COLLATERAL_SUBTYPE_NOT_ALLOWED,
  COLLATERAL_TYPE_NOT_ALLOWED,
  COMMERCIAL_CONTRACT_RENTS_NOT_FILLED,
  COMMERCIAL_MARKET_VALUE_NOT_FILLED,
  COMMERCIAL_RENT_NOT_FILLED,
  FORM_ERROR_MESSAGE,
  GROUND_LEASE_END_DATE_NOT_FILLED,
  MARKET_RENT_NOT_FILLED,
  RESIDENTIAL_MARKET_VALUE_NOT_FILLED,
  RESIDENTIAL_RENT_NOT_FILLED,
  YEARLY_GROUND_LEASE_NOT_FILLED,
} from 'constants/messages/errorMessages';
import { VALIDATION_DEPOSIT_AMOUNT, VALIDATION_EMPTY } from 'constants/validationCheckTypes';
import { getSubTypeCollateralOptions, getTypeCollateralOptions } from 'helpers/helpers';
import AddressItem from 'components/form/AddressItem';
import Header from 'components/header/Header';

const ObjectCollateralForm = () => {
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();
  let indexForEdit = -1;
  let currentCollateral = new CollateralModel();
  let collaterals: CollateralModel[] = [];
  if (searchParams.get('index') != null) {
    indexForEdit = Number(searchParams.get('index'));
    var savedValues = sessionStorage.getItem(COLLATERAL_DATA) || '';

    if (savedValues !== '') {
      collaterals = JSON.parse(savedValues);
      currentCollateral = collaterals[indexForEdit];
    }
  }

  const [getZipcodeValue, setZipcodeValue] = useState<string>(currentCollateral.zipCode);
  const [getHouseNumberValue, setHouseNumberValue] = useState<string>(currentCollateral.houseNumber);
  const [getAdditionValue, setAdditionValue] = useState<string>(currentCollateral.addition);
  const [getStreetValue, setStreetValue] = useState<string>(currentCollateral.streetName);
  const [getCityValue, setCityValue] = useState<string>(currentCollateral.city);
  const [getBuildingStockValue, setBuildingStockValue] = useState<string>(currentCollateral.buildingStock);
  const [getBuildingStockAmountValue, setBuildingStockAmountValue] = useState<string>(currentCollateral.buildingStockAmount);
  const [getMarketValueValue, setMarketValueValue] = useState<string>(currentCollateral.marketValue);
  const [getBuyPriceValue, setBuyPriceValue] = useState<string>(currentCollateral.buyPrice);
  const [getTypeCollateralValue, setTypeCollateralValue] = useState<DropdownOptionsModel>(currentCollateral.typeCollateral);
  const [getSubTypeCollateralValue, setSubTypeCollateralValue] = useState<DropdownOptionsModel>(currentCollateral.subtypeCollateral);
  const [getRoleCollateralValue, setRoleCollateralValue] = useState<string>(currentCollateral.roleCollateral);
  const [getValueBeforeRenovationValue] = useState<string>(currentCollateral.valueBeforeRenovation);
  const [getMarketRentValue, setMarketRentValue] = useState<string>(currentCollateral.marketRent);
  const [getGroundLeaseValue, setGroundLeaseValue] = useState<string>(currentCollateral.groundLease);
  const [getGroundLeaseFeeValue, setGroundLeaseFeeValue] = useState<string>(currentCollateral.groundLeaseFee);
  const [getGroundLeaseEndDateValue, setGroundLeaseEndDateValue] = useState<string>(currentCollateral.groundLeaseEndDate);
  const [getBuyPriceDisabledValue, setBuyPriceDisabledValue] = useState<boolean>(currentCollateral.buyPriceDisabled);
  const [getBuildingStockRenovationCosts, setBuildingStockRenovationCosts] = useState<string>(
    currentCollateral.buildingStockRenovationCosts
  );
  const [getBuildingStockWorthAfterRenovation, setBuildingStockWorthAfterRenovation] = useState<string>(
    currentCollateral.buildingStockWorthAfterRenovation
  );
  const [getNumberOfRentalContracts, setNumberOfRentalContracts] = useState<number>(currentCollateral.numberOfRentalContracts);
  const [getLostThroughVacancy, setLostThroughVacancy] = useState<string>(currentCollateral.lostThroughVacancy);
  const [getResidentialMarketValue, setResidentialMarketValue] = useState<string>(currentCollateral.residentialMarketValue);
  const [getResidentialRentValue, setResidentialRentValue] = useState<string>(currentCollateral.residentialRentValue);
  const [getCommercialMarketValue, setCommercialMarketValue] = useState<string>(currentCollateral.commercialMarketValue);
  const [getCommercialRentValue, setCommercialRentValue] = useState<string>(currentCollateral.commercialRentValue);
  const [getRentalContractsOption, setRentalContractsOption] = useState<DropdownOptionsModel>(currentCollateral.rentalContractsOption);
  const [getRentalContractValues, setRentalContractValues] = useState<Array<string>>(currentCollateral.rentalContractValues);

  const typeCollateralList = getTypeCollateralOptions(false, indexForEdit !== -1, getSubTypeCollateralValue);
  const subTypeCollateralList = getSubTypeCollateralOptions(indexForEdit !== -1, getTypeCollateralValue);

  const validateForm = (): boolean => {
    let formValid = true;
    let allWithErrorClass = Array.from(document.getElementsByClassName('input_error'));

    if (allWithErrorClass.length > 0) {
      notify(FORM_ERROR_MESSAGE, false);
      formValid = false;
    }

    if (getSubTypeCollateralValue.isDisabled) {
      notify(COLLATERAL_SUBTYPE_NOT_ALLOWED, false)
      formValid = false;
    }

    if (getTypeCollateralValue.isDisabled) {
      notify(COLLATERAL_TYPE_NOT_ALLOWED, false)
      formValid = false;
    }

    if (getGroundLeaseValue === 'Ja') {
      if (getGroundLeaseFeeValue === '') {
        notify(YEARLY_GROUND_LEASE_NOT_FILLED, false);
        formValid = false;
      }

      if (getGroundLeaseEndDateValue === '') {
        notify(GROUND_LEASE_END_DATE_NOT_FILLED, false);
        formValid = false;
      }
    }

    if (getMarketRentValue === '' && showMarketValues()) {
      notify(MARKET_RENT_NOT_FILLED, false);
      formValid = false;
    }

    if (getTypeCollateralValue.label === COLLATERAL_DROPDOWN_OPTIONS[4].label) {
      if (getResidentialMarketValue === '') {
        notify(RESIDENTIAL_MARKET_VALUE_NOT_FILLED, false);
        formValid = false;
      }

      if (getCommercialMarketValue === '') {
        notify(RESIDENTIAL_RENT_NOT_FILLED, false);
        formValid = false;
      }

      if (getResidentialRentValue === '') {
        notify(COMMERCIAL_MARKET_VALUE_NOT_FILLED, false);
        formValid = false;
      }

      if (getCommercialRentValue === '') {
        notify(COMMERCIAL_RENT_NOT_FILLED, false);
        formValid = false;
      }

      if (getNumberOfRentalContracts > 0) {
        for (var i = 1; i <= getNumberOfRentalContracts; i++) {
          var value = getRentalContractValues[i];
          if (value === undefined) {
            notify(COMMERCIAL_CONTRACT_RENTS_NOT_FILLED);
            formValid = false;
          }
        }
      }
    }

    return formValid;
  };

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateForm()) {
      let collateralData = new CollateralModel();
      collateralData.zipCode = getZipcodeValue;
      collateralData.houseNumber = getHouseNumberValue;
      collateralData.addition = getAdditionValue;
      collateralData.streetName = getStreetValue;
      collateralData.city = getCityValue;
      collateralData.buildingStock = getBuildingStockValue;
      collateralData.buildingStockAmount = getBuildingStockAmountValue;
      collateralData.buildingStockDuration = getBuildingStockValue === 'Ja' ? '6' : '';
      collateralData.marketValue = getMarketValueValue;
      collateralData.buyPrice = getBuyPriceValue;
      collateralData.typeCollateral = getTypeCollateralValue;
      collateralData.subtypeCollateral = getSubTypeCollateralValue;
      collateralData.statusCollateral = getBuildingStockValue === 'Ja' ? 'RENEWAL' : 'EXISTING_BUILDING';
      collateralData.roleCollateral = getRoleCollateralValue;
      collateralData.valueBeforeRenovation = getValueBeforeRenovationValue;
      collateralData.buildingStockWorthAfterRenovation = getBuildingStockWorthAfterRenovation;
      collateralData.buildingStockRenovationCosts = getBuildingStockRenovationCosts;
      collateralData.marketRent = getMarketRentValue;
      collateralData.groundLease = getGroundLeaseValue;
      collateralData.groundLeaseFee = getGroundLeaseFeeValue;
      collateralData.groundLeaseEndDate = getGroundLeaseEndDateValue;
      collateralData.buyPriceDisabled = getBuyPriceDisabledValue;
      collateralData.lostThroughVacancy = getLostThroughVacancy;
      collateralData.residentialMarketValue = getResidentialMarketValue;
      collateralData.residentialRentValue = getResidentialRentValue;
      collateralData.commercialMarketValue = getCommercialMarketValue;
      collateralData.commercialRentValue = getCommercialRentValue;
      collateralData.rentalContractsOption = getRentalContractsOption;
      collateralData.rentalContractValues = getRentalContractValues;

      let savedValue = sessionStorage.getItem(COLLATERAL_DATA) || '';

      if (savedValue !== '') {
        collaterals = JSON.parse(savedValue);
      }

      if (indexForEdit != null && indexForEdit !== -1) {
        collaterals[indexForEdit] = collateralData;
      } else {
        collaterals.push(collateralData);
      }

      sessionStorage.setItem(COLLATERAL_DATA, JSON.stringify(collaterals));
      navigateToWizard(1);
    }
  };

  const handleClickCancel = () => {
    navigateToWizard(1);
  };

  const navigateToWizard = (index: number) => {
    navigate({
      pathname: '/wizard',
      search: '?wizardStep=' + index,
    });
  };

  const showBuildingStockValues = () => {
    return getBuildingStockValue === 'Ja';
  };

  const showMarketValues = () => {
    return getTypeCollateralValue.value !== '4';
  };

  const showCombiValues = () => {
    var show = getTypeCollateralValue.value === '4';

    if (!show) {
      if (getResidentialMarketValue !== '') setResidentialMarketValue('');
      if (getResidentialRentValue !== '') setResidentialRentValue('');
      if (getCommercialMarketValue !== '') setCommercialMarketValue('');
      if (getCommercialRentValue !== '') setCommercialRentValue('');
    }

    return show;
  };

  const showGroundLeaseValues = () => {
    return getGroundLeaseValue === 'Ja' ? 'form_row' : 'form_row hidden_build_depot';
  };

  const showCombiRentalContracts = () => {
    const show = Number(getRentalContractsOption.value) > 0;

    if (!show) {
      if (getLostThroughVacancy !== '') setLostThroughVacancy('');
      if (getNumberOfRentalContracts !== 0) setNumberOfRentalContracts(0);
      if (getRentalContractValues.length > 0) setRentalContractValues(new Array<string>());
    }

    return show;
  };

  const updateRentalContractValue = (index: number, value: string) => {
    var rentalContracts = [...getRentalContractValues];
    rentalContracts[index] = value;

    setRentalContractValues(rentalContracts);
  };

  const updateCollateralPropertyLayout = (roleState: React.SetStateAction<string>) => {
    var disableBuyPrice = roleState === 'Ja';
    setRoleCollateralValue(roleState);
    setBuyPriceDisabledValue(disableBuyPrice);
    if (disableBuyPrice) {
      setBuyPriceValue(getMarketValueValue);
    } else {
      setBuyPriceValue('');
    }
  };

  let combiValues = showCombiValues();

  useEffect(() => {
    setTypeCollateralValue(getTypeCollateralValue.isDisabled ? typeCollateralList.filter(option => !option.isDisabled)[0] : getTypeCollateralValue);
    setSubTypeCollateralValue(getSubTypeCollateralValue.isDisabled ? subTypeCollateralList.filter(option => !option.isDisabled)[0] : getSubTypeCollateralValue);
  }, [])

  useEffect(() => {
    if (getBuildingStockValue !== 'Ja') {
      setBuildingStockAmountValue('');
    }
  }, [getBuildingStockValue]);

  useEffect(() => {
    if (getGroundLeaseValue !== 'Ja') {
      setGroundLeaseFeeValue('');
    }
  }, [getGroundLeaseValue]);

  useEffect(() => {
    var check = getTypeCollateralValue.value !== '4';

    if (!check) {
      setMarketRentValue('');
      setMarketValueValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMarketValues]);

  useEffect(() => {
    var rentalContractsCount = Number(getRentalContractsOption.value);
    if (Number.isNaN(rentalContractsCount)) {
      rentalContractsCount = 0;
    }

    setNumberOfRentalContracts(rentalContractsCount);
  }, [getRentalContractsOption, setRentalContractsOption]);

  return (
    <div className="app_container">
      <Header />
      <div className="form_center">
        <p className="title_create_object">Object</p>
        <form className="form" onSubmit={handleSubmitForm} name="collateral_form">
          <div className="form_container form_left form_col_start_1 form_row_start_1">
            <AddressItem
              getZipcodeValue={getZipcodeValue}
              setZipcodeValue={setZipcodeValue}
              getHouseNumberValue={getHouseNumberValue}
              setHouseNumberValue={setHouseNumberValue}
              getAdditionValue={getAdditionValue}
              setAdditionValue={setAdditionValue}
              getCityValue={getCityValue}
              setCityValue={setCityValue}
              getStreetValue={getStreetValue}
              setStreetValue={setStreetValue}
            />
            <div className="form_row">
              <RadioGroup
                title="Bouwdepot gekoppeld"
                inputClass="radio_group"
                options={['Ja', 'Nee']}
                getValue={getBuildingStockValue}
                setValue={setBuildingStockValue}
              />
            </div>

            {showBuildingStockValues() ? (
              <div>
                <div className="form_row">
                  <ValutaField
                    title="Bedrag bouwdepot"
                    inputId="collateral_stock_amount"
                    validationChecks={new Array<string>(VALIDATION_EMPTY, VALIDATION_DEPOSIT_AMOUNT)}
                    getValue={getBuildingStockAmountValue}
                    setValue={setBuildingStockAmountValue}
                  />
                </div>
                <div className="form_row">
                  <ValutaField
                    title="Totale kosten verbouwing"
                    inputId="collateral_renovation_costs"
                    validationChecks={new Array<string>(VALIDATION_EMPTY)}
                    getValue={getBuildingStockRenovationCosts}
                    setValue={setBuildingStockRenovationCosts}
                  />
                  <ValutaField
                    title="Waarde na verbouwing"
                    inputId="collateral_worth_after_renovation"
                    validationChecks={new Array<string>(VALIDATION_EMPTY)}
                    getValue={getBuildingStockWorthAfterRenovation}
                    setValue={setBuildingStockWorthAfterRenovation}
                  />
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div className="form_container form_right form_col_start_2 form_row_start_1">
            <div className="form_row">
              <RadioGroup
                title="Erfpacht"
                inputClass="radio_group"
                options={['Ja', 'Nee']}
                getValue={getGroundLeaseValue}
                setValue={setGroundLeaseValue}
              />
              <RadioGroup
                title="Reeds in eigendom"
                inputClass="radio_group"
                options={['Ja', 'Nee']}
                getValue={getRoleCollateralValue}
                setValue={updateCollateralPropertyLayout}
              />
            </div>

            <div className={showGroundLeaseValues()}>
              <div className="form_row">
                <ValutaField
                  title="Jaarlijkse erfpachtkosten"
                  inputId="collateral_lease_fee"
                  getValue={getGroundLeaseFeeValue}
                  setValue={setGroundLeaseFeeValue}
                />
                <InputField
                  inputId="person_birth"
                  title="Einddatum erfpacht"
                  inputClass="input_field"
                  inputType="date"
                  containerClass="input_container_small"
                  getValue={getGroundLeaseEndDateValue}
                  setValue={setGroundLeaseEndDateValue}
                />
              </div>
            </div>

            {showMarketValues() ? (
              <div className="form_container">
                <ValutaField
                  title="Marktwaarde"
                  inputId="collateral_market_value"
                  validationChecks={new Array<string>(VALIDATION_EMPTY)}
                  getValue={getMarketValueValue}
                  setValue={(price) => {
                    if (getBuyPriceDisabledValue) {
                      setBuyPriceValue(price);
                    }

                    setMarketValueValue(price);
                  }}
                  disable={!showMarketValues()}
                />
                <ValutaField
                  title={getBuildingStockValue === 'Ja' ? 'Markthuur na verbouwing' : 'Markthuur'}
                  inputId="collateral_market_rent"
                  validationChecks={new Array<string>(VALIDATION_EMPTY)}
                  getValue={getMarketRentValue}
                  setValue={setMarketRentValue}
                  disable={!showMarketValues()}
                />
              </div>
            ) : (
              <div></div>
            )}

            <div className="form_row">
              <ValutaField
                title="Aankoopprijs"
                inputId="collateral_buy_price"
                validationChecks={getBuyPriceDisabledValue && getRoleCollateralValue === 'Ja' ? [] : new Array<string>(VALIDATION_EMPTY)}
                getValue={getBuyPriceValue}
                setValue={setBuyPriceValue}
                disable={getBuyPriceDisabledValue}
              />
            </div>
            <div className="form_row">
              <Dropdown
                title="Type onderpand"
                dropdownId="collateral_type"
                inputClass="dropdown_field"
                options={typeCollateralList}
                containerClass="input_container"
                getValue={getTypeCollateralValue.isDisabled ? typeCollateralList.filter(option => !option.isDisabled)[0] : getTypeCollateralValue}
                setValue={setTypeCollateralValue}
              />
            </div>
            <div className="form_row">
              <Dropdown
                title="Bestemming onderpand"
                dropdownId="collateral_subtype"
                inputClass="dropdown_field"
                options={subTypeCollateralList}
                containerClass="input_container"
                getValue={getSubTypeCollateralValue.isDisabled ? subTypeCollateralList.filter(option => !option.isDisabled)[0] : getSubTypeCollateralValue}
                setValue={setSubTypeCollateralValue}
              />
            </div>
          </div>

          {combiValues ? (
            <div className="form_container form_left">
              <h3>Residentieel</h3>
              <ValutaField
                title="Marktwaarde"
                inputId="collateral_residential_market"
                validationChecks={new Array<string>(VALIDATION_EMPTY)}
                getValue={getResidentialMarketValue}
                setValue={setResidentialMarketValue}
              />
              <ValutaField
                title={getBuildingStockValue === 'Ja' ? 'Markthuur na verbouwing' : 'Markthuur'}
                inputId="collateral_residential_rent"
                validationChecks={new Array<string>(VALIDATION_EMPTY)}
                getValue={getResidentialRentValue}
                setValue={setResidentialRentValue}
              />
            </div>
          ) : (
            <div></div>
          )}

          {combiValues ? (
            <div className="form_container form_right">
              <h3>Commercieel</h3>
              <ValutaField
                title="Marktwaarde"
                inputId="collateral_commercial_market"
                validationChecks={new Array<string>(VALIDATION_EMPTY)}
                getValue={getCommercialMarketValue}
                setValue={setCommercialMarketValue}
              />
              <ValutaField
                title={getBuildingStockValue === 'Ja' ? 'Markthuur na verbouwing' : 'Markthuur'}
                inputId="collateral_commercial_rent"
                validationChecks={new Array<string>(VALIDATION_EMPTY)}
                getValue={getCommercialRentValue}
                setValue={setCommercialRentValue}
              />
            </div>
          ) : (
            <div></div>
          )}

          <div className="form_container form_right form_col_start_2">
            <h3>Huurcontracten</h3>
            <Dropdown
              title="Aantal huurcontracten"
              dropdownId="collateral_rental_contracts_count"
              inputClass="dropdown_field"
              options={COLLATERAL_RENTAL_CONTRACTS_COUNT}
              containerClass="input_container"
              getValue={getRentalContractsOption}
              setValue={setRentalContractsOption}
            />
            {showCombiRentalContracts() ? (
              [...Array(getNumberOfRentalContracts)].map((x, i) => (
                <ValutaField
                  validationChecks={new Array<string>(VALIDATION_EMPTY)}
                  title={'Contracthuur huurcontract ' + (i + 1)}
                  inputId={'collateral_rental_contracts_' + i}
                  getValue={getRentalContractValues[i + 1]}
                  index={i + 1}
                  handleChange={updateRentalContractValue}
                />
              ))
            ) : (
              <div></div>
            )}
            {getNumberOfRentalContracts > 0 ? (
              <ValutaField
                title="Markthuur van het gedeelte leegstand"
                inputId="collateral_vacancy"
                getValue={getLostThroughVacancy}
                setValue={setLostThroughVacancy}
              />
            ) : (
              <div></div>
            )}
          </div>

          <div className="form_container form_button_container form_col_start_2 form_padding_bottom form_left">
            <div className="button_cancel" onClick={() => handleClickCancel()}>
              <p className="button_cancel_text">Annuleren</p>
            </div>
            <input type="submit" className="button_save" value="Opslaan" />
          </div>
        </form>
      </div>
      <DefaultToastContainer />
    </div>
  );
};

export default ObjectCollateralForm;
