import logoDcmf from "assets/logo_DCMF.svg";
import ellipseSmall from "assets/ellipse_small.svg";
import ellipseBigHalf from "assets/ellipse_big_half.svg";
import ellipseBigSliver from "assets/ellipse_big_sliver.svg";
import "./Background.css";

type Props = {
  ellipseType: string;
  showBackground: boolean;
};

const Background = ({ ellipseType, showBackground }: Props): JSX.Element => {
  return (
    <div>
      {getBackGroundImage({ ellipseType, showBackground })}
      {showBackground ? (
        <img src={logoDcmf} className="logo_dcmf" alt="logo" />
      ) : (
        ""
      )}
    </div>
  );
};

const getBackGroundImage = ({ ellipseType }: Props): JSX.Element => {
  switch (ellipseType) {
    case "big_sliver":
      return (
        <img
          src={ellipseBigSliver}
          className="ellipse_big_sliver"
          alt="ellipse"
        />
      );
    case "big_half":
      return (
        <img src={ellipseBigHalf} className="ellipse_big_half" alt="ellipse" />
      );
    default:
    case "small":
      return <img src={ellipseSmall} className="ellipse_small" alt="ellipse" />;
  }
};

export default Background;
