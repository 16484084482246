export const TITLE_APPLICANT = 'Kredietnemer';
export const TITLE_APPLICANT_OLD = "Aanvrager";
export const TITLE_COMPANY = 'Bedrijf';
export const TITLE_APPLICANTS = 'Aanvrager(s)';
export const TITLE_COLLATERALS = 'Onderpand(en)';
export const TITLE_FINANCING = 'Financieringsbehoefte';

export const BUTTON_NEXT = 'Verder';
export const BUTTON_PREVIOUS = 'Terug';
export const BUTTON_SAVE = 'Opslaan';
export const BUTTON_LOGOUT = 'Uitloggen';
export const BUTTON_IMPORT_RATE_CSV = 'Importeer rentetarieven';
export const BUTTON_IMPORT_UPFRONT_CSV = 'Importeer upfront fees';
export const BUTTON_ADD_APPLICANT = 'Natuurlijk persoon toevoegen';
export const BUTTON_ADD_COMPANY = 'B.V. / V.O.F / Eenmanszaak / Maatschap / Stichting toevoegen';
export const BUTTON_ADD_COLLATERAL = 'Object toevoegen';

export const HISTORY_BODY_PARAGRAPH_1 =
  'Aanvragen worden gedurende 14 dagen na verzending opgeslagen. Daarna worden deze automatisch verwijderd.';
export const HISTORY_BODY_PARAGRAPH_2 =
  'Klik op het icoontje achter de aanvraag om deze te bekijken en eventueel te bewerken en opnieuw te versturen.';
