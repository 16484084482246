import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './css/ObjectForm.css';
import { useState } from 'react';
import InputField from 'components/form/InputField';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CompanyModel } from 'models/companyModel';
import { APPLICANT_DATA, COMPANY_DATA } from 'constants/constants';
import { ApplicantModel } from 'models/applicantModel';
import DirectorList from 'components/form/DirectorList';
import { notify } from 'components/toast/notify.component';
import { DefaultToastContainer } from 'components/toast/toast-container.component';
import Dropdown from 'components/form/Dropdown';
import { COMPANY_DROPDOWN_OPTIONS, COUNTRY_OPTIONS } from 'constants/dropdownListData';
import { DropdownOptionsModel } from 'models/dropdownOptionsModel';
import { GetCountryDropdownModel } from 'helpers/countryUtility';
import {
  VALIDATION_COMPANY_NAME,
  VALIDATION_EMAIL,
  VALIDATION_EMPTY,
  VALIDATION_KVK,
  VALIDATION_PHONE,
  VALIDATION_RSIN,
} from 'constants/validationCheckTypes';
import { FORM_ERROR_MESSAGE } from 'constants/messages/errorMessages';
import AddressItem from 'components/form/AddressItem';
import Header from 'components/header/Header';

function ObjectCompanyForm() {
  let navigate = useNavigate();
  let persons = new Array<ApplicantModel>();
  const [searchParams] = useSearchParams();

  var currentCompany = new CompanyModel();
  var indexForEdit = -1;
  if (searchParams.get('index') != null) {
    indexForEdit = Number(searchParams.get('index'));
    let companies: CompanyModel[] = [];
    var savedValues = sessionStorage.getItem(COMPANY_DATA) || '';
    if (savedValues !== '') {
      companies = JSON.parse(savedValues);
      currentCompany = companies[indexForEdit];
    }
  }

  const [getNameValue, setNameValue] = useState<string>(currentCompany.name);
  const [getCompanyTypeValue, setCompanyTypeValue] = useState<DropdownOptionsModel>(currentCompany.companyType);
  const [getRsinValue, setRsinValue] = useState<string>(currentCompany.rsin);
  const [getKvkValue, setKvkValue] = useState<string>(currentCompany.kvk);
  const [getPhoneNumberValue, setPhoneNumberValue] = useState<string>(currentCompany.phoneNumber);
  const [getEmailValue, setEmailValue] = useState<string>(currentCompany.email);
  const [getZipcodeValue, setZipcodeValue] = useState<string>(currentCompany.zipCode);
  const [getHouseNumberValue, setHouseNumberValue] = useState<string>(currentCompany.houseNumber);
  const [getAdditionValue, setAdditionValue] = useState<string>(currentCompany.addition);
  const [getStreetValue, setStreetValue] = useState<string>(currentCompany.streetName);
  const [getCityValue, setCityValue] = useState<string>(currentCompany.city);
  const [getCountryValue, setCountryValue] = useState<string>(currentCompany.country);
  const [getCountryTaxValue, setCountryTaxValue] = useState<string>(currentCompany.countryTax);
  const [getDirectorValues, setDirectorValues] = useState<ApplicantModel[]>(currentCompany.directors);

  function ValidateForm(): boolean {
    let formValid = true;

    var allWithErrorClass = Array.from(document.getElementsByClassName('input_error'));

    if (allWithErrorClass.length > 0) {
      notify(FORM_ERROR_MESSAGE, false);
      formValid = false;
    }

    return formValid;
  }

  const HandleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (ValidateForm()) {
      let companyData = new CompanyModel();
      companyData.name = getNameValue;
      companyData.companyType = getCompanyTypeValue;
      companyData.rsin = getRsinValue;
      companyData.kvk = getKvkValue;
      companyData.phoneNumber = getPhoneNumberValue;
      companyData.email = getEmailValue;
      companyData.zipCode = getZipcodeValue;
      companyData.houseNumber = getHouseNumberValue;
      companyData.addition = getAdditionValue;
      companyData.streetName = getStreetValue;
      companyData.city = getCityValue;
      companyData.country = getCountryValue === '' ? 'NL' : getCountryValue;
      companyData.countryTax = getCountryTaxValue === '' ? 'NL' : getCountryTaxValue;
      companyData.directors = getDirectorValues;

      let savedValue = sessionStorage.getItem(COMPANY_DATA) || '';
      let companies: CompanyModel[] = [];
      if (savedValue !== '') {
        companies = JSON.parse(savedValue);
      }

      if (indexForEdit != null && indexForEdit !== -1) {
        companies[indexForEdit] = companyData;
      } else {
        companies.push(companyData);
      }

      sessionStorage.setItem(COMPANY_DATA, JSON.stringify(companies));
      NavigateToWizard(0);
    }
  };

  function HandleClickCancel() {
    NavigateToWizard(0);
  }

  function NavigateToWizard(index: number) {
    navigate({
      pathname: '/wizard',
      search: '?wizardStep=' + index,
    });
  }

  function LoadDirectors() {
    var applicants = new Array<ApplicantModel>();
    var sessionData = sessionStorage[APPLICANT_DATA];
    if (sessionData !== undefined && sessionData !== '') {
      applicants = JSON.parse(sessionData);
    }

    persons = applicants;
    return applicants;
  }

  function GetDirectors() {
    var existingDirectors = LoadDirectors();
    var savedDirectors = getDirectorValues;

    if (savedDirectors.length > 0) {
      savedDirectors = savedDirectors.filter((parentItem) => existingDirectors.find((childItem) => parentItem.key === childItem.key));
      for (var i = 0; i < existingDirectors.length; i++) {
        let index = i;
        if (!savedDirectors.find((item) => existingDirectors[index].key === item.key)) {
          savedDirectors.push(existingDirectors[i]);
        } else {
          var saveDirectorIndex = savedDirectors.findIndex((savedDirector) => savedDirector.key === existingDirectors[index].key);

          var savedUbo = savedDirectors[saveDirectorIndex].isUbo;
          var savedAuth = savedDirectors[saveDirectorIndex].isAuthorizedSignee;
          var savedDir = savedDirectors[saveDirectorIndex].isDirector;

          savedDirectors[saveDirectorIndex] = existingDirectors[i];

          savedDirectors[saveDirectorIndex].isAuthorizedSignee = savedAuth;
          savedDirectors[saveDirectorIndex].isUbo = savedUbo;
          savedDirectors[saveDirectorIndex].isDirector = savedDir;
        }
      }
    } else {
      for (i = 0; i < existingDirectors.length; i++) {
        existingDirectors[i].isAuthorizedSignee = false;
        existingDirectors[i].isUbo = false;
      }
    }

    return savedDirectors.length > 0 ? savedDirectors : existingDirectors;
  }

  function SaveApplicants(data: { applicant: ApplicantModel; index: number }) {
    persons[data.index] = data.applicant;
    setDirectorValues(...([persons] as const));
    sessionStorage.setItem(APPLICANT_DATA, JSON.stringify(...([persons] as const)));
  }

  return (
    <div className="app_container">
      <Header />
      <div className="form_center">
        <p className="title_create_object">Bedrijf</p>
        <form className="form" onSubmit={HandleSubmitForm}>
          <div className="form_container form_left">
            <div className="form_row">
              <InputField
                inputId="company_name"
                title="Bedrijfsnaam"
                inputClass="input_field"
                inputType="text"
                containerClass="input_container"
                maxLength={80}
                validationChecks={new Array<string>(VALIDATION_EMPTY, VALIDATION_COMPANY_NAME)}
                getValue={getNameValue}
                setValue={setNameValue}
              />
            </div>
            <div className="form_row">
              <Dropdown
                title="Bedrijfsvorm"
                dropdownId=" company_type"
                inputClass="dropdown_field"
                options={COMPANY_DROPDOWN_OPTIONS}
                containerClass="input_container"
                getValue={getCompanyTypeValue}
                setValue={setCompanyTypeValue}
                initialValue={COMPANY_DROPDOWN_OPTIONS[0]}
              />
            </div>
            <div className="form_row">
              <InputField
                inputId="company_rsin"
                title="RSIN"
                inputClass="input_field"
                inputType="number"
                maxLength={9}
                containerClass="input_container"
                validationChecks={new Array<string>(VALIDATION_EMPTY, VALIDATION_RSIN)}
                getValue={getRsinValue}
                setValue={setRsinValue}
              />
            </div>
            <div className="form_row">
              <InputField
                inputId="company_kvk"
                title="Kvk nummer"
                inputClass="input_field"
                inputType="number"
                maxLength={8}
                containerClass="input_container"
                validationChecks={new Array<string>(VALIDATION_EMPTY, VALIDATION_KVK)}
                getValue={getKvkValue}
                setValue={setKvkValue}
              />
            </div>
            <div className="">
              <DirectorList
                title="Bestuurders"
                inputClass=""
                containerClass=""
                applicants={GetDirectors()}
                setValue={(data) =>
                  SaveApplicants(
                    data as {
                      applicant: ApplicantModel;
                      index: number;
                    }
                  )
                }
              />
            </div>
          </div>
          <div className="form_container form_right">
            <div className="form_row">
              <InputField
                inputId="company_phone"
                title="Telefoonnummer bijv: '+3163333333' of '063333333'"
                inputClass="input_field"
                inputType="text"
                containerClass="input_container"
                validationChecks={new Array<string>(VALIDATION_EMPTY, VALIDATION_PHONE)}
                phoneRestrictions={true}
                getValue={getPhoneNumberValue}
                setValue={setPhoneNumberValue}
              />
            </div>
            <div className="form_row">
              <InputField
                inputId="company_email"
                title="Email"
                inputClass="input_field"
                inputType="text"
                containerClass="input_container"
                validationChecks={new Array<string>(VALIDATION_EMPTY, VALIDATION_EMAIL)}
                getValue={getEmailValue}
                setValue={setEmailValue}
              />
            </div>
            <AddressItem
              getZipcodeValue={getZipcodeValue}
              setZipcodeValue={setZipcodeValue}
              getHouseNumberValue={getHouseNumberValue}
              setHouseNumberValue={setHouseNumberValue}
              getAdditionValue={getAdditionValue}
              setAdditionValue={setAdditionValue}
              getCityValue={getCityValue}
              setCityValue={setCityValue}
              getStreetValue={getStreetValue}
              setStreetValue={setStreetValue}
            />
            <div className="form_row">
              <Dropdown
                title="Land"
                inputClass="dropdown_field"
                dropdownId="company_country"
                containerClass="input_container"
                options={COUNTRY_OPTIONS}
                getValue={GetCountryDropdownModel(getCountryValue)}
                setValue={(option) => setCountryValue((option as DropdownOptionsModel).value)}
                initialValue={GetCountryDropdownModel(getCountryValue)}
              />
            </div>
            <div className="form_row">
              <Dropdown
                title="Land belastingsplicht"
                inputClass="dropdown_field"
                dropdownId="company_tax_country"
                containerClass="input_container"
                options={COUNTRY_OPTIONS}
                getValue={GetCountryDropdownModel(getCountryTaxValue)}
                setValue={(option) => setCountryTaxValue((option as DropdownOptionsModel).value)}
                initialValue={GetCountryDropdownModel(getCountryTaxValue)}
              />
            </div>
            <div className="form_button_container">
              <div className="button_cancel" onClick={() => HandleClickCancel()}>
                <p className="button_cancel_text">Annuleren</p>
              </div>
              <input type="submit" className="button_save" value="Opslaan" />
            </div>
          </div>
        </form>
      </div>
      <DefaultToastContainer />
    </div>
  );
}

export default ObjectCompanyForm;
