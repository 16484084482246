import './Dropdown.css';
import { SetStateAction, useRef } from 'react';
import InputField from './InputField';
import { VALIDATION_EMPTY, VALIDATION_POSTAL_CODE } from 'constants/validationCheckTypes';
import { BASE_URL_API, HEADER_AUTHORIZATION, REQUEST_GET } from 'constants/constants';
import { useTranslation } from 'react-i18next';
import { InputFieldMethods } from './InputFieldMethods';
import { useKeycloak } from '@react-keycloak/web';

type AddressItemProps = {
  getZipcodeValue: string;
  setZipcodeValue: React.Dispatch<SetStateAction<string>>;
  getHouseNumberValue: string;
  setHouseNumberValue: React.Dispatch<SetStateAction<string>>;
  getAdditionValue: string;
  setAdditionValue: React.Dispatch<SetStateAction<string>>;
  getStreetValue: string;
  setStreetValue: React.Dispatch<SetStateAction<string>>;
  getCityValue: string;
  setCityValue: React.Dispatch<SetStateAction<string>>;
  validatePostalcode?: boolean;
};

const AddressItem = ({
  getZipcodeValue,
  setZipcodeValue,
  getHouseNumberValue,
  setHouseNumberValue,
  getAdditionValue,
  setAdditionValue,
  getStreetValue,
  setStreetValue,
  getCityValue,
  setCityValue,
  validatePostalcode = true,
}: AddressItemProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'address-component' });
  const { keycloak } = useKeycloak();
  const handleZipcodeChange = (zipCode: string) => {
    autoFillAddress(zipCode, getHouseNumberValue);
    return zipCode;
  };

  const streetInputFieldRef = useRef<InputFieldMethods>(null);
  const cityInputFieldRef = useRef<InputFieldMethods>(null);

  const handleHouseNumberChange = (houseNumber: string) => {
    autoFillAddress(getZipcodeValue, houseNumber);
    return houseNumber;
  };

  const autoFillAddress = (zipCode: string, houseNumber: string) => {
    if (!zipCode || !houseNumber) {
      return;
    }

    var requestHeaders = new Headers();
    requestHeaders.append('PostalCode', zipCode);
    requestHeaders.append('HouseNumber', houseNumber);
    requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)
    
    var requestOptions = {
      method: REQUEST_GET,
      headers: requestHeaders,
    };

    fetch(BASE_URL_API + 'api/get-address', requestOptions as RequestInit)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }

        return response.text();
      })
      .then((result) => {
        var data = JSON.parse(result);
        var city = data['city'];
        var street = data['street'];

        if (data && city && street) {
          if (streetInputFieldRef.current) {
            streetInputFieldRef.current?.handleRemoteChange(street);
          } else {
            setStreetValue(street);
          }
          if (cityInputFieldRef.current) {
            cityInputFieldRef.current?.handleRemoteChange(city);
          } else {
            setCityValue(city);
          }
        }

        return Promise.resolve();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="form_row">
        <InputField
          inputId="collateral_postal_code"
          title={t('postal-code')}
          inputClass="input_field"
          inputType="text"
          containerClass="input_container"
          validationChecks={validatePostalcode ? new Array<string>(VALIDATION_EMPTY, VALIDATION_POSTAL_CODE) : undefined}
          getValue={getZipcodeValue}
          setValue={setZipcodeValue}
          onBlur={handleZipcodeChange}
        />
        <InputField
          inputId="collateral_house_number"
          title={t('house-number')}
          inputClass="input_field"
          inputType="number"
          containerClass="input_container"
          validationChecks={new Array<string>(VALIDATION_EMPTY)}
          getValue={getHouseNumberValue}
          setValue={setHouseNumberValue}
          onBlur={handleHouseNumberChange}
        />
        <InputField
          inputId="collateral_house_addition"
          title={t('addition')}
          inputClass="input_field"
          inputType="text"
          containerClass="input_container"
          getValue={getAdditionValue}
          setValue={setAdditionValue}
        />
      </div>
      <div className="form_row">
        <InputField
          inputId="collateral_street"
          ref={streetInputFieldRef}
          title={t('street')}
          inputClass="input_field"
          inputType="text"
          containerClass="input_container"
          validationChecks={new Array<string>(VALIDATION_EMPTY)}
          getValue={getStreetValue}
          setValue={setStreetValue}
        />
        <InputField
          inputId="collateral_residence"
          ref={cityInputFieldRef}
          title={t('place')}
          inputClass="input_field"
          inputType="text"
          containerClass="input_container"
          validationChecks={new Array<string>(VALIDATION_EMPTY)}
          getValue={getCityValue}
          setValue={setCityValue}
        />
      </div>
    </div>
  );
};

export default AddressItem;
