import './Dropdown.css';
import { SetStateAction, useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { DropdownOptionsModel } from 'models/dropdownOptionsModel';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  dropdownId: string;
  inputClass: string;
  options: DropdownOptionsModel[];
  containerClass: string;
  validationChecks?: Array<string>;
  getValue: DropdownOptionsModel;
  setValue: React.Dispatch<SetStateAction<DropdownOptionsModel>>;
  initialValue?: DropdownOptionsModel;
  clearOnRefresh?: boolean;
  clearValue?: DropdownOptionsModel;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRequired?: boolean;
};

const Dropdown = ({
  title,
  dropdownId,
  inputClass,
  options,
  containerClass,
  getValue,
  setValue,
  initialValue = new DropdownOptionsModel(),
  clearOnRefresh = false,
  clearValue = new DropdownOptionsModel(),
  isDisabled = false,
  isLoading = false,
  isRequired = false,
}: Props) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'dropdown' });
  const handleChange = (value: SingleValue<DropdownOptionsModel>) => {
    if (value !== null) {
      setValue(value);
    }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const selectedValueExists = options.some((option) => option.value === getValue?.value && option.label === getValue?.label);

    if (!selectedValueExists) {
      setValue(options[0] ?? clearValue ?? new DropdownOptionsModel());
    }
  }, [options, isLoading]);

  return (
    <div className={containerClass}>
      <p className={"input_title " + (isRequired ? 'input_required' : '')}>{title}</p>
      <Select
        id={dropdownId}
        className={inputClass}
        placeholder={t('placeholder')}
        options={options}
        onChange={handleChange}
        value={getValue}
        defaultValue={initialValue && initialValue.value && initialValue.value !== '' ? initialValue : getValue}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default Dropdown;
