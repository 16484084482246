import { UserModel } from 'models/userModel';

export const convertUserDataToCSV = (userData: Array<UserModel>) => {
  var result = '';

  const columnDelimiter = ';';
  const lineDelimiter = '\n';
  const headers = ['Naam', 'Broker', 'Tussenpersoon Nummer', 'Laatst actief', 'Rol'];

  result = '';
  result += headers.join(columnDelimiter);
  result += lineDelimiter;

  userData.forEach((item) => {
    result += `${item.firstName} ${item.lastName}`.trimEnd() + columnDelimiter;
    result += item.broker + columnDelimiter;
    result += item.intermediaryNumber + columnDelimiter;
    result +=
      (item.lastActive > -1 ? item.lastActive : '') +
      (item.lastActive > -1 ? (item.lastActive === 1 ? ' dag geleden' : ' dagen geleden') : '') +
      columnDelimiter;
    result += item.roles + columnDelimiter;
    result += lineDelimiter;
  });

  return result;
};
