import "./ObjectDataViewSmall.css";
import object_background from "assets/object_background.svg";

type Props = {
  index: number;
  subOption?: boolean;
  title: string;
  subtitle: string;
};

const ObjectDataViewSmall = ({ index, subOption, title, subtitle }: Props) => {
  var dataViewClass =
    "object_data_container_small" + (subOption ? "_sub_option" : "");
  var dataContainerClass =
    "object_container_small" + (subOption ? "_sub_option" : "");
  return (
    <div className={dataContainerClass}>
      <div className={dataViewClass}>
        <div className="object_row_container_small">
          <p className="object_title_small">{title}</p>
          <p className="object_type_small">{subtitle}</p>
        </div>
        {subOption ? (
          <div />
        ) : (
          <img
            src={object_background}
            className="object_background_small"
            alt="logo"
          />
        )}
      </div>
    </div>
  );
};

export default ObjectDataViewSmall;
