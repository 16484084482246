import { SetStateAction } from 'react';
import InputField from './InputField';
import RadioGroup from './RadioGroup';
import Dropdown from './Dropdown';
import { COUNTRY_OPTIONS } from 'constants/dropdownListData';
import { DropdownOptionsModel } from 'models/dropdownOptionsModel';
import { GetCountryDropdownModel } from 'helpers/countryUtility';
import { VALIDATION_EMPTY, VALIDATION_INITIALS, VALIDATION_NAME } from 'constants/validationCheckTypes';
import { useTranslation } from 'react-i18next';
import { IsAdmin, IsDCMF } from 'helpers/helpers';

type Props = {
  getGenderValue: string;
  setGenderValue: React.Dispatch<SetStateAction<string>>;
  getInitialsValue: string;
  setInitialsValue: React.Dispatch<SetStateAction<string>>;
  getFamilyNameValue: string;
  setFamilyNameValue: React.Dispatch<SetStateAction<string>>;
  getFamilyNameInsertionValue: string;
  setFamilyNameInsertionValue: React.Dispatch<SetStateAction<string>>;
  getBirthDateValue: string;
  setBirthDateValue: React.Dispatch<SetStateAction<string>>;
  getNationalityValue: string;
  setNationalityValue: React.Dispatch<SetStateAction<string>>;
  getJointlyLiableValue?: string;
  setJointlyLiableValue?: React.Dispatch<SetStateAction<string>>;
  getIsAuthorizedSignee?: string;
  setIsAuthorizedSignee?: React.Dispatch<SetStateAction<string>>;
  showAuthorizedSignee: boolean;
};

const PersonForm = ({
  getGenderValue,
  setGenderValue,
  getInitialsValue,
  setInitialsValue,
  getFamilyNameValue,
  setFamilyNameValue,
  getFamilyNameInsertionValue,
  setFamilyNameInsertionValue,
  getBirthDateValue,
  setBirthDateValue,
  getNationalityValue,
  setNationalityValue,
  getJointlyLiableValue,
  setJointlyLiableValue,
  getIsAuthorizedSignee,
  setIsAuthorizedSignee,
  showAuthorizedSignee,
}: Props) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'person-form' });

  return (
    <div>
      <div className="form_row">
        <RadioGroup
          title={t('sex')}
          inputClass="radio_group"
          options={[t('male'), t('female')]}
          getValue={getGenderValue ?? ''}
          setValue={setGenderValue}
        />
        {getJointlyLiableValue && setJointlyLiableValue && (IsAdmin() || IsDCMF()) && (
          <RadioGroup
            title="Hoofdelijk aansprakelijk"
            inputClass="radio_group"
            options={['Ja', 'Nee']}
            getValue={getJointlyLiableValue}
            setValue={setJointlyLiableValue}
          />
        )}
        {showAuthorizedSignee && getIsAuthorizedSignee && setIsAuthorizedSignee && (
          <RadioGroup
            title={t('authorized-signee')}
            inputClass="radio_group"
            options={['Ja', 'Nee']}
            getValue={getIsAuthorizedSignee}
            setValue={setIsAuthorizedSignee}
            isRequired={true}
          />
        )}
      </div>
      <div className="form_row">
        <InputField
          inputId="person_name"
          title={t('initials')}
          inputClass="input_field"
          inputType="text"
          containerClass="input_container_tiny"
          validationChecks={new Array<string>(VALIDATION_EMPTY, VALIDATION_INITIALS)}
          getValue={getInitialsValue ?? ''}
          setValue={setInitialsValue}
          onKeyDown={(e) => {
            if (e.currentTarget.value === '' && e.key === '.') {
              e.preventDefault();
            }
          }}
          onBlur={(value) => {
            if (value.trim() !== '' && value.trim() !== '.') {
              value =
                value
                  .split('')
                  .filter((ch) => ch !== '.')
                  .join('.')
                  .toUpperCase() + '.';
            }

            setInitialsValue(value);
            return value;
          }}
        />
        <InputField
          inputId="person_last_name"
          title={t('last-name')}
          inputClass="input_field"
          inputType="text"
          containerClass="input_container"
          validationChecks={new Array<string>(VALIDATION_EMPTY, VALIDATION_NAME)}
          getValue={getFamilyNameValue ?? ''}
          setValue={setFamilyNameValue}
        />
        <InputField
          inputId="person_insertion"
          title={t('addition')}
          inputClass="input_field"
          inputType="text"
          containerClass="input_container_tiny"
          getValue={getFamilyNameInsertionValue ?? ''}
          setValue={setFamilyNameInsertionValue}
        />
      </div>

      <div className="form_row">
        <InputField
          inputId="person_birth"
          title={t('date-of-birth')}
          inputClass="input_field"
          inputType="date"
          containerClass="input_container"
          validationChecks={new Array<string>(VALIDATION_EMPTY)}
          getValue={getBirthDateValue ?? ''}
          setValue={setBirthDateValue}
        />
      </div>
      <div className="form_row">
        <Dropdown
          title={t('nationality')}
          inputClass="dropdown_field"
          dropdownId="person_nationality"
          containerClass="input_container"
          options={COUNTRY_OPTIONS}
          getValue={GetCountryDropdownModel(getNationalityValue)}
          setValue={(option) => setNationalityValue((option as DropdownOptionsModel).value)}
          initialValue={GetCountryDropdownModel(getNationalityValue)}
        />
      </div>
    </div>
  );
};

export default PersonForm;
