import "./Dropdown.css";
import { SetStateAction } from "react";
import { ApplicantModel } from "models/applicantModel";
import { useTranslation } from "react-i18next";

type Props = {
  currentApplicant: ApplicantModel;
  index: number;
  setValue: React.Dispatch<
    SetStateAction<{ applicant: ApplicantModel; index: number }>
  >;
};

const DirectorItem = ({ currentApplicant, index, setValue }: Props) => {
  const { t } = useTranslation(undefined, { keyPrefix: "director-item" });

  const saveDirectorToStateUbo = (isUbo: boolean) => {
    currentApplicant.isUbo = isUbo;
    setValue({ applicant: currentApplicant, index: index });
  };

  const saveDirectorToState = (isDirector: boolean) => {
    currentApplicant.isDirector = isDirector;
    currentApplicant.isAuthorizedSignee = isDirector
    if (isDirector === false) {
      currentApplicant.isUbo = false;
    }

    setValue({ applicant: currentApplicant, index: index });
  };

  return (
    <div className="director_row">
      <div className="director_row_item">
        <p>{`${currentApplicant.person.initials} ${currentApplicant.person.lastNameInsertion} ${currentApplicant.person.lastName}`}</p>
      </div>
      <div className="director_row_item">
        <p>{t("board-member")}</p>
        <input
          type="checkbox"
          defaultChecked={currentApplicant.isDirector}
          onChange={(event) => saveDirectorToState(event.target.checked)}
        />
      </div>
      {currentApplicant.isDirector ? (
        <div className="director_row_item">
          <p>{t("ubo")}</p>
          <input
            type="checkbox"
            defaultChecked={currentApplicant.isUbo}
            onChange={(event) => saveDirectorToStateUbo(event.target.checked)}
          />
        </div>
      ) : (
        <div className="director_row_item"></div>
      )}
    </div>
  );
};

export default DirectorItem;
