import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './css/ObjectForm.css';
import { useEffect, useState } from 'react';
import InputField from 'components/form/InputField';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApplicantModel } from 'models/applicantModel';
import { APPLICANT_DATA } from 'constants/constants';
import PersonForm from 'components/form/PersonForm';
import { notify } from 'components/toast/notify.component';
import { DefaultToastContainer } from 'components/toast/toast-container.component';
import { COUNTRY_OPTIONS, RELATION_DROPDOWN_OPTIONS } from 'constants/dropdownListData';
import Dropdown from 'components/form/Dropdown';
import { DropdownOptionsModel } from 'models/dropdownOptionsModel';
import { GetCountryDropdownModel } from 'helpers/countryUtility';
import { VALIDATION_EMAIL, VALIDATION_EMPTY, VALIDATION_PHONE } from 'constants/validationCheckTypes';
import { FORM_ERROR_MESSAGE } from 'constants/messages/errorMessages';
import AddressItem from 'components/form/AddressItem';
import Header from 'components/header/Header';
import { IsAdmin, IsDCMF } from 'helpers/helpers';
import RadioGroup from 'components/form/RadioGroup';

const ObjectApplicantForm = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  var indexForEdit = -1;
  var currentApplicant = new ApplicantModel();
  if (searchParams.get('index') != null) {
    indexForEdit = Number(searchParams.get('index'));
    const savedApplicantValues = sessionStorage.getItem(APPLICANT_DATA) || '';
    let applicants: ApplicantModel[] = [];

    if (savedApplicantValues !== '') {
      applicants = JSON.parse(savedApplicantValues);
      currentApplicant = applicants[indexForEdit];
    }
  }

  const [getGenderValue, setGenderValue] = useState<string>(currentApplicant.person.gender);
  const [getInitialsValue, setInitialsValue] = useState<string>(currentApplicant.person.initials);
  const [getFamilyNameValue, setFamilyNameValue] = useState<string>(currentApplicant.person.lastName);
  const [getFamilyNameInsertionValue, setFamilyNameInsertionValue] = useState<string>(currentApplicant.person.lastNameInsertion);
  const [getBirthDateValue, setBirthDateValue] = useState<string>(currentApplicant.person.birthday);
  const [getNationalityValue, setNationalityValue] = useState<string>(currentApplicant.person.nationality);
  const [getPhoneNumberValue, setPhoneNumberValue] = useState<string>(currentApplicant.phoneNumber);
  const [getEmailValue, setEmailValue] = useState<string>(currentApplicant.email);
  const [getZipcodeValue, setZipcodeValue] = useState<string>(currentApplicant.zipCode);
  const [getHouseNumberValue, setHouseNumberValue] = useState<string>(currentApplicant.houseNumber);
  const [getAdditionValue, setAdditionValue] = useState<string>(currentApplicant.addition);
  const [getStreetValue, setStreetValue] = useState<string>(currentApplicant.streetName);
  const [getCityValue, setCityValue] = useState<string>(currentApplicant.city);
  const [getCountryValue, setCountryValue] = useState<string>(currentApplicant.country);
  const [getCountryTaxValue, setCountryTaxValue] = useState<string>(currentApplicant.countryTax);
  const [getRelationValue, setRelationValue] = useState<DropdownOptionsModel>(currentApplicant.relation);
  const [getJointlyLiableValue, setJointlyLiableValue] = useState<string>(currentApplicant.person.isJointlyAndSeverallyLiable);

  //Relation
  const [getRelationGenderValue, setRelationGenderValue] = useState<string>(currentApplicant.relationApplicant?.person.gender ?? '');
  const [getRelationInitialsValue, setRelationInitialsValue] = useState<string>(currentApplicant.relationApplicant?.person.initials ?? '');
  const [getRelationFamilyNameValue, setRelationFamilyNameValue] = useState<string>(
    currentApplicant.relationApplicant?.person.lastName ?? ''
  );
  const [getRelationFamilyNameInsertionValue, setRelationFamilyNameInsertionValue] = useState<string>(
    currentApplicant.relationApplicant?.person.lastNameInsertion ?? ''
  );
  const [getRelationBirthDateValue, setRelationBirthDateValue] = useState<string>(
    currentApplicant.relationApplicant?.person.birthday ?? ''
  );
  const [getRelationNationalityValue, setRelationNationalityValue] = useState<string>(
    currentApplicant.relationApplicant?.person.nationality ?? ''
  );
  const [getRelationIsSignee, setRelationIsSigneeValue] = useState<string>(currentApplicant.relationApplicant?.person.isSignee ?? '');

  const [getRelationPhoneNumberValue, setRelationPhoneNumberValue] = useState<string>(
    currentApplicant.relationApplicant?.phoneNumber ?? ''
  );
  const [getRelationEmailValue, setRelationEmailValue] = useState<string>(currentApplicant.relationApplicant?.email ?? '');
  const [getRelationZipcodeValue, setRelationZipcodeValue] = useState<string>(currentApplicant.relationApplicant?.zipCode ?? '');
  const [getRelationHouseNumberValue, setRelationHouseNumberValue] = useState<string>(
    currentApplicant.relationApplicant?.houseNumber ?? ''
  );
  const [getRelationAdditionValue, setRelationAdditionValue] = useState<string>(currentApplicant.relationApplicant?.addition ?? '');
  const [getRelationStreetValue, setRelationStreetValue] = useState<string>(currentApplicant.relationApplicant?.streetName ?? '');
  const [getRelationCityValue, setRelationCityValue] = useState<string>(currentApplicant.relationApplicant?.city ?? '');
  const [getRelationCountryValue, setRelationCountryValue] = useState<string>(currentApplicant.relationApplicant?.country ?? '');
  const [getRelationCountryTaxValue, setRelationCountryTaxValue] = useState<string>(currentApplicant.relationApplicant?.countryTax ?? '');

  const validateForm = (): boolean => {
    const allWithErrorClass = Array.from(document.getElementsByClassName('input_error'));

    let formValid = true;
    if (allWithErrorClass.length > 0) {
      notify(FORM_ERROR_MESSAGE, false);
      formValid = false;
    }

    return formValid;
  };

  const relationFieldsVisible = (): boolean => {
    if (
      getRelationValue.label === RELATION_DROPDOWN_OPTIONS[0].label ||
      getRelationValue.label === RELATION_DROPDOWN_OPTIONS[8].label ||
      getRelationValue.label === ''
    ) {
      return false;
    }
    return true;
  };

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isValidIndex = indexForEdit != null && indexForEdit !== -1;
    const savedApplicantValue = sessionStorage.getItem(APPLICANT_DATA) || '';
    let applicants: ApplicantModel[] = [];
    if (savedApplicantValue !== '') {
      applicants = JSON.parse(savedApplicantValue);
    }

    const oldApplicant = isValidIndex ? applicants[indexForEdit] : undefined;
    if (validateForm()) {
      let applicantData = new ApplicantModel();
      applicantData.person.gender = getGenderValue;
      applicantData.person.initials = getInitialsValue;
      applicantData.person.lastName = getFamilyNameValue;
      applicantData.person.lastNameInsertion = getFamilyNameInsertionValue;
      applicantData.person.birthday = getBirthDateValue;
      applicantData.person.nationality = getNationalityValue === '' ? 'NL' : getNationalityValue;
      applicantData.phoneNumber = getPhoneNumberValue;
      applicantData.email = getEmailValue;
      applicantData.zipCode = getZipcodeValue;
      applicantData.houseNumber = getHouseNumberValue;
      applicantData.addition = getAdditionValue;
      applicantData.streetName = getStreetValue;
      applicantData.city = getCityValue;
      applicantData.country = getCountryValue === '' ? 'NL' : getCountryValue;
      applicantData.countryTax = getCountryTaxValue === '' ? 'NL' : getCountryTaxValue;
      applicantData.person.isRelationPerson = false;
      applicantData.person.isJointlyAndSeverallyLiable = getJointlyLiableValue;

      //relation
      applicantData.relation = getRelationValue;
      if (relationFieldsVisible()) {
        if (applicantData.relationApplicant === null || applicantData.relationApplicant === undefined) {
          applicantData.relationApplicant = new ApplicantModel();
        }

        applicantData.relationApplicant.person.gender = getRelationGenderValue ?? '';
        applicantData.relationApplicant.person.initials = getRelationInitialsValue ?? '';
        applicantData.relationApplicant.person.lastName = getRelationFamilyNameValue ?? '';
        applicantData.relationApplicant.person.lastNameInsertion = getRelationFamilyNameInsertionValue ?? '';
        applicantData.relationApplicant.person.birthday = getRelationBirthDateValue ?? '';
        applicantData.relationApplicant.person.nationality =
          getRelationNationalityValue ?? getRelationNationalityValue === '' ? 'NL' : getRelationNationalityValue;
        applicantData.relationApplicant.person.isSignee = getRelationIsSignee ?? 'NO_OPTION_SELECTED';
        applicantData.relationApplicant.isAuthorizedSignee = getRelationIsSignee === 'Ja' ?? false;
        applicantData.relationApplicant.person.isRelationPerson = true;

        applicantData.relationApplicant.phoneNumber = getRelationPhoneNumberValue ?? '';
        applicantData.relationApplicant.email = getRelationEmailValue ?? '';
        applicantData.relationApplicant.zipCode = getRelationZipcodeValue ?? '';
        applicantData.relationApplicant.houseNumber = getRelationHouseNumberValue ?? '';
        applicantData.relationApplicant.addition = getRelationAdditionValue ?? '';
        applicantData.relationApplicant.streetName = getRelationStreetValue ?? '';
        applicantData.relationApplicant.city = getRelationCityValue ?? '';
        applicantData.relationApplicant.country = getRelationCountryValue ?? '';
        applicantData.relationApplicant.countryTax = getRelationCountryTaxValue ?? '';
      } else {
        if (isValidIndex && oldApplicant && oldApplicant.relationApplicant && oldApplicant.relationApplicant.relationApplicant) {
          const oldRelationIndex = applicants.findIndex((app) => app.key === oldApplicant?.relationApplicant?.key);
          applicants.splice(oldRelationIndex, 1);
        }
      }

      if (isValidIndex && oldApplicant) {
        applicantData.key = oldApplicant.key;
        applicants[indexForEdit] = applicantData;

        // Has relation applicant
        if (applicantData.relationApplicant) {
          // Copy the key from the old relation
          applicantData.relationApplicant.relationApplicantKey = applicantData.key;

          // Has an old relation applicant before the update, set keys correctly for this old relation
          if (oldApplicant.relationApplicant) {
            applicantData.relationApplicant.key = oldApplicant.relationApplicant.key;
          }

          // Get the index of the relation applicant and set the matching applicant to the new relation applicant
          const relationIndex = applicants.findIndex(
            (app) => app.key === applicantData.relationApplicant?.key || app.key === oldApplicant?.relationApplicant?.key
          );
          if (relationIndex >= 0) {
            // Remove the relation applicant if the newer one isn't authorized
            if (applicantData.relationApplicant.isAuthorizedSignee === false) {
              applicants.splice(relationIndex, 1);
            }
            // Otherwise update the relation applicant
            else {
              applicants[relationIndex] = applicantData.relationApplicant;
            }
          }
          // Else if there isn't a match, add this relation applicant when authorized signee to the list
          else if (applicantData.relationApplicant.isAuthorizedSignee) {
            applicants.push(applicantData.relationApplicant);
          }
        }
        // When this is a new applicant add it the list
      } else {
        applicants.push(applicantData);

        // Do the same for the relation applicant if it exists and is authorized
        if (applicantData.relationApplicant && applicantData.relationApplicant.isAuthorizedSignee) {
          applicants.push(applicantData.relationApplicant);
        }
      }

      sessionStorage.setItem(APPLICANT_DATA, JSON.stringify(applicants));

      navigateToWizard(0);
    }
  };

  const handleClickCancel = () => {
    navigateToWizard(0);
  };

  const navigateToWizard = (index: number) => {
    navigate({
      pathname: '/wizard',
      search: '?wizardStep=' + index,
    });
  };

  useEffect(() => {
    if (!relationFieldsVisible()) {
      setRelationGenderValue('Man');
      setRelationInitialsValue('');
      setRelationFamilyNameValue('');
      setRelationFamilyNameInsertionValue('');
      setRelationBirthDateValue('');
      setRelationNationalityValue('');
      setRelationIsSigneeValue('NO_OPTION_SELECTED');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRelationValue]);

  useEffect(() => {
    if (getRelationIsSignee === 'Nee' || !relationFieldsVisible()) {
      setRelationPhoneNumberValue('');
      setRelationEmailValue('');
      setRelationZipcodeValue('');
      setRelationHouseNumberValue('');
      setRelationAdditionValue('');
      setRelationStreetValue('');
      setRelationCityValue('');
      setRelationCountryValue('');
      setRelationCountryTaxValue('');
    }

    if (getRelationIsSignee === 'Ja' && relationFieldsVisible()) {
      setRelationPhoneNumberValue(getRelationPhoneNumberValue);
      setRelationEmailValue(getRelationEmailValue);
      setRelationZipcodeValue(getRelationZipcodeValue === '' ? getZipcodeValue : getRelationZipcodeValue);
      setRelationHouseNumberValue(getRelationHouseNumberValue === '' ? getHouseNumberValue : getRelationHouseNumberValue);
      setRelationAdditionValue(getRelationAdditionValue === '' ? getAdditionValue : getRelationAdditionValue);
      setRelationStreetValue(getRelationStreetValue === '' ? getStreetValue : getRelationStreetValue);
      setRelationCityValue(getRelationCityValue === '' ? getCityValue : getRelationCityValue);
      setRelationCountryValue(getRelationCountryValue === '' ? getCountryValue : getRelationCountryValue);
      setRelationCountryTaxValue(getRelationCountryTaxValue === '' ? getCountryTaxValue : getRelationCountryTaxValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRelationIsSignee]);

  return (
    <div className="app_container">
      <Header />
      <div className="form_center">
        <p className="title_create_object">Natuurlijk persoon</p>
        <form className="form" onSubmit={handleSubmitForm}>
          <div className="form_container form_left">
            <PersonForm
              getGenderValue={getGenderValue}
              setGenderValue={setGenderValue}
              getInitialsValue={getInitialsValue}
              setInitialsValue={setInitialsValue}
              getFamilyNameValue={getFamilyNameValue}
              setFamilyNameValue={setFamilyNameValue}
              getFamilyNameInsertionValue={getFamilyNameInsertionValue}
              setFamilyNameInsertionValue={setFamilyNameInsertionValue}
              getBirthDateValue={getBirthDateValue}
              setBirthDateValue={setBirthDateValue}
              getNationalityValue={getNationalityValue}
              setNationalityValue={setNationalityValue}
              getJointlyLiableValue={getJointlyLiableValue}
              setJointlyLiableValue={setJointlyLiableValue}
              showAuthorizedSignee={false}
            />
            <div className="form_row">
              <Dropdown
                title="Relatie"
                dropdownId="pers_relation"
                inputClass="dropdown_field"
                options={RELATION_DROPDOWN_OPTIONS}
                initialValue={getRelationValue}
                containerClass="input_container"
                getValue={getRelationValue}
                setValue={setRelationValue}
              />
            </div>
            {relationFieldsVisible() && (
              <div className="relation_person_extra_input_column">
                <PersonForm
                  getGenderValue={getRelationGenderValue ?? ''}
                  setGenderValue={setRelationGenderValue}
                  getInitialsValue={getRelationInitialsValue ?? ''}
                  setInitialsValue={setRelationInitialsValue}
                  getFamilyNameValue={getRelationFamilyNameValue ?? ''}
                  setFamilyNameValue={setRelationFamilyNameValue}
                  getFamilyNameInsertionValue={getRelationFamilyNameInsertionValue ?? ''}
                  setFamilyNameInsertionValue={setRelationFamilyNameInsertionValue}
                  getBirthDateValue={getRelationBirthDateValue ?? ''}
                  setBirthDateValue={setRelationBirthDateValue}
                  getNationalityValue={getRelationNationalityValue ?? ''}
                  setNationalityValue={setRelationNationalityValue}
                  getIsAuthorizedSignee={getRelationIsSignee ?? 'NO_OPTION_SELECTED'}
                  setIsAuthorizedSignee={setRelationIsSigneeValue}
                  showAuthorizedSignee={true}
                />
              </div>
            )}
          </div>
          <div className="form_container form_right">
            <div className="form_row">
              <InputField
                inputId="pers_phone"
                title="Telefoonnummer bijv: '+3163333333' of '063333333'"
                inputClass="input_field"
                inputType="text"
                containerClass="input_container"
                validationChecks={new Array<string>(VALIDATION_EMPTY, VALIDATION_PHONE)}
                phoneRestrictions={true}
                getValue={getPhoneNumberValue}
                setValue={setPhoneNumberValue}
              />
            </div>
            <div className="form_row">
              <InputField
                inputId="pers_email"
                title="E-mailadres"
                inputClass="input_field"
                inputType="text"
                containerClass="input_container"
                validationChecks={new Array<string>(VALIDATION_EMPTY, VALIDATION_EMAIL)}
                getValue={getEmailValue}
                setValue={setEmailValue}
              />
            </div>
            {(IsAdmin() || IsDCMF()) && (
              <div className="form_row">
                <Dropdown
                  title="Land"
                  inputClass="dropdown_field"
                  dropdownId="pers_country"
                  containerClass="input_container"
                  options={COUNTRY_OPTIONS}
                  getValue={GetCountryDropdownModel(getCountryValue)}
                  setValue={(option) => setCountryValue((option as DropdownOptionsModel).value)}
                  initialValue={GetCountryDropdownModel(getCountryValue)}
                />
              </div>
            )}
            <div className="form_row">
              <Dropdown
                title="Land belastingsplicht"
                inputClass="dropdown_field"
                dropdownId="pers_tax_country"
                containerClass="input_container"
                options={COUNTRY_OPTIONS}
                getValue={GetCountryDropdownModel(getCountryTaxValue)}
                setValue={(option) => setCountryTaxValue((option as DropdownOptionsModel).value)}
                initialValue={GetCountryDropdownModel(getCountryTaxValue)}
                isDisabled={!IsAdmin() && !IsDCMF()}
              />
            </div>

            <AddressItem
              getZipcodeValue={getZipcodeValue}
              setZipcodeValue={setZipcodeValue}
              getHouseNumberValue={getHouseNumberValue}
              setHouseNumberValue={setHouseNumberValue}
              getAdditionValue={getAdditionValue}
              setAdditionValue={setAdditionValue}
              getCityValue={getCityValue}
              setCityValue={setCityValue}
              getStreetValue={getStreetValue}
              setStreetValue={setStreetValue}
              validatePostalcode={getCountryValue === 'NL'}
            />

            {relationFieldsVisible() && getRelationIsSignee === 'Ja' && (
              <div className="relation_person_extra_input_column">
                <div className="form_row">
                  <InputField
                    inputId="pers_phone"
                    title="Telefoonnummer bijv: '+3163333333' of '063333333'"
                    inputClass="input_field"
                    inputType="text"
                    containerClass="input_container"
                    validationChecks={new Array<string>(VALIDATION_EMPTY, VALIDATION_PHONE)}
                    phoneRestrictions={true}
                    getValue={getRelationPhoneNumberValue}
                    setValue={setRelationPhoneNumberValue}
                  />
                </div>
                <div className="form_row">
                  <InputField
                    inputId="pers_email"
                    title="E-mailadres"
                    inputClass="input_field"
                    inputType="text"
                    containerClass="input_container"
                    validationChecks={new Array<string>(VALIDATION_EMPTY, VALIDATION_EMAIL)}
                    getValue={getRelationEmailValue}
                    setValue={setRelationEmailValue}
                  />
                </div>
                <AddressItem
                  getZipcodeValue={getRelationZipcodeValue}
                  setZipcodeValue={setRelationZipcodeValue}
                  getHouseNumberValue={getRelationHouseNumberValue}
                  setHouseNumberValue={setRelationHouseNumberValue}
                  getAdditionValue={getRelationAdditionValue}
                  setAdditionValue={setRelationAdditionValue}
                  getCityValue={getRelationCityValue}
                  setCityValue={setRelationCityValue}
                  getStreetValue={getRelationStreetValue}
                  setStreetValue={setRelationStreetValue}
                  validatePostalcode={getRelationCountryValue === 'NL'}
                />
                {(IsAdmin() || IsDCMF()) && (
                  <div className="form_row">
                    <Dropdown
                      title="Land"
                      inputClass="dropdown_field"
                      dropdownId="pers_country"
                      containerClass="input_container"
                      options={COUNTRY_OPTIONS}
                      getValue={GetCountryDropdownModel(getRelationCountryValue)}
                      setValue={(option) => setRelationCountryValue((option as DropdownOptionsModel).value)}
                      initialValue={GetCountryDropdownModel(getRelationCountryValue)}
                    />
                  </div>
                )}
                <div className="form_row">
                  <Dropdown
                    title="Land belastingsplicht"
                    inputClass="dropdown_field"
                    dropdownId="pers_tax_country"
                    containerClass="input_container"
                    options={COUNTRY_OPTIONS}
                    getValue={GetCountryDropdownModel(getRelationCountryTaxValue)}
                    setValue={(option) => setRelationCountryTaxValue((option as DropdownOptionsModel).value)}
                    initialValue={GetCountryDropdownModel(getRelationCountryTaxValue)}
                    isDisabled={!IsAdmin() && !IsDCMF()}
                  />
                </div>
              </div>
            )}

            <div className="form_button_container form_col_start_2">
              <div className="button_cancel" onClick={() => handleClickCancel()}>
                <p className="button_cancel_text">Annuleren</p>
              </div>
              <input type="submit" className="button_save" value="Opslaan" />
            </div>
          </div>
        </form>
      </div>
      <DefaultToastContainer />
    </div>
  );
};

export default ObjectApplicantForm;
