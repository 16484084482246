import {
  LOAN_DURATION_MAX,
  LOAN_DURATION_MIN,
  LOAN_PART_DURATION_MIN,
  LOAN_PART_DURATION_MAX,
  LOAN_PART_VALUE_MIN,
  LOAN_PART_VALUE_MAX,
} from '../constants';

export const PHONE_NUMBER_INVALID = 'Het telefoon nummer voldoet niet aan de valide notatie';
export const EMAIL_INVALID = 'Dit is geen correct email-adres';
export const INITIALS_NOT_FILLED_RELATION = 'De initialen van de partner zijn niet ingevuld';
export const LASTNAME_NOT_FILLED_RELATION = 'De achternaam van de partner zijn niet ingevuld';
export const NAME_INVALID = 'Dit is geen valide naam';
export const COMPANY_NAME_INVALID = 'Dit is geen valide bedrijfsnaam';
export const INITIALS_INVALID = 'Dit zijn geen valide initialen';
export const FINANCE_DURATION_NOT_NUMBER = 'Looptijd is geen getal!';
export const POSTAL_CODE_INVALID = 'Dit is geen correct postcode';
export const RSIN_INVALID = 'Het RSIN-nummer moet uit 9 cijfers bestaan';
export const CHAMBER_OF_COMMERCE_NUMBER_INVALID = 'Het Kamer van Koophandel nummer moet uit 8 cijfers bestaan';

export const DIRECTOR_LIST_HAS_NO_ACTIVE_DIRECTOR = 'Dit bedrijf vereist één of meer bestuurders';
export const DIRECTOR_LIST_HAS_NO_DIRECTORS = 'Er zijn nog geen mogelijke bestuurders beschikbaar, maak eerst een natuurlijk persoon aan';
export const INPUT_FIELD_NO_NUMBER = 'De ingevoerde waarde moet een getal zijn';
export const DEPOSIT_AMOUNT_EXCEEDED_MAX = 'Bouwdepot bedrag max niet boven de €1.000.000 liggen';
export const GROUND_LEASE_END_DATE_NOT_FILLED = 'De erfpacht einddatum is niet ingevuld';
export const YEARLY_GROUND_LEASE_NOT_FILLED = 'De jaarlijkse erfpachtkosten is niet ingevuld';
export const MARKET_RENT_NOT_FILLED = 'De markthuur is niet ingevuld';
export const RENOVATION_WORTH_NOT_FILLED = 'De waarde na verbouwing is niet ingevuld';
export const COLLATERAL_TYPE_NOT_ALLOWED = 'Dit type onderpand is niet toegestaan in combinatie met vorige onderpanden'
export const COLLATERAL_SUBTYPE_NOT_ALLOWED = 'Deze bestemming is niet toegestaan in combinatie met vorige onderpanden'
export const COLLATERAL_COMBINATION_NOT_ALLOWED = 'Deze combinatie van type en bestemming zijn niet toegestaan'

export const RESIDENTIAL_MARKET_VALUE_NOT_FILLED = 'De residentiële marktwaarde is niet ingevuld';
export const RESIDENTIAL_RENT_NOT_FILLED = 'De residentiële markthuur is niet ingevuld';
export const COMMERCIAL_MARKET_VALUE_NOT_FILLED = 'De commerciële marktwaarde is niet ingevuld';
export const COMMERCIAL_RENT_NOT_FILLED = 'De commerciële markthuur is niet ingevuld';
export const COMMERCIAL_LOST_THROUGH_VACANCY_NOT_FILLED = 'Markthuur van het gedeelte leegstaand is niet ingevuld!';
export const COMMERCIAL_CONTRACT_RENTS_NOT_FILLED = 'Contracthuur huurcontract(en) is of zijn niet ingevuld!';

export const LOAN_DURATION_RANGE_INVALID = `De looptijd in maanden moet tussen de ${LOAN_DURATION_MIN} en ${LOAN_DURATION_MAX} maanden liggen`;
export const LOAN_PART_DURATION_RANGE_INVALID = `De looptijd in maanden moet tussen de ${LOAN_PART_DURATION_MIN} en ${LOAN_PART_DURATION_MAX} maanden liggen`;
export const LOAN_PART_VALUE_RANGE_INVALID = `De hoogte van het leningsdeel moet tussen de €${LOAN_PART_VALUE_MIN} en €${LOAN_PART_VALUE_MAX} liggen`;
export const DESIRED_FINANCING_EXCEEDED = `De gewenste financiering mag niet hoger liggen dan €3.000.000`;
export const LTV_INTEREST_ONLY_EXCEEDED = "Het aflossingsvrije deel van de lening mag niet hoger zijn dan 75%"

export const DOSSIER_ERROR = 'Er is iets fout gegaan met het aanmaken van het dossier';
export const FORM_SEND_FAILED =
  'Fout: Er gaat iets niet goed met de aanvraag. Sluit dit venster niet en neem contact op met acceptatie T: 0207197197';
export const FORM_SEND_FAILED_RATES_LTV = 'De LTV waarde is te hoog';
export const FORM_SEND_FAILED_RATES_ICR = 'De ICR waarde is te laag';

export const FIELD_EMPTY = 'Dit veld mag niet leeg zijn';
export const FORM_ERROR_MESSAGE = 'Velden met een sterretje zijn verplicht in te vullen';

export const NO_REQUEST_HISTORY_FOUND = 'Geen aanvraaghistory gevonden';
export const NO_INTEREST_RATES_FOUND = 'Er zijn geen rentetarieven gevonden';
export const NO_UPFRONT_FEES_FOUND = 'Er zijn geen upfront fees gevonden';
export const NO_VALID_FILE_FOUND = "Het aangeleverde document is geen valide 'CSV' file";
export const INVALID_CSV_ROWS = 'Het geïmporteerde CVS-bestand bevat teveel rijen!';
export const INVALID_CSV_COLUMNS = 'Het geïmporteerde CVS-bestand bevat teveel kolommen!';
export const INVALID_CSV_NAN = 'Het geïmporteerde CVS-bestand bestaat niet compleet uit getallen!';
export const INTEREST_RATES_CHANGED = 'De rentetarieven zijn aangepast, druk op opslaan om de aanpassing door te voeren';
export const UPFRONT_FEE_CHANGED = 'De upfront fees zijn aangepast, druk op opslaan om de aanpassing door te voeren';
