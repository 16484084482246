import { toast, ToastContent } from "react-toastify";

export const notify = (message: ToastContent, succes?: boolean) => {
  if (succes) {
    toast.success(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: message!.toString(),
      onClose: () => toast.dismiss(message!.toString()),
    });
    return;
  }

  toast.error(message, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: message!.toString(),
    onClose: () => toast.dismiss(message!.toString()),
  });
};
