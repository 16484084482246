import './ObjectCreateButton.css';
import { getIcon } from 'helpers/helpers';

type Props = {
  text: string;
  icon: string;
  isDisabled?: boolean
};

const ObjectApplicantCreateButton = ({ text, icon, isDisabled = false }: Props): JSX.Element => {
  return (
    <div className={isDisabled ? "button_container_disabled" :"button_container"}>
      <div className="button_icon_container">
        <img src={getIcon(icon)} className="button_icon" alt="logo" />
      </div>
      <p className={"button_text " + (isDisabled ? "button_text_disabled" : "")}>{text}</p>
    </div>
  );
};

export default ObjectApplicantCreateButton;
