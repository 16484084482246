import { ApplicantModel } from "./applicantModel";
import { CollateralModel } from "./collateralModel";
import { CompanyModel } from "./companyModel";
import { FinancingModel } from "./financingModel";
import { LoanPartModel } from "./loanPartModel";
import { v4 as uuidv4 } from 'uuid';
import { FormUserModel } from "./formUserModel";

export class RequestModel {
  key: string = uuidv4();
  applicants: ApplicantModel[] = [];
  companies: CompanyModel[] = [];
  collaterals: CollateralModel[] = [];
  financing: FinancingModel = new FinancingModel();
  loanParts: LoanPartModel[] = [];
  user: FormUserModel = new FormUserModel();
}
