import "./RadioGroup.css";
import { SetStateAction } from "react";

type Props = {
  title: string;
  inputClass: string;
  options: string[];
  getValue: string;
  setValue: React.Dispatch<SetStateAction<string>>;
  isRequired?: boolean;
};

const RadioGroup = ({
  title,
  inputClass,
  options,
  getValue,
  setValue,
  isRequired = false
}: Props) => {
  const GetRadioGroupClass = (button: string) => {
    if (button === getValue) {
      return "selected_button";
    }
    return "radio_button";
  };

  const GetRadioTextClass = (button: string) => {
    if (button === getValue) {
      return "selected_text";
    }
    return "radio_button_text";
  };

  return (
    <div className="input_container">
      <p className={isRequired ? "input_required" : "input_title"}>{title}</p>
      <div className={isRequired && getValue == "NO_OPTION_SELECTED" ? "input_error" : ""}></div>
      <div className="radio_button_container">
        {options.map((x, index) => {
          return (
            <div
              key={index}
              className={GetRadioGroupClass(x)}
              onClick={() => {
                setValue(x);
              }}
            >
              <p className={GetRadioTextClass(x)}>{x}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioGroup;
