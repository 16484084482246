import NumberFormat from "react-number-format";
import "./ObjectDataViewSmall.css";
import object_background from "assets/object_background.svg";

type Props = {
  index: number;
  subOption?: boolean;
  title: string;
  subtitle: string;
};

const ObjectDataViewSmallCurrency = ({ index, subOption, title, subtitle }: Props) => {
  var dataViewClass =
    "object_data_container_small" + (subOption ? "_sub_option" : "");
  var dataContainerClass =
    "object_container_small" + (subOption ? "_sub_option" : "");
  
    const getLabelValue = (label: string, labelClass: string) => {
      const valueToNumber = Number(label);
  
      if (isNaN(valueToNumber)) {
        return <p className={labelClass}>{label}</p>;
      } else {
        return (
          <p className={labelClass}>
            <NumberFormat
              value={label}
              displayType={'text'}
              thousandsGroupStyle="thousand"
              thousandSeparator="."
              prefix={'€'}
              decimalSeparator=","
            />
          </p>
        );
      }
    };

  return (
    <div className={dataContainerClass}>
      <div className={dataViewClass}>
        <div className="object_row_container_small">
          {getLabelValue(title, "object_title_small")}
          {getLabelValue(subtitle, "object_type_small")}
        </div>
        {subOption ? (
          <div />
        ) : (
          <img
            src={object_background}
            className="object_background_small"
            alt="logo"
          />
        )}
      </div>
    </div>
  );
};

export default ObjectDataViewSmallCurrency;
