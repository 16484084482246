import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './css/ObjectForm.css';
import './css/ObjectFinancingForm.css';
import { useEffect, useState } from 'react';
import InputField from 'components/form/InputField';
import RadioGroup from 'components/form/RadioGroup';
import { useNavigate } from 'react-router-dom';
import { FinancingModel } from 'models/financingModel';
import {
  COLLATERAL_DATA,
  FINANCING_DATA,
  LOAN_PART_DATA,
  LOAN_PART_VALUE_MAX,
  LTV_INTEREST_ONLY_MAX_PERCENTAGE,
  ROLE_ADMIN,
  ROLE_DCMF,
} from 'constants/constants';
import ValutaField from 'components/form/ValutaField';
import { LoanPartModel } from 'models/loanPartModel';
import ObjectApplicantCreateButton from 'components/ObjectApplicantCreateButton';
import { getNextButtonClass } from 'helpers/helpers';
import { DefaultToastContainer } from 'components/toast/toast-container.component';
import { TITLE_FINANCING } from 'constants/textConstants';
import { notify } from 'components/toast/notify.component';
import { DESIRED_FINANCING_EXCEEDED, FORM_ERROR_MESSAGE, LTV_INTEREST_ONLY_EXCEEDED } from 'constants/messages/errorMessages';
import { VALIDATION_DESIRED_FINANCING, VALIDATION_LOAN_DURATION } from 'constants/validationCheckTypes';
import { PAGE_CREATE_LOAN_PART, PAGE_OVERVIEW } from 'constants/pageConstants';
import { useKeycloak } from '@react-keycloak/web';
import ObjectDataView from 'components/views/ObjectDataView';
import { useTranslation } from 'react-i18next';
import ObjectDataViewRates from 'components/views/ObjectDataViewRates';
import { calculateInterestValues } from 'helpers/rateCalculations';
import { CollateralModel } from 'models/collateralModel';

type Props = {
  clickNextHandler: React.MouseEventHandler<HTMLDivElement>;
  clickBackHandler: React.MouseEventHandler<HTMLDivElement>;
};

const ObjectFinancingForm = ({ clickBackHandler }: Props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'object-financing-form',
  });

  const buildingStockDuration = '6';
  const { keycloak } = useKeycloak();
  const roles = keycloak.tokenParsed?.realm_access?.roles;
  const isAdmin = roles?.find((role) => role === ROLE_ADMIN) !== undefined;
  const isDCMF = roles?.find((role) => role === ROLE_DCMF) !== undefined;

  const [getLtv, setLtv] = useState<Number>(0);
  const [getIcr, setIcr] = useState<Number>(0);
  const [getDscr, setDscr] = useState<Number>(0);
  const [getMaxLtv, setMaxLtv] = useState<Number>(-1);
  const [getMinIcr, setMinIcr] = useState<Number>(-1);
  const [getMinDscr, setMinDscr] = useState<Number>(-1);
  const [getLtvInterestOnly, setLtvInterestOnly] = useState<number>(0);
  const [getTotalValue, setTotalValue] = useState<number>(0);

  const navigate = useNavigate();
  const savedValue = sessionStorage.getItem(LOAN_PART_DATA) || '';
  let loanParts: LoanPartModel[] = [];

  if (savedValue !== '') {
    loanParts = JSON.parse(savedValue);
  }

  const [getLoanParts, setLoanParts] = useState<LoanPartModel[]>(loanParts);

  let savedValueCollateral = sessionStorage.getItem(COLLATERAL_DATA) || '';
  let collaterals: CollateralModel[] = [];
  if (savedValueCollateral !== '') {
    collaterals = JSON.parse(savedValueCollateral);
  }

  const [getValueCollaterals] = useState<CollateralModel[]>(collaterals);

  const getDesiredFinancing = (): number => {
    const savedValue = sessionStorage.getItem(LOAN_PART_DATA) || '';
    let loanParts: LoanPartModel[] = [];

    if (savedValue !== '') {
      loanParts = JSON.parse(savedValue);
    }

    if (loanParts && loanParts.length > 0) {
      let totalLoan = 0;
      for (let i = 0; i < loanParts.length; i++) {
        const loan = Number(loanParts[i].loanPartAmount);
        totalLoan += loan;
      }

      return totalLoan;
    }

    return 0;
  };

  const validateForm = (isAdmin: boolean, isDCMF: boolean): boolean => {
    const allWithErrorClass = Array.from(document.getElementsByClassName('input_error'));
    let formValid = true;

    if (allWithErrorClass.length > 0) {
      notify(FORM_ERROR_MESSAGE, false);
      formValid = false;
    }

    if (getLtvInterestOnly > LTV_INTEREST_ONLY_MAX_PERCENTAGE) {
      notify(LTV_INTEREST_ONLY_EXCEEDED, false);
      formValid = false;
    }

    const desiredFinancingContent = getDesiredFinancing();
    const desiredFinancing = Number(desiredFinancingContent);
    if (desiredFinancing && !Number.isNaN(desiredFinancing) && desiredFinancing > LOAN_PART_VALUE_MAX && !isAdmin && !isDCMF) {
      formValid = false;
      notify(DESIRED_FINANCING_EXCEEDED, false);
    }

    return formValid;
  };

  const clickEditHandler = (index: number) => {
    navigate({
      pathname: PAGE_CREATE_LOAN_PART,
      search: '?index=' + index,
    });
  };

  const hasUpfrontFee = (): boolean => {
    if (loanParts.length === 0) return false;

    return loanParts.some((part) => part.hasUpfrontFee);
  };

  const clickDeleteHandler = (index: number) => {
    const loanParts = getLoanParts;
    loanParts.splice(index, 1);
    setLoanParts([...loanParts]);
    sessionStorage.setItem(LOAN_PART_DATA, JSON.stringify(loanParts));
  };

  const clickCreateLoanPart = () => {
    saveFinancingData();
    navigate({ pathname: PAGE_CREATE_LOAN_PART });
  };

  let currentFinancing = new FinancingModel();
  let financing: FinancingModel;
  const savedValues = sessionStorage.getItem(FINANCING_DATA) || '';
  if (savedValues !== '') {
    financing = JSON.parse(savedValues);
    currentFinancing = financing;
  }

  const [getFinancingType, setFinancingType] = useState<string>(currentFinancing.financingType);
  const [getDuration, setDuration] = useState<string>(currentFinancing.duration);
  const [getBuyAmount] = useState<string>(currentFinancing.buyAmount);
  const [getBuildingStockValue] = useState<string>(currentFinancing.buildingStock);
  const [getBuildingStockAmountValue] = useState<string>(currentFinancing.buildingStockAmount);

  const handleSubmitForm = () => {
    if (validateForm(isAdmin, isDCMF)) {
      saveFinancingData();
      navigate({ pathname: PAGE_OVERVIEW });
    }
  };

  const saveFinancingData = () => {
    const financingData = new FinancingModel();
    financingData.financingType = getFinancingType;
    financingData.duration = getDuration;
    financingData.buyAmount = getBuyAmount;
    financingData.desiredMortgageRegistration = getDesiredFinancing().toString();
    financingData.buildingStock = getBuildingStockValue;
    financingData.buildingStockAmount = getBuildingStockAmountValue;
    financingData.buildingStockDuration = financingData.buildingStock === 'Ja' ? buildingStockDuration : '';

    sessionStorage.setItem(FINANCING_DATA, JSON.stringify(financingData));
  };

  const getInterest = (): number => {
    return 10;
  };

  const getUpfrontFee = (): number => {
    const upfrontPercentage = Number(loanParts.find((part) => part.hasUpfrontFee)?.upfrontFee) / 100;
    return (getDesiredFinancing() * upfrontPercentage);
  };

  useEffect(() => {
    calculateInterestValues(getValueCollaterals, loanParts, keycloak.token).then((interestData) => {
      setLtv(interestData.ltv);
      setIcr(interestData.icr);
      setDscr(interestData.dscr);
      setMaxLtv(hasUpfrontFee() ? 75 : interestData.maxLtv);
      setMinIcr(interestData.minIcr);
      setMinDscr(interestData.minDscr);
      setLtvInterestOnly(interestData.ltvInterestOnly);
      setTotalValue(interestData.totalValue);
    });
  }, [loanParts]);

  return (
    <div className="app_container">
      <div style={{marginBottom: "108px"}} className="form_center">
        <p className="title_create_object">{TITLE_FINANCING}</p>
        <form className="form">
          <div className="form_container form_left">
            <div className="form_row">
              <RadioGroup
                title={t('mortgage-type')}
                inputClass="radio_group"
                options={[t('new-request'), t('other-request')]}
                getValue={getFinancingType}
                setValue={setFinancingType}
              />
            </div>
            <div className="form_row">
              <InputField
                inputId="finance_duration"
                title={t('finance-duration')}
                inputClass="input_field"
                inputType="number"
                maxLength={3}
                containerClass="input_container"
                validationChecks={new Array<string>(VALIDATION_LOAN_DURATION)}
                getValue={getDuration}
                setValue={setDuration}
              />
            </div>
          </div>
          <div className="form_container form_right" style={{ display: 'flex', alignItems: 'end' }}>
            <div className="form_row">
              <ValutaField
                title={t('desired-financing')}
                disable={true}
                validationChecks={new Array<string>(VALIDATION_DESIRED_FINANCING)}
                inputId="finance_preferred"
                getValue={getDesiredFinancing().toString()}
              />
            </div>
          </div>
        </form>

        <div className="seperator"></div>
        <form className="form">
          <div className="form_container form_left">
            <p className="title_create_object" style={{ marginTop: 0 }}>
              Leningsdelen
            </p>
            <div className="content">
              {getLoanParts.map(({ loanPartAmount, duration, _, key }: any, index) => {
                return (
                  <ObjectDataView
                    index={index}
                    title={loanPartAmount}
                    subtitle={`${getLoanParts[index].redemptionType.label}\n${duration} maanden`}
                    key={key}
                    clickEditEvent={clickEditHandler}
                    clickDeleteEvent={clickDeleteHandler}
                  />
                );
              })}
            </div>

            {loanParts.some((loanPart) => loanPart.hasUpfrontFee) ? (
              <div />
            ) : (
              <div className="button_link" onClick={clickCreateLoanPart}>
                <ObjectApplicantCreateButton text={t('add-loan-part')} icon="plus" />
              </div>
            )}

            <div className="button_next_container">
              <div className="nav_button button_back" onClick={clickBackHandler}>
                <p className="nav_button_text button_back_text">{t('previous')}</p>
              </div>

              <div className={'nav_button button_next ' + getNextButtonClass(getLoanParts.length)} onClick={handleSubmitForm}>
                <p className="nav_button_text button_next_text">{t('next')}</p>
              </div>
            </div>
          </div>
          <div className="form_container form_right">
            <p className="title_create_object" style={{ marginTop: 0 }}>
              Berekening
            </p>
            <div style={{ marginTop: 12 }}>
              <ObjectDataViewRates
                upfrontFee={getUpfrontFee()}
                interest={getInterest()}
                ltv={getLtv}
                icr={getIcr}
                dscr={getDscr}
                maxLtv={getMaxLtv}
                minIcr={getMinIcr}
                minDscr={getMinDscr}
                ltvInterestOnly={getLtvInterestOnly}
                totalValue={getTotalValue}
              />
            </div>
          </div>
        </form>
      </div>

      <DefaultToastContainer />
    </div>
  );
};

export default ObjectFinancingForm;
