import {
	DEPOSIT_AMOUNT_MAX,
	KVK_NUMBER_LENGTH,
	LOAN_DURATION_MAX,
	LOAN_DURATION_MIN,
	LOAN_PART_DURATION_MAX,
	LOAN_PART_DURATION_MIN,
	LOAN_PART_VALUE_MAX,
	LOAN_PART_VALUE_MIN,
	RSIN_NUMBER_LENGTH,
} from "./constants";
import {
	CHAMBER_OF_COMMERCE_NUMBER_INVALID,
	COMPANY_NAME_INVALID,
	DEPOSIT_AMOUNT_EXCEEDED_MAX,
	DESIRED_FINANCING_EXCEEDED,
	EMAIL_INVALID,
	FIELD_EMPTY,
	INITIALS_INVALID,
	INPUT_FIELD_NO_NUMBER,
	LOAN_DURATION_RANGE_INVALID,
	LOAN_PART_DURATION_RANGE_INVALID,
	LOAN_PART_VALUE_RANGE_INVALID,
	NAME_INVALID,
	PHONE_NUMBER_INVALID,
	POSTAL_CODE_INVALID,
	RSIN_INVALID,
} from "./messages/errorMessages";
import {
	COMPANY_NAME_REGEX,
	EMAIL_REGEX,
	INITIALS_REGEX,
	NAME_REGEX,
	PHONE_REGEX,
	POST_CODE_REGEX as POSTAL_CODE_REGEX,
} from "./regex";
import {
	VALIDATION_RSIN,
	VALIDATION_EMAIL,
	VALIDATION_EMPTY,
	VALIDATION_INITIALS,
	VALIDATION_KVK,
	VALIDATION_NAME,
	VALIDATION_PHONE,
	VALIDATION_POSTAL_CODE,
	VALIDATION_DEPOSIT_AMOUNT,
	VALIDATION_LOAN_DURATION,
	VALIDATION_LOAN_PART_VALUE,
	VALIDATION_LOAN_PART_DURATION,
	VALIDATION_DESIRED_FINANCING,
	VALIDATION_COMPANY_NAME,
} from "./validationCheckTypes";

export const Validate = (
	value: string,
	checkTypes: string[],
	validationData: string | undefined
) => {
	var errors = new Array<string>();
	checkTypes.forEach((checkType) => {
		switch (checkType) {
			case VALIDATION_EMPTY:
				errors.push(ValidateEmpty(value));
				break;
			case VALIDATION_EMAIL:
				errors.push(ValidateEmail(value));
				break;
			case VALIDATION_PHONE:
				errors.push(ValidatePhoneNumber(value));
				break;
			case VALIDATION_INITIALS:
				errors.push(ValidateInitials(value));
				break;
			case VALIDATION_NAME:
				errors.push(ValidateName(value));
				break;
			case VALIDATION_COMPANY_NAME:
				errors.push(ValidateCompanyName(value));
				break;
			case VALIDATION_POSTAL_CODE:
				errors.push(ValidatePostalCode(value, validationData));
				break;
			case VALIDATION_RSIN:
				errors.push(ValidateRsin(value));
				break;
			case VALIDATION_KVK:
				errors.push(ValidateKvk(value));
				break;
			case VALIDATION_DEPOSIT_AMOUNT:
				errors.push(ValidateDepositAmount(value));
				break;
			case VALIDATION_LOAN_DURATION:
				errors.push(ValidateLoanDuration(value));
				break;
			case VALIDATION_LOAN_PART_DURATION:
				errors.push(ValidateLoanPartDuration(value));
				break;
			case VALIDATION_LOAN_PART_VALUE:
				errors.push(ValidateLoanPartValue(value));
				break;
			case VALIDATION_DESIRED_FINANCING:
				errors.push(ValidateDesiredFinancing(value));
				break;
		}
	});

	return errors;
};

export const ValidateEmpty = (input: string) => {
	if (input.trim() === "") {
		return FIELD_EMPTY;
	}

	return "";
};

export const ValidatePhoneNumber = (phoneNumber: string) => {
	var regexMatch = phoneNumber.match(PHONE_REGEX) ?? [""];
	if (!regexMatch || regexMatch[0] != phoneNumber) {
		return PHONE_NUMBER_INVALID;
	}

	return "";
};

export const ValidateEmail = (email: string) => {
	if (!email.match(EMAIL_REGEX)) {
		return EMAIL_INVALID;
	}

	return "";
};

export const ValidateInitials = (initials: string) => {
	if (!initials.match(INITIALS_REGEX)) {
		return INITIALS_INVALID;
	}

	return "";
};

export const ValidateName = (name: string) => {
	if (!name.match(NAME_REGEX)) {
		return NAME_INVALID;
	}

	return "";
};

export const ValidateCompanyName = (name: string) => {
	let nameCopy = name;
	if (!name.match(COMPANY_NAME_REGEX) || nameCopy.replace(/\s+/g, ' ').trim() !== name) {
		return COMPANY_NAME_INVALID;
	}

	return "";
};

export const ValidatePostalCode = (
	postalCode: string,
	countryData: string | undefined
) => {
	if (countryData === "") countryData = "NL";

	if (
		(countryData === undefined || countryData === "NL") &&
		!postalCode.match(POSTAL_CODE_REGEX)
	) {
		return POSTAL_CODE_INVALID;
	}

	return "";
};

export const ValidateRsin = (rsin: string) => {
	if (rsin.length !== RSIN_NUMBER_LENGTH) {
		return RSIN_INVALID;
	}

	return "";
};

export const ValidateKvk = (kvk: string) => {
	if (kvk.length !== KVK_NUMBER_LENGTH) {
		return CHAMBER_OF_COMMERCE_NUMBER_INVALID;
	}

	return "";
};

export const ValidateDepositAmount = (amount: string) => {
	amount = amount.substring(1);
	amount = amount.split(".").join("");

	var value = Number(amount);
	if (!value) {
		return INPUT_FIELD_NO_NUMBER;
	}

	if (value > DEPOSIT_AMOUNT_MAX) {
		return DEPOSIT_AMOUNT_EXCEEDED_MAX;
	}

	return "";
};

export const ValidateLoanDuration = (amount: string) => {
	var value = Number(amount);
	if (!value) {
		return FIELD_EMPTY;
	}

	if (value < LOAN_DURATION_MIN || value > LOAN_DURATION_MAX) {
		return LOAN_DURATION_RANGE_INVALID;
	}

	return "";
};

export const ValidateLoanPartDuration = (amount: string) => {
	var value = Number(amount);
	if (!value) {
		return FIELD_EMPTY;
	}

	if (value < LOAN_PART_DURATION_MIN || value > LOAN_PART_DURATION_MAX) {
		return LOAN_PART_DURATION_RANGE_INVALID;
	}

	return "";
};

export const ValidateLoanPartValue = (amount: string) => {
	amount = amount.substring(1);
	amount = amount.split(".").join("");
	var value = Number(amount);
	if (!value) {
		return FIELD_EMPTY;
	}

	if (value < LOAN_PART_VALUE_MIN || value > LOAN_PART_VALUE_MAX) {
		return LOAN_PART_VALUE_RANGE_INVALID;
	}

	return "";
};

export const ValidateDesiredFinancing = (amount: string) => {
	amount = amount.substring(1);
	amount = amount.split(".").join("");
	var value = Number(amount);
	if (!value) {
		return FIELD_EMPTY;
	}

	if (value > LOAN_PART_VALUE_MAX) {
		return DESIRED_FINANCING_EXCEEDED;
	}

	return "";
};
