export class UserModel {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  lastActive: number;
  groups: string[];
  roles: string[];
  broker: string;
  intermediaryNumber: string = '';

  constructor(
    id: string,
    firstName: string,
    lastName: string,
    lastActive: number,
    groups: string[],
    roles: string[],
    email: string,
    broker: string,
    intermediaryNumber: string
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.name = firstName + ' ' + lastName;
    this.lastActive = lastActive;
    this.groups = groups;
    this.roles = roles;
    this.email = email;
    this.broker = broker;
    this.intermediaryNumber = intermediaryNumber;
  }
}
