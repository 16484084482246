import "./form/Dropdown.css";
import { SetStateAction } from "react";
import Select, { SingleValue } from "react-select";
import { DropdownOptionsModel } from "models/dropdownOptionsModel";
import { useTranslation } from "react-i18next";

type UserDropdownProps = {
  title: string;
  dropdownId: string;
  inputClass: string;
  options: DropdownOptionsModel[];
  containerClass: string;
  validationChecks?: Array<string>;
  getValue: DropdownOptionsModel;
  setValue: React.Dispatch<SetStateAction<DropdownOptionsModel>>;
  isDisabled?: boolean;
  initialValue?: DropdownOptionsModel;
};

const UserDropdown = ({
  title,
  dropdownId,
  inputClass,
  containerClass,
  options,
  getValue,
  setValue,
  isDisabled,
  initialValue = new DropdownOptionsModel(),
}: UserDropdownProps): JSX.Element => {
  const { t } = useTranslation(undefined, { keyPrefix: "user-management" });
  const HandleChange = (value: SingleValue<DropdownOptionsModel>) => {
    if (value !== null) {
      setValue(value);
    }
  };

  return (
    <div className={containerClass}>
      <p className="input_title">{title}</p>
      <Select
        id={dropdownId}
        className={inputClass}
        options={options}
        onChange={HandleChange}
        placeholder={t("me")}
        isDisabled={isDisabled}
        defaultValue={
          initialValue && initialValue.value && initialValue.value !== ""
            ? initialValue
            : getValue
        }
        maxMenuHeight={100}
      />
    </div>
  );
};

export default UserDropdown;
