import "./Dropdown.css";
import { SetStateAction } from "react";
import { ApplicantModel } from "models/applicantModel";
import DirectorItem from "../form/DirectorItem";
import {
  DIRECTOR_LIST_HAS_NO_ACTIVE_DIRECTOR,
  DIRECTOR_LIST_HAS_NO_DIRECTORS,
} from "constants/messages/errorMessages";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  inputClass: string;
  containerClass: string;
  applicants: ApplicantModel[];
  setValue: React.Dispatch<
    SetStateAction<{ applicant: ApplicantModel; index: number }>
  >;
};

const DirectorList = ({
  title,
  containerClass,
  applicants,
  setValue,
}: Props) => {
  const { t } = useTranslation(undefined, { keyPrefix: "director-list-item" });
  const companyHasNoDirector = applicants.every(
    (applicant) =>
      !applicant.isDirector && !applicant.isUbo && !applicant.isAuthorizedSignee
  );

  return (
    <div className={containerClass}>
      {companyHasNoDirector ? (
        <div className="input_error">
          {applicants.length === 0
            ? DIRECTOR_LIST_HAS_NO_DIRECTORS
            : DIRECTOR_LIST_HAS_NO_ACTIVE_DIRECTOR}
        </div>
      ) : (
        <div></div>
      )}
      <p className="input_title">{title}</p>
      {applicants.length > 0 ? (
        <div>
          {" "}
          {applicants.map((currentApplicant, applicantIndex) => {
            return (
              <DirectorItem
                currentApplicant={currentApplicant}
                setValue={setValue}
                index={applicantIndex}
              />
            );
          })}{" "}
        </div>
      ) : (
        <p className="input_title input_container_small transparent">
          {t("no-board-members")}
        </p>
      )}
    </div>
  );
};

export default DirectorList;
