import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './css/UserDashboard.css';
import arrow_right from 'assets/arrow_right.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { ACTIVE_TOKEN, ACTIVE_USER_ID, ACTIVE_USER_NAME, BASE_URL_API, HEADER_AUTHORIZATION, KEY_SUCCESS, KEY_USER_ID, ROLE_ADMIN } from 'constants/constants';
import { useState } from 'react';
import { AmountModel } from 'models/amountModel';
import { GetUserIdFromStorage } from 'helpers/storageUtility';
import { DefaultToastContainer } from 'components/toast/toast-container.component';
import { notify } from 'components/toast/notify.component';
import Background from 'components/background/Background';
import Header from 'components/header/Header';
import { useTranslation } from 'react-i18next';

const useIsAdmin = (): boolean => {
  const { keycloak } = useKeycloak();

  let roles = keycloak.tokenParsed?.realm_access?.roles;
  let isAdmin = false;

  window.localStorage.setItem(ACTIVE_USER_ID, keycloak.tokenParsed?.sub + '');

  roles?.forEach((role) => {
    if (role === ROLE_ADMIN) {
      window.localStorage.setItem(ACTIVE_TOKEN, keycloak.token!.toString());
      isAdmin = true;
    }
  });

  return isAdmin;
};

const UserDashboard = (): JSX.Element => {
  const { t } = useTranslation(undefined, { keyPrefix: 'success' });

  const [getAanvraagAmount, setAanvraagAmount] = useState<string>('');
  const [getUserAmount, setUserAmount] = useState<string>('');

  let navigate = useNavigate();

  const { keycloak } = useKeycloak();
  var userName = keycloak.idTokenParsed?.preferred_username;
  window.localStorage.setItem(ACTIVE_USER_NAME, userName);

  const amountOfRequest = (isAdmin: boolean) => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(KEY_USER_ID, GetUserIdFromStorage());
    myHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)

    var endpoint = isAdmin ? 'api/total' : 'api/amount';
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(BASE_URL_API + endpoint, requestOptions as RequestInit)
      .then((response) => response.text())
      .then(
        (result) => {
          if (result !== '') {
            var parsedResult = JSON.parse(result) as AmountModel;
            if (parsedResult !== undefined && parsedResult !== null) {
              setAanvraagAmount(parsedResult.amount);
            } else {
              setAanvraagAmount('x');
            }
          }

          return Promise.resolve();
        },
        (error) => {
          if (error) {
            console.error(error);
          }
        }
      );
  };

  const amountOfUsers = () => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(BASE_URL_API + 'api/user-count', requestOptions as RequestInit)
      .then((response) => response.text())
      .then(
        (result) => {
          if (result !== undefined && result !== null) {
            setUserAmount(result);
          } else {
            setUserAmount('x');
          }

          return Promise.resolve();
        },
        (error) => {
          if (error) {
            console.error(error);
          }
        }
      );
  };

  amountOfRequest(useIsAdmin());
  amountOfUsers();

  const success = sessionStorage.getItem(KEY_SUCCESS);
  if (success !== undefined && success !== null) {
    sessionStorage.removeItem(KEY_SUCCESS);
    notify(t('form-sent'), true);
  }

  const handleStartNewRequest = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    sessionStorage.clear();

    const userId = GetUserIdFromStorage();
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(KEY_USER_ID, userId);
    myHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
    };

    fetch(BASE_URL_API + 'api/newrequest', requestOptions as RequestInit)
      .then((response) => response.text())
      .then(
        (result) => {
          return Promise.resolve();
        },
        (error) => {
          if (error) {
            console.error(error);
          }
        }
      );

    navigate({
      pathname: '/wizard',
    });
  };

  return (
    <div className="app_container">
      <Header />
      <Background ellipseType={'big_sliver'} showBackground={false} />
      <p className="title">Welkom op je dashboard</p>

      <div className="user_dashboard_center">
        <div className="container">
          <div className={useIsAdmin() ? 'hidden' : 'amount_container'}>
            <p className="amount_text">aanvragen {getAanvraagAmount}</p>
            <div className="amount_background" />
          </div>
          <div className={useIsAdmin() ? 'admin_amount_container' : 'hidden'}>
            <div className="admin_amount_text_container">
              <p className="admin_amount_text">Totaal aantal eigen aanvragen</p>
              <p className="admin_amount_value">
                {getAanvraagAmount.length > 30 || getAanvraagAmount.length <= 0 ? '0' : getAanvraagAmount}
              </p>
            </div>
            <div className="admin_amount_seperator"></div>
            <div className="admin_amount_text_container">
              <p className="admin_amount_text">Totaal aantal gebruikers</p>
              <p className="admin_amount_value">{getUserAmount.length > 30 || getUserAmount.length <= 0 ? '0' : getUserAmount}</p>
            </div>
          </div>

          <div className="container_vertical">
            <div className="link_container" onClick={handleStartNewRequest}>
              <img src={arrow_right} className="arrow_right" alt="" />
              <p className="link">Nieuwe aanvraag starten</p>
            </div>

            <div className="link_container">
              <img src={arrow_right} className="arrow_right" alt="" />
              <Link className="link" to="/request_history">
                Aanvraaghistorie
              </Link>
            </div>

            <div className={useIsAdmin() ? 'link_container' : 'hidden'}>
              <img src={arrow_right} className="arrow_right" alt="" />
              <Link className="link" to="/user_management">
                Gebruikers beheren
              </Link>
            </div>

            <div className={useIsAdmin() ? 'link_container' : 'hidden'}>
              <img src={arrow_right} className="arrow_right" alt="" />
              <Link className="link" to="/global_values">
                Globale waarden beheren
              </Link>
            </div>
          </div>
        </div>
      </div>
      <DefaultToastContainer />
    </div>
  );
};

export default UserDashboard;
