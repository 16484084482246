import './InputField.css';
import { SetStateAction, forwardRef, useImperativeHandle, useState } from 'react';
import { Validate } from 'constants/validation';
import React from 'react';
import { InputFieldMethods } from './InputFieldMethods';

type Props = {
  inputId?: string;
  title?: string;
  inputClass: string;
  inputType: string;
  containerClass: string;
  maxLength?: number;
  validationChecks?: Array<string>;
  validationData?: string;
  phoneRestrictions?: boolean;
  getValue?: string;
  setValue?: React.Dispatch<SetStateAction<string>>;
  onChange?: (e: string) => {};
  onBlur?: (e: string) => {};
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const InputField = forwardRef<InputFieldMethods, Props>(
  (
    {
      inputId,
      title,
      inputClass,
      inputType,
      containerClass,
      maxLength,
      validationChecks,
      validationData,
      phoneRestrictions = false,
      getValue,
      setValue,
      onChange,
      onBlur,
      onKeyDown,
      disabled = false,
    }: Props,
    ref
  ) => {
    const [getErrorValues, setErrorValues] = useState<Array<string>>(new Array<string>());

    const required = validationChecks !== undefined && validationChecks.length > 0;
    const titleClass = 'input_title ' + (required ? 'input_required' : '');
    let errorMessageInput = required && getErrorValues.length > 0 ? 'input_error_container' : '';
    const errorMessageClass = (getValue === '' || getErrorValues.length > 0) && required ? 'input_error' : '';
    const dateMax = inputType === 'date' ? '2500-12-31' : '';

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
      setErrorValues([]);
      let value = e.currentTarget.value;

      if (maxLength !== undefined && value.length > maxLength) {
        value = value.substring(0, maxLength);
      }

      if (phoneRestrictions) {
        value = value.replace(/[^+0-9]/g, '');
      }

      e.currentTarget.value = value;
      if (setValue) setValue(value);

      if (validationChecks && validationChecks.length > 0) {
        setErrorValues(Validate(value, validationChecks, validationData).filter((error) => error !== ''));
      }
    };

    useImperativeHandle(ref, () => ({
      handleRemoteChange: (value: string) => {
        const syntheticEvent = {
          currentTarget: {
            value: value,
          },
        } as React.FormEvent<HTMLInputElement>;
        handleChange(syntheticEvent);
      },
    }));

    return (
      <div className={containerClass}>
        {!title ? <div></div> : <p className={titleClass}>{title}</p>}
        <div className={errorMessageClass}>{getErrorValues[0]}</div>
        <input
          id={inputId}
          className={inputClass + ' ' + errorMessageInput}
          type={inputType}
          value={getValue}
          max={dateMax}
          maxLength={maxLength}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
            handleChange(e);
          }}
          onBlur={(e) => {
            if (onBlur) onBlur(e.target.value);
          }}
          onKeyDown={(e) => {
            if (onKeyDown) onKeyDown(e);
          }}
          disabled={disabled}
          autoComplete="off"
        />
      </div>
    );
  }
);

export default InputField;
