import "./ObjectCreateButton.css";
import { getIcon } from "helpers/helpers";

type Props = {
  text: string;
  icon: string;
};

const ObjectCollateralCreateButton = ({ text, icon }: Props): JSX.Element => {
  return (
    <div className="button_container">
      <div className="button_icon_container">
        <img src={getIcon(icon)} className="button_icon" alt="logo" />
      </div>
      <p className="button_text">{text}</p>
    </div>
  );
};

export default ObjectCollateralCreateButton;
