import { v4 as uuidv4 } from 'uuid';
import { LOAN_PART_REDEMPTION_OPTIONS, LOAN_PART_UPFRONT_FEE_OPTIONS } from 'constants/dropdownListData';
import { DropdownOptionsModel } from './dropdownOptionsModel';

export class LoanPartModel {
  termLength: string = 'Jaren';
  duration: string = '360';
  loanPartAmount: string = '';
  variabilityType: DropdownOptionsModel | undefined = undefined;
  maturityVariability: string = 'Ja';
  redemptionType: DropdownOptionsModel = LOAN_PART_REDEMPTION_OPTIONS[0];
  redemptionInterval: string = '';
  upfrontFeeType: DropdownOptionsModel = LOAN_PART_UPFRONT_FEE_OPTIONS[0];
  hasUpfrontFee: boolean = false;
  upfrontFee: number = 0;
  key: string = uuidv4();
}
