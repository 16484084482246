import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useEffect, useState } from 'react';
import { ApplicantModel } from 'models/applicantModel';
import './WizardStep.css';
import { GetFullNameForPerson, getNextButtonClass } from 'helpers/helpers';
import ObjectApplicantCreateButton from 'components/ObjectApplicantCreateButton';
import { IWizardStepProps } from 'interfaces/IWizardStepProps';
import { APPLICANT_DATA, COMPANY_DATA, NAME_MAX_LENGTH, NAME_SUFFIX_DOTS } from 'constants/constants';
import Footer from 'components/footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { CompanyModel } from 'models/companyModel';
import { TITLE_APPLICANT, TITLE_APPLICANT_OLD, TITLE_COMPANY } from 'constants/textConstants';
import ObjectDataView from 'components/views/ObjectDataView';
import { useTranslation } from 'react-i18next';
import { PersonModel } from 'models/personModel';
import { notify } from 'components/toast/notify.component';
import { DefaultToastContainer } from 'components/toast/toast-container.component';

const WizardStepOne = ({ clickNextHandler, clickBackHandler, clickOnNavStepHandler }: IWizardStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, { keyPrefix: 'wizard-step-one' });

  const savedValueApplicant = sessionStorage.getItem(APPLICANT_DATA) || '';
  let applicants: ApplicantModel[] = [];
  if (savedValueApplicant !== '') {
    applicants = JSON.parse(savedValueApplicant);
  }

  const [getValueApplicants, setValueApplicants] = useState<ApplicantModel[]>(applicants);

  const savedValueCompany = sessionStorage.getItem(COMPANY_DATA) || '';
  let companies: CompanyModel[] = [];
  if (savedValueCompany !== '') {
    companies = JSON.parse(savedValueCompany);
  }

  const [getValueCompanies, setValueCompanies] = useState<CompanyModel[]>(companies);

  const validateDirectors = (): boolean => {
    if (!getValueCompanies.length) return true;
 
    return getValueCompanies.every(company => 
      company.directors.some(director => 
        getValueApplicants.some(applicant => 
          applicant.key === director.key && applicant.isDirector
        )
      )
    );
  } 

  const clickEditHandler = (index: number, type: string) => {
    if (type === TITLE_APPLICANT || type === TITLE_APPLICANT_OLD) {
      navigate({ pathname: '/create_applicant', search: '?index=' + index });
    }

    if (type === TITLE_COMPANY) {
      navigate({ pathname: '/create_company', search: '?index=' + index });
    }
  };

  const clickDeleteHandler = (index: number, type: string) => {
    if (type === TITLE_APPLICANT || type === TITLE_APPLICANT_OLD) {
      const applicants = getValueApplicants;

      var relationIndex = -1;
      if (applicants[index].relationApplicant !== undefined && applicants[index].relationApplicant !== null) {
        relationIndex = applicants.findIndex((app) => app.relationApplicant?.key === applicants[index].relationApplicant?.key);
      }

      for (var i = applicants.length - 1; i >= 0; i--) {
        if (i === index || i === relationIndex) {
          applicants.splice(i, 1);
        }
      }

      setValueApplicants([...applicants]);
      sessionStorage.setItem(APPLICANT_DATA, JSON.stringify(applicants));
    }

    if (type === TITLE_COMPANY) {
      const companies = getValueCompanies;

      companies.splice(index, 1);
      setValueCompanies([...companies]);
      sessionStorage.setItem(COMPANY_DATA, JSON.stringify(companies));
    }
  };

  const nextIfValidDirectors = (event: any) => {
    if (validateDirectors()) {
      clickNextHandler(event);
    } else {
      notify("Voeg bestuurders toe aan het bedrijf", false)
    }
  }

  return (
    <div>
      <div className="center">
        <p className="step_title">{t('persons')}</p>
        <div className="scroll_container_content">
          <div className="content">
            {getValueApplicants.map((applicant, index) => {
              const person = applicant.person;
              if (person.isRelationPerson) return <div />;

              const relationPerson =
                applicant.relationApplicant && applicant.relationApplicant.isAuthorizedSignee
                  ? applicant.relationApplicant.person
                  : undefined;

              return (
                <ObjectDataView
                  index={index}
                  title={GetFullNameForPerson(person)}
                  subtitle={applicant.type}
                  extraTitle={relationPerson && GetFullNameForPerson(relationPerson)}
                  extraSubTitle={applicant.relationApplicant && applicant.relationApplicant.type}
                  key={applicant.key}
                  clickEditEvent={() => clickEditHandler(index, applicant.type)}
                  clickDeleteEvent={() => clickDeleteHandler(index, applicant.type)}
                />
              );
            })}
            {getValueCompanies.map(({ name, type, key }: any, index) => {
              name = name.length > NAME_MAX_LENGTH ? name.substring(0, NAME_MAX_LENGTH) + NAME_SUFFIX_DOTS : name;
              return (
                <ObjectDataView
                  index={index}
                  title={name}
                  subtitle={getValueCompanies[index].companyType.label}
                  key={key}
                  clickEditEvent={() => clickEditHandler(index, type)}
                  clickDeleteEvent={() => clickDeleteHandler(index, type)}
                />
              );
            })}
          </div>

          <Link to="/create_applicant" className="button_link">
            <ObjectApplicantCreateButton text={t('add-person')} icon="add_person" />
          </Link>
          {getValueApplicants.length === 0
            ? (
              <div className='hover-text'>
                <ObjectApplicantCreateButton text={t('add-company')} icon="plus" isDisabled={true} />
                <span className="tooltip-text" id='bottom'>Voeg eerst een natuurlijk persoon toe</span>
              </div>
            )
            : (
            <Link to="/create_company" className="button_link">
              <ObjectApplicantCreateButton text={t('add-company')} icon="plus" />
            </Link>
            )
          }

          <div className="button_next_container">
            <div className="nav_button button_back hidden" onClick={clickBackHandler}>
              <p className="nav_button_text button_back_text">{t('previous')}</p>
            </div>
            <div
                className={'nav_button button_next ' + getNextButtonClass(getValueApplicants.length + getValueCompanies.length)}
                onClick={nextIfValidDirectors} >
                <p className="nav_button_text button_next_text">{t('next')}</p>
            </div>
            
          </div>
        </div>
      </div>
      <Footer value={0} clickOnNavStepHandler={clickOnNavStepHandler} />
      <DefaultToastContainer />
    </div>
  );
};

export default WizardStepOne;
