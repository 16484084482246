import { v4 as uuidv4 } from "uuid";
import { getSubTypeCollateralOptions, getTypeCollateralOptions } from "../helpers/helpers";
import { DropdownOptionsModel } from "./dropdownOptionsModel";
import { COLLATERAL_RENTAL_CONTRACTS_COUNT } from "../constants/dropdownListData";

export class CollateralModel {
  zipCode: string = "";
  houseNumber: string = "";
  addition: string = "";
  streetName: string = "";
  city: string = "";
  country: string = "NL";
  buildingStock: string = "Nee";
  buildingStockAmount: string = "";
  buildingStockTermLength: string = "Maanden";
  buildingStockDuration: string = "";
  buildingStockRenovationCosts: string = "";
  buildingStockWorthAfterRenovation: string = "";
  marketValue: string = "";
  buyPrice: string = "";
  typeCollateral: DropdownOptionsModel = getTypeCollateralOptions(true)[0];
  subtypeCollateral: DropdownOptionsModel = getSubTypeCollateralOptions()[0];
  statusCollateral: string = "EXISTING_BUILDING";
  roleCollateral: string = "Nee";
  valueBeforeRenovation: string = "";
  renovationCosts: string = "";
  valueAfterRenovation: string = "";
  marketRent: string = "";
  groundLease: string = "Nee";
  groundLeaseFee: string = "";
  groundLeaseEndDate: string = "";
  buyPriceDisabled: boolean = false;
  residentialMarketValue: string = "";
  residentialRentValue: string = "";
  commercialMarketValue: string = "";
  commercialRentValue: string = "";
  rentalContractsOption: DropdownOptionsModel =
    COLLATERAL_RENTAL_CONTRACTS_COUNT[0];
  numberOfRentalContracts: number = 0;
  rentalContractValues: Array<string> = new Array<string>();
  lostThroughVacancy: string = "";
  key: string = uuidv4();
}
