import { useKeycloak } from "@react-keycloak/web";
import { Link } from "react-router-dom";
import logo_dcmf_small from "assets/logo_DCMF_small.svg";
import "./Header.css";
import { PAGE_DASHBOARD } from "constants/pageConstants";
import { useTranslation } from "react-i18next";

const Header = (): JSX.Element => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const handleLogout = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    sessionStorage.clear();

    keycloak.logout({ redirectUri: window.location.origin });
  };

  return (
    <div className="box_container">
      <Link to={PAGE_DASHBOARD}>
        <img src={logo_dcmf_small} className="logo" alt="logo" />
      </Link>
      <div className="logout_container">
        <div className="logout_border" onClick={handleLogout}>
          <p className="logout_text">{t("header.logout")}</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
