import './css/GlobalValues.css';
import './css/ObjectForm.css';
import ellipse_half from 'assets/ellipse_big_half.svg';
import { useNavigate } from 'react-router-dom';
import InputField from 'components/form/InputField';
import { useEffect, useState } from 'react';
import { BUTTON_IMPORT_RATE_CSV, BUTTON_PREVIOUS, BUTTON_SAVE } from 'constants/textConstants';
import { MoonLoader } from 'react-spinners';
import {
  BASE_URL_API,
  DCMF_BASE_COLOR,
  HEADER_AUTHORIZATION,
  HEADER_INTEREST_RATES,
  HEADER_MAX_LTV,
  HEADER_MIN_DSCR,
  HEADER_MIN_ICR,
  HEADER_UPFRONT_FEES,
  REQUEST_GET,
  REQUEST_POST,
  UPFRONT_FEES_COLUMN_COUNT,
  UPFRONT_FEES_ROW_COUNT,
} from 'constants/constants';
import { PAGE_DASHBOARD } from 'constants/pageConstants';
import { TableContainer, Paper, Table, TableRow, TableCell, TableBody, Alert } from '@mui/material';
import {
  INTEREST_RATES_CHANGED,
  INVALID_CSV_COLUMNS,
  INVALID_CSV_NAN,
  INVALID_CSV_ROWS,
  NO_INTEREST_RATES_FOUND,
  NO_UPFRONT_FEES_FOUND,
  NO_VALID_FILE_FOUND,
} from 'constants/messages/errorMessages';
import Papa from 'papaparse';
import Header from 'components/header/Header';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useKeycloak } from '@react-keycloak/web';

type UpfrontFeeRowModel = {
  id: number;
  upfrontfee: number | undefined;
  m12: number | undefined;
  m36: number | undefined;
  m60: number | undefined;
  m84: number | undefined;
  m120: number | undefined;
};

const UserGlobalValuesView = (): JSX.Element => {
  let navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const [getPageLoaded, setPageLoaded] = useState<boolean>(false);

  const [getSelectedRateFile, setSelectedRateFile] = useState<File>();
  const [getSelectedRateFileError, setSelectedRateFileError] = useState<string>('');
  const [getChangedInterestRates, setChangedInterestRates] = useState<boolean>(false);

  const [getMaximumLtv, setMaximumLtv] = useState<string>('');
  const [getMinimumIcr, setMinimumIcr] = useState<string>('');
  const [getMinimumDscr, setMinimumDscr] = useState<string>('');
  const [getParsedInterestRates, setParsedInterestRates] = useState<Array<Array<string>>>(new Array<Array<string>>());
  const [getParsedUpfrontFees, setParsedUpfrontFees] = useState<Array<Array<number | undefined>>>(new Array<Array<number | undefined>>());
  const [getUpfrontFeeModels, setUpfrontFeeModels] = useState<UpfrontFeeRowModel[]>([]);

  const numberRenderer = (params: GridRenderCellParams): any => {
    return <>{params.value}</>;
  };

  const upfrontFeeColumnsDefinition: GridColDef[] = [
    {
      field: 'upfrontfee',
      headerName: 'Upfrontfee %',
      flex: 1,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'm12',
      headerName: '12 maanden',
      type: 'number',
      flex: 1,
      editable: true,
      disableColumnMenu: true,
      renderCell: numberRenderer,
    },
    {
      field: 'm36',
      headerName: '36 maanden',
      type: 'number',
      flex: 1,
      editable: true,
      disableColumnMenu: true,
      renderCell: numberRenderer,
    },
    {
      field: 'm60',
      headerName: '60 maanden',
      type: 'number',
      flex: 1,
      editable: true,
      disableColumnMenu: true,
      renderCell: numberRenderer,
    },
    {
      field: 'm84',
      headerName: '84 maanden',
      type: 'number',
      flex: 1,
      editable: true,
      disableColumnMenu: true,
      renderCell: numberRenderer,
    },
    {
      field: 'm120',
      headerName: '120 maanden',
      type: 'number',
      flex: 1,
      editable: true,
      disableColumnMenu: true,
      renderCell: numberRenderer,
    },
  ];

  const handleSubmitForm = () => {
    savePageData();
  };

  const backToDashboard = () => {
    navigate({ pathname: PAGE_DASHBOARD });
  };

  const savePageData = () => {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append(HEADER_MAX_LTV, getMaximumLtv);
    requestHeaders.append(HEADER_MIN_ICR, getMinimumIcr);
    requestHeaders.append(HEADER_MIN_DSCR, getMinimumDscr);

    let interestRates = '';
    getParsedInterestRates.map((row) => row.map((item) => (interestRates += item + ';')));
    interestRates = interestRates.slice(0, -1);

    let upfrontFees = '';
    getUpfrontFeeModels.map(
      (row) =>
        (upfrontFees += `${row.upfrontfee};${row.m12 ?? -999};${row.m36 ?? -999};${row.m60 ?? -999};${row.m84 ?? -999};${
          row.m120 ?? -999
        };`)
    );
    upfrontFees = upfrontFees.slice(0, -1);

    requestHeaders.append(HEADER_INTEREST_RATES, interestRates);
    requestHeaders.append(HEADER_UPFRONT_FEES, upfrontFees);
    requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)

    const requestOptions = {
      method: REQUEST_POST,
      headers: requestHeaders,
    };

    fetch(BASE_URL_API + 'api/set-global-values', requestOptions as RequestInit).then(() => {
      navigate({ pathname: PAGE_DASHBOARD });
      setChangedInterestRates(false);
      return Promise.resolve();
    });
  };

  const loadPageData = () => {
    const requestHeaders = new Headers();
    requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)
    var requestOptions = {
      method: REQUEST_GET,
      headers: requestHeaders
    };

    fetch(BASE_URL_API + 'api/get-global-values', requestOptions as RequestInit)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }

        return response.text();
      })
      .then((result) => {
        const parsedResult = JSON.parse(result);

        setMaximumLtv(parsedResult['MaximumLtv']);
        setMinimumIcr(parsedResult['MinimumIcr']);
        setMinimumDscr(parsedResult['MinimumDscr']);

        const unparsedInterestRates: string = parsedResult['InterestRates'];
        const splitInterestRates = unparsedInterestRates.split(';');
        const maxRows = 5;
        const rowLength = 7;
        let reformedInterestRates: Array<Array<string>> = new Array<Array<string>>();

        for (var i = 0; i < maxRows; i++) {
          reformedInterestRates.push(splitInterestRates.splice(0, rowLength));
        }

        const unparsedUpfrontFees: string = parsedResult['UpfrontFees'];
        if (unparsedUpfrontFees) {
          const splitUpfrontFees = unparsedUpfrontFees.split(';');
          const maxCols = UPFRONT_FEES_COLUMN_COUNT;
          const maxRows = UPFRONT_FEES_ROW_COUNT;
          let reformedUpfrontFees: Array<Array<number>> = new Array<Array<number>>();

          for (var j = 0; j < maxRows; j++) {
            const splitFeesRow = splitUpfrontFees.splice(0, maxCols);
            const rowArray = new Array<number>();
            for (var k = 0; k < splitFeesRow.length; k++) {
              rowArray.push(Number(splitFeesRow[k]));
            }

            reformedUpfrontFees.push([...rowArray]);
          }

          setParsedUpfrontFees(reformedUpfrontFees);
          const flatData = reformedUpfrontFees.map((data, index) => ({
            id: index,
            upfrontfee: data[0],
            m12: data[1] !== -999 ? data[1] : undefined,
            m36: data[2] !== -999 ? data[2] : undefined,
            m60: data[3] !== -999 ? data[3] : undefined,
            m84: data[4] !== -999 ? data[4] : undefined,
            m120: data[5] !== -999 ? data[5] : undefined,
          }));

          setUpfrontFeeModels([...flatData]);
        }

        setParsedInterestRates(reformedInterestRates);
        setPageLoaded(true);

        return Promise.resolve();
      });
  };

  const handleRateImportError = (errorMessage: string) => {
    setSelectedRateFile(undefined);
    setSelectedRateFileError(errorMessage);
    loadPageData();
  };

  const importRateCSVData = async () => {
    if (!getSelectedRateFile) {
      return;
    }

    if (getSelectedRateFile.type !== 'text/csv') {
      setSelectedRateFileError(NO_VALID_FILE_FOUND);
      return;
    }

    const text = await new Response(getSelectedRateFile).text();
    const result = Papa.parse<Array<string>>(text, {
      header: false,
      delimiter: ';',
    });
    const data = result.data;
    const maxRows = 5;
    const maxColumns = 7;

    // CSVs are sometimes create with trailing empty rows, this removes those
    for (var i = maxRows; i < data.length; i++) {
      if (data[i] && data[i].length !== maxColumns) {
        data.pop();
      }
    }

    if (data.length > maxRows) {
      handleRateImportError(INVALID_CSV_ROWS);
      return;
    } else {
      for (var i = 0; i < data.length; i++) {
        if (data[i].length > maxColumns) {
          handleRateImportError(INVALID_CSV_COLUMNS);
          return;
        }

        for (var j = 0; j < data[i].length; j++) {
          var parsedNumber = Number(data[i][j].replace(',', '.'));
          if (Number.isNaN(parsedNumber)) {
            handleRateImportError(INVALID_CSV_NAN);
            return;
          }
        }
      }
    }

    setSelectedRateFileError('');
    setSelectedRateFile(undefined);

    setParsedInterestRates(data);
    setChangedInterestRates(true);
  };

  useEffect(() => {
    if (getSelectedRateFile) {
      importRateCSVData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelectedRateFile]);

  useEffect(() => {
    loadPageData();
  }, []);

  return (
    <div className="app_container">
      <Header />

      {getPageLoaded ? (
        <div className="padding-left">
          <p className="title_create_object">Globale waarden</p>
          <div className="form">
            <div className="form_container form_left">
              <div className="form_row">
                <InputField
                  inputId="max_ltv"
                  title="Maximum LTV percentage"
                  inputClass="input_field"
                  inputType="number"
                  maxLength={8}
                  containerClass="input_container"
                  getValue={getMaximumLtv}
                  setValue={setMaximumLtv}
                />
              </div>
              <div className="form_row">
                <InputField
                  inputId="min_icr"
                  title="Minimum ICR"
                  inputClass="input_field"
                  inputType="number"
                  maxLength={8}
                  containerClass="input_container"
                  getValue={getMinimumIcr}
                  setValue={setMinimumIcr}
                />
              </div>
              <div className="form_row">
                <InputField
                  inputId="min_dscr"
                  title="Minimum DSCR"
                  inputClass="input_field"
                  inputType="number"
                  maxLength={8}
                  containerClass="input_container"
                  getValue={getMinimumDscr}
                  setValue={setMinimumDscr}
                />
              </div>

              <p className="input_title">Rentetarieven</p>
              {getParsedInterestRates && getParsedInterestRates.length !== 0 ? (
                <div>
                  <TableContainer
                    component={Paper}
                    className={'interest_rate_table ' + (getChangedInterestRates ? 'interest_rate_table_changed' : '')}
                  >
                    <Table sx={{ minWidth: '100%' }} aria-label="simple table" size="small">
                      <TableBody>
                        {getParsedInterestRates.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            }}
                          >
                            {row.map((entry, i) => (
                              <TableCell align="left" id={i.toString()}>
                                {entry}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {getChangedInterestRates ? (
                    <Alert className="table_no_data" severity="warning">
                      {INTEREST_RATES_CHANGED}
                    </Alert>
                  ) : (
                    <div></div>
                  )}
                  {getSelectedRateFileError !== '' ? (
                    <Alert className="table_no_data" severity="error">
                      {getSelectedRateFileError}
                    </Alert>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                <Alert className="table_no_data" severity="error">
                  {NO_INTEREST_RATES_FOUND}
                </Alert>
              )}

              <div className="form_row_end">
                <input
                  className="hidden"
                  type={'file'}
                  id={'csvFileInput'}
                  accept={'.csv'}
                  onChange={(e) => {
                    setSelectedRateFileError('');
                    setChangedInterestRates(false);
                    setSelectedRateFile(e.target.files ? e.target.files[0] : undefined);
                  }}
                  onClick={(e) => (e.currentTarget.value = '')}
                />

                <label className="nav_button button_back content_button" htmlFor="csvFileInput">
                  <p className="nav_button_text button_back_text">{BUTTON_IMPORT_RATE_CSV}</p>
                </label>
              </div>

              <p className="input_title">Upfrontfees</p>
              <p className="input_title" style={{ fontSize: 10 }}>
                (dubbelklik of start met typen met een geselecteerd vakje om een waarde aan te passen)
              </p>
              {getParsedUpfrontFees && getParsedUpfrontFees.length !== 0 ? (
                <div>
                  <DataGrid
                    className={'upfront_fee_table'}
                    rows={getUpfrontFeeModels}
                    columns={upfrontFeeColumnsDefinition}
                    hideFooter
                    hideFooterPagination
                    onCellEditCommit={(params) => {
                      const index = Number(params.id);
                      const upfrontValues = [...getUpfrontFeeModels];
                      const upfrontValue = upfrontValues[index];
                      upfrontValues[index] = {
                        id: upfrontValue.id,
                        upfrontfee: upfrontValue.upfrontfee,
                        m12: params.field !== 'm12' ? upfrontValue.m12 : params.value,
                        m36: params.field !== 'm36' ? upfrontValue.m36 : params.value,
                        m60: params.field !== 'm60' ? upfrontValue.m60 : params.value,
                        m84: params.field !== 'm84' ? upfrontValue.m84 : params.value,
                        m120: params.field !== 'm120' ? upfrontValue.m120 : params.value,
                      };

                      setUpfrontFeeModels([...upfrontValues]);
                    }}
                  />
                </div>
              ) : (
                <Alert className="table_no_data" severity="error">
                  {NO_UPFRONT_FEES_FOUND}
                </Alert>
              )}
              <div className="form_row_end padding-top">
                <div className="nav_button button_back" onClick={backToDashboard}>
                  <p className="nav_button_text button_back_text">{BUTTON_PREVIOUS}</p>
                </div>
                <div className={'global_values_save_button nav_button button_next'} onClick={handleSubmitForm}>
                  <p className="nav_button_text button_next_text">{BUTTON_SAVE}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loader">
          <MoonLoader color={DCMF_BASE_COLOR} />
        </div>
      )}

      <img className="background_logo" src={ellipse_half} alt="" />
    </div>
  );
};

export default UserGlobalValuesView;
