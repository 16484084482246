import { useTranslation } from 'react-i18next';
import './ObjectDataViewSmall.css';
import { getUpfrontInterestRate } from 'helpers/rateCalculations';
import { LOAN_PART_DATA, COLLATERAL_DATA, LTV_INTEREST_ONLY_MAX_PERCENTAGE } from 'constants/constants';
import { CollateralModel } from 'models/collateralModel';
import { LoanPartModel } from 'models/loanPartModel';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useKeycloak } from '@react-keycloak/web';

type Props = {
  ltv: Number;
  dscr: Number;
  icr: Number;
  maxLtv: Number;
  minDscr: Number;
  minIcr: Number;
  interest?: number;
  upfrontFee?: number;
  ltvInterestOnly?: number;
  totalValue?: number;
};

const ObjectDataViewRates = ({ ltv, dscr, icr, maxLtv, minDscr, minIcr, upfrontFee, ltvInterestOnly, totalValue }: Props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'object-data-view-rates',
  });
  const { keycloak } = useKeycloak();

  const validLtv = ltv <= maxLtv;
  const validIcr = icr >= minIcr;
  const validDscr = dscr >= minDscr;
  console.log(ltvInterestOnly);
  const validLtvInterestOnly = ltvInterestOnly ? ltvInterestOnly <= LTV_INTEREST_ONLY_MAX_PERCENTAGE : true;

  const validRates = validLtv && validDscr && validIcr && validLtvInterestOnly;

  const savedValue = sessionStorage.getItem(LOAN_PART_DATA) || '';
  let loanParts: LoanPartModel[] = [];

  if (savedValue !== '') {
    loanParts = JSON.parse(savedValue);
  }

  let savedValueCollateral = sessionStorage.getItem(COLLATERAL_DATA) || '';
  let collaterals: CollateralModel[] = [];
  if (savedValueCollateral !== '') {
    collaterals = JSON.parse(savedValueCollateral);
  }

  const [interestRate, setInterestRate] = useState<number | null>(null);

  useEffect(() => {
    (async () => {
      const rate = await getUpfrontInterestRate(collaterals, loanParts, keycloak.token);
      setInterestRate(rate);
    })();
  }, [upfrontFee, collaterals, loanParts]);

  const validUpfrontFee = upfrontFee !== undefined && !Number.isNaN(upfrontFee);

  return (
    <div className="object_container_small">
      <div className="object_data_container_small_flex">
        {loanParts.length !== 0 ? (
          <div className="object_column_container_small">
            {<div className="object_type_small_flex">{`Rente: ${(interestRate! * 100).toFixed(2)}%`}</div>}
            {validUpfrontFee ? 
              <div className="object_type_small_flex">
                <NumberFormat
                  value={upfrontFee}
                  displayType={'text'}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                  prefix={'Upfront fee €'}
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </div> 
            : <></>}
            {<br />}
            <div className="object_row_container_small">
              <p className="object_type_small_flex flex-1">{t('ltv')}</p>
              <p className={'object_type_small_flex flex-8 ' + (validLtv ? '' : 'object_type_error')}>{ltv.toFixed(2)}%</p>
            </div>
            <div className="object_row_container_small">
              <p className="object_type_small_flex flex-1">{t('icr')}</p>
              <p className={'object_type_small_flex flex-8 ' + (validIcr ? '' : 'object_type_error')}>{icr.toFixed(4)}</p>
            </div>
            <div className="object_row_container_small">
              <p className="object_type_small_flex flex-1">{t('dscr')}</p>
              <p className={'object_type_small_flex flex-8 ' + (validDscr ? '' : 'object_type_error')}>{dscr.toFixed(4)}</p>
            </div>
            {validRates ? <div></div> : <p className="object_type_small">{t('invalid-request')}</p>}
            {!validLtvInterestOnly && (
              <p className="object_type_small object_type_error">
                {t('max-ltv-interest-only', {
                  maxLtvInterestOnly: LTV_INTEREST_ONLY_MAX_PERCENTAGE,
                })}
                <NumberFormat
                  value={totalValue ? (totalValue / 100) * LTV_INTEREST_ONLY_MAX_PERCENTAGE : 0}
                  displayType={'text'}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                  prefix={'€'}
                  decimalSeparator=","
                />
              </p>
            )}
            {validRates ? (
              <div></div>
            ) : (
              <p className="object_type_small">
                {t('max-ltv')}
                <b>{maxLtv}%</b>
              </p>
            )}
            {validRates ? (
              <div></div>
            ) : (
              <p className="object_type_small">
                {t('min-icr')}
                <b>{minIcr}</b>
              </p>
            )}
            {validRates ? (
              <div></div>
            ) : (
              <p className="object_type_small">
                {t('min-dscr')}
                <b>{minDscr}</b>
              </p>
            )}
          </div>
        ) : (
          <p style={{ padding: 16, fontSize: 14 }}>Voeg eerst een leningsdeel toe</p>
        )}
      </div>
    </div>
  );
};

export default ObjectDataViewRates;
