import './InputField.css';
import { SetStateAction, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Validate } from '../../constants/validation';

type Props = {
  name?: string;
  title: string;
  required?: boolean;
  inputId: string;
  inputClass?: string;
  containerClass?: string;
  defaultValue?: number;
  getValue: string;
  setValue?: React.Dispatch<SetStateAction<string>>;
  validationChecks?: Array<string>;
  validationData?: string;
  index?: number;
  maxLength?: number;
  disable?: boolean;
  handleChange?: (index: number, value: string) => void;
};

const ValutaField = ({
  name,
  title,
  inputId,
  inputClass = 'input_field',
  containerClass = 'input_container',
  getValue,
  setValue,
  validationChecks,
  validationData,
  index,
  maxLength,
  disable = false,
  handleChange,
}: Props) => {
  const [getErrorValues, setErrorValues] = useState<Array<string>>(new Array<string>());

  const disabledClassName = disable ? ' valuta_disabled' : '';
  const required = validationChecks !== undefined && validationChecks.length > 0;
  const titleClass = 'input_title ' + (required ? 'input_required' : '');
  const errorMessageInput = required && getErrorValues.length > 0 ? 'input_error_container' : '';
  const errorMessageClass = (getValue === '' || getErrorValues.length > 0) && required ? 'input_error' : '';

  const handleChanged = (e: React.FormEvent<HTMLInputElement>) => {
    var value = e.currentTarget.value;

    if (maxLength !== undefined && value.length > maxLength) {
      value = value.substring(0, maxLength);
    }

    validateInput(value);
  };

  const validateInput = (value: string) => {
    if (validationChecks && validationChecks.length > 0) {
      setErrorValues(Validate(value, validationChecks, validationData).filter((error) => error !== ''));
    }
  };

  return (
    <div className={containerClass}>
      <p className={titleClass}>{title}</p>
      <div className={errorMessageClass}>{getErrorValues[0]}</div>
      <NumberFormat
        name={name ? name : ''}
        className={inputClass + disabledClassName + ' ' + errorMessageInput}
        id={inputId}
        thousandsGroupStyle="thousand"
        value={getValue}
        prefix="€"
        decimalSeparator=","
        displayType="input"
        type="text"
        thousandSeparator="."
        allowNegative={true}
        onChange={handleChanged}
        onValueChange={(values) => {
          const { formattedValue, value } = values;
          if (setValue) {
            setValue(value);
          }

          if (handleChange && index) {
            handleChange(index, value);
          }
        }}
        disabled={disable}
        autoComplete="off"
      />
    </div>
  );
};

export default ValutaField;
