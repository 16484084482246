import { v4 as uuidv4 } from "uuid";
import { COMPANY_DROPDOWN_OPTIONS } from "constants/dropdownListData";
import { ApplicantModel } from "./applicantModel";
import { DropdownOptionsModel } from "./dropdownOptionsModel";

export class CompanyModel {
  name: string = "";
  companyType: DropdownOptionsModel = COMPANY_DROPDOWN_OPTIONS[0];
  rsin: string = "";
  kvk: string = "";
  jointlyLiable: string = "Ja";
  phoneNumber: string = "";
  email: string = "";
  addressType: string = "Niet Residentie";
  zipCode: string = "";
  houseNumber: string = "";
  addition: string = "";
  streetName: string = "";
  city: string = "";
  country: string = "";
  countryTax: string = "";
  directors: ApplicantModel[] = new Array<ApplicantModel>();
  type: string = "Bedrijf";
  key: string = uuidv4();
}
