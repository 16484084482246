import { v4 as uuidv4 } from 'uuid';

export class FinancingModel {
  financingType: string = 'Aankoop';
  termLength: string = 'Jaren';
  duration: string = '360';
  buyAmount: string = '';
  desiredMortgageRegistration: string = '';
  closingCosts: string = '';

  buildingStock: string = 'Nee';
  buildingStockAmount: string = '';
  buildingStockTermLength: string = 'Maanden';
  buildingStockDuration: string = '';
  key: string = uuidv4();
}
