import Keycloak from "keycloak-js";
import { BASE_KEYCLOAK_URL } from "constants/constants";

const keycloak = new Keycloak({
  url: BASE_KEYCLOAK_URL,
  realm: "DCMF",
  clientId: "portal",
});

export default keycloak;
