import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './css/ObjectForm.css';
import { useEffect, useState } from 'react';
import InputField from 'components/form/InputField';
import Dropdown from 'components/form/Dropdown';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoanPartModel } from 'models/loanPartModel';
import {
  BASE_URL_API,
  HEADER_AUTHORIZATION,
  LOAN_PART_DATA,
  REQUEST_GET,
  ROLE_ADMIN,
  ROLE_DCMF,
  ROLE_UPFRONT_FEE,
  UPFRONT_FEES_COLUMN_COUNT,
  UPFRONT_FEES_ROW_COUNT,
} from 'constants/constants';
import ValutaField from 'components/form/ValutaField';
import { DefaultToastContainer } from 'components/toast/toast-container.component';
import { LOAN_PART_PERIOD_OPTIONS, LOAN_PART_REDEMPTION_OPTIONS, LOAN_PART_UPFRONT_FEE_OPTIONS } from 'constants/dropdownListData';
import { DropdownOptionsModel } from 'models/dropdownOptionsModel';
import { VALIDATION_LOAN_PART_DURATION, VALIDATION_LOAN_PART_VALUE } from 'constants/validationCheckTypes';
import { notify } from 'components/toast/notify.component';
import { FORM_ERROR_MESSAGE } from 'constants/messages/errorMessages';
import { useKeycloak } from '@react-keycloak/web';
import Header from 'components/header/Header';


const ObjectLoanPartForm = () => {
  const { keycloak } = useKeycloak();
  const roles = keycloak.tokenParsed?.realm_access?.roles;
  const isAdmin = roles?.find((role) => role === ROLE_ADMIN) !== undefined;
  const isDCMF = roles?.find((role) => role === ROLE_DCMF) !== undefined;
  const isUpfrontFeeUser = roles?.find((role) => role === ROLE_UPFRONT_FEE) !== undefined;

  const validateForm = (): boolean => {
    let formValid = true;

    var allWithErrorClass = Array.from(document.getElementsByClassName('input_error'));

    if (allWithErrorClass.length > 0) {
      notify(FORM_ERROR_MESSAGE, false);
      formValid = false;
    } else if (getVariabilityTypeValue.value === '-1') {
      notify('Select een valide rentevast periode!', false);
      formValid = false;
    }

    return formValid;
  };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let loanParts: LoanPartModel[] = [];
  let currentLoanPart = new LoanPartModel();
  let shouldDisableUpfrontFee = false;
  let indexForEdit = -1;

  const savedValues = sessionStorage.getItem(LOAN_PART_DATA) || '';
  if (savedValues !== '') {
    loanParts = JSON.parse(savedValues);
    if (searchParams.get('index') != null) {
      indexForEdit = Number(searchParams.get('index'));
      currentLoanPart = loanParts[indexForEdit];
    }
  }
  shouldDisableUpfrontFee = (indexForEdit === -1 && loanParts.length > 0) || (indexForEdit !== -1 && loanParts.length > 1);

  const emptyDropdownOptionsModel: DropdownOptionsModel = { value: '-1', label: 'Geen', isDisabled: false };

  const [getDurationValue, setDurationValue] = useState<string>(currentLoanPart.duration);
  const [getLoanPartAmountValue, setLoanPartAmountValue] = useState<string>(currentLoanPart.loanPartAmount);
  const [getVariabilityTypeValue, setVariabilityTypeValue] = useState<DropdownOptionsModel>(
    currentLoanPart.variabilityType ?? emptyDropdownOptionsModel
  );
  const [getRedemptionTypeValue, setRedemptionTypeValue] = useState<DropdownOptionsModel>(currentLoanPart.redemptionType);
  const [getUpfrontFeeTypeValue, setUpfrontFeeTypeValue] = useState<DropdownOptionsModel>(currentLoanPart.upfrontFeeType);

  const [getUpfrontFeeTypeOptions, setUpfrontFeeTypeOptions] = useState<DropdownOptionsModel[]>(LOAN_PART_UPFRONT_FEE_OPTIONS);
  const [getLoanPartPeriodWithFeeOptions, setLoanPartPeriodWithFeeOptions] = useState<Map<string, DropdownOptionsModel[]>>(
    new Map<string, DropdownOptionsModel[]>()
  );

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm()) {
      const loanPartData = new LoanPartModel();
      loanPartData.duration = getDurationValue;
      loanPartData.loanPartAmount = getLoanPartAmountValue;
      loanPartData.variabilityType = getVariabilityTypeValue;
      loanPartData.maturityVariability = 'Ja';
      loanPartData.redemptionType = getRedemptionTypeValue;
      loanPartData.redemptionInterval = 'Maandelijks';
      loanPartData.upfrontFeeType = getUpfrontFeeTypeValue;
      loanPartData.hasUpfrontFee = getUpfrontFeeTypeValue.value !== '0';
      const upfrontFeeMatch = getUpfrontFeeTypeValue?.label.match(/[-+]?\d*\.?\d+/);
      loanPartData.upfrontFee = upfrontFeeMatch ? parseFloat(upfrontFeeMatch?.[0]) : 0;

      const savedValue = sessionStorage.getItem(LOAN_PART_DATA) || '';
      let loanParts: LoanPartModel[] = [];

      if (savedValue !== '') {
        loanParts = JSON.parse(savedValue);
      }

      if (indexForEdit != null && indexForEdit !== -1) {
        loanParts[indexForEdit] = loanPartData;
      } else {
        loanParts.push(loanPartData);
      }

      sessionStorage.setItem(LOAN_PART_DATA, JSON.stringify(loanParts));

      navigateToWizard(2);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        var requestHeaders = new Headers();
        requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)

        const requestOptions = { method: REQUEST_GET, headers: requestHeaders };
        const response = await fetch(BASE_URL_API + 'api/get-global-values', requestOptions);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const result = await response.json();

        const unparsedUpfrontFees: string = result['UpfrontFees'];
        if (!unparsedUpfrontFees) {
          return;
        }

        const splitUpfrontFees = unparsedUpfrontFees.split(';');
        const maxCols = UPFRONT_FEES_COLUMN_COUNT;
        const maxRows = UPFRONT_FEES_ROW_COUNT;

        const mappedLoanPartPeriodWithFeeOptions = new Map<string, DropdownOptionsModel[]>();
        for (let i = 0; i < maxRows; i++) {
          const optionsForRow: DropdownOptionsModel[] = [];

          const splitFeesRow = splitUpfrontFees.splice(0, maxCols);
          for (let j = 1; j < splitFeesRow.length; j++) {
            if (splitFeesRow[j] !== '-999') {
              optionsForRow.push({
                value: (j - 1).toString(),
                label: `${LOAN_PART_PERIOD_OPTIONS[j].label} - ${Number(splitFeesRow[j]) * 100}% rentekorting`,
                isDisabled: false,
              });
            }
          }
          if (optionsForRow.length > 0) {
            mappedLoanPartPeriodWithFeeOptions.set((i + 1).toString(), optionsForRow);
          }
        }

        const filteredUpfrontFeeTypeOptions = [
          ...LOAN_PART_UPFRONT_FEE_OPTIONS.filter((option) => mappedLoanPartPeriodWithFeeOptions.has(option.value)),
        ];
        filteredUpfrontFeeTypeOptions.unshift(LOAN_PART_UPFRONT_FEE_OPTIONS[0]);

        setUpfrontFeeTypeOptions(filteredUpfrontFeeTypeOptions);
        setLoanPartPeriodWithFeeOptions(mappedLoanPartPeriodWithFeeOptions);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const getLoanPartPeriodOptionsByLoanPartType = (): DropdownOptionsModel[] => {
    return getLoanPartPeriodWithFeeOptions.get(getUpfrontFeeTypeValue.value) ?? LOAN_PART_PERIOD_OPTIONS;
  };

  const handleClickCancel = () => {
    navigateToWizard(2);
  };

  const navigateToWizard = (index: number) => {
    navigate({ pathname: '/wizard', search: '?wizardStep=' + index });
  };

  useEffect(() => {
    if (getRedemptionTypeValue.value !== LOAN_PART_REDEMPTION_OPTIONS[0].value) {
      setUpfrontFeeTypeValue(LOAN_PART_UPFRONT_FEE_OPTIONS[0]);
    }
  }, [getRedemptionTypeValue]);

  return (
    <div className="app_container">
      <Header />
      <div className="form_center">
        <p className="title_create_object">Leningdeel</p>
        <form className="form" onSubmit={handleSubmitForm}>
          <div className="form_container form_left">
            <div className="form_row">
              <ValutaField
                title="Leningdeel hoogte"
                inputId="loan_part_value"
                containerClass="input_container"
                validationChecks={isAdmin || isDCMF ? [] : new Array<string>(VALIDATION_LOAN_PART_VALUE)}
                getValue={getLoanPartAmountValue}
                setValue={setLoanPartAmountValue}
              />
            </div>
            <div className="form_row">
              <InputField
                inputId="loan_part_duration"
                title="Looptijd (in maanden)"
                inputClass="input_field"
                inputType="number"
                containerClass="input_container"
                validationChecks={new Array<string>(VALIDATION_LOAN_PART_DURATION)}
                getValue={getDurationValue}
                setValue={setDurationValue}
              />
            </div>
            <div className="form_row">
              {(isUpfrontFeeUser || isAdmin || isDCMF) && (
                <Dropdown
                  title="Upfront fee"
                  dropdownId="loan_part_upfront_fee"
                  inputClass="dropdown_field"
                  options={getUpfrontFeeTypeOptions}
                  initialValue={getUpfrontFeeTypeValue}
                  containerClass="input_container"
                  getValue={getUpfrontFeeTypeValue}
                  setValue={setUpfrontFeeTypeValue}
                  isDisabled={shouldDisableUpfrontFee || getRedemptionTypeValue.value !== LOAN_PART_UPFRONT_FEE_OPTIONS[0].value}
                />
              )}
            </div>
          </div>
          <div className="form_container form_right">
            {(isUpfrontFeeUser || isAdmin || isDCMF) && <div className="form_row" style={{ minHeight: 76 }}></div>}
            <div className="form_row">
              <Dropdown
                title="Aflossingstype"
                dropdownId="loan_part_type"
                inputClass="dropdown_field"
                options={LOAN_PART_REDEMPTION_OPTIONS}
                initialValue={getRedemptionTypeValue}
                containerClass="input_container"
                getValue={getRedemptionTypeValue}
                setValue={setRedemptionTypeValue}
              />
            </div>
            <div className="form_row">
              <Dropdown
                title="Rentevast periode (in maanden)"
                dropdownId="loan_part_rent_period"
                inputClass="dropdown_field"
                options={getLoanPartPeriodOptionsByLoanPartType()}
                initialValue={getVariabilityTypeValue}
                containerClass="input_container"
                getValue={getVariabilityTypeValue}
                setValue={setVariabilityTypeValue}
                isLoading={getLoanPartPeriodWithFeeOptions.size === 0}
                isRequired={true}
              />
            </div>
          </div>
          <div className="form_button_container form_col_start_2">
            <div className="button_cancel" onClick={() => handleClickCancel()}>
              <p className="button_cancel_text">Annuleren</p>
            </div>

            <input type="submit" className="button_save" value="Opslaan" />
          </div>
        </form>
      </div>
      <DefaultToastContainer />
    </div>
  );
};

export default ObjectLoanPartForm;
