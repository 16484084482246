export const PAGE_DASHBOARD = "/user_dashboard";
export const PAGE_USER_MANAGEMENT = "/user_management";
export const PAGE_GLOBAL_VALUES = "/global_values";
export const PAGE_REQUEST_HISTORY = "/request_history";
export const PAGE_WIZARD = "/wizard";
export const PAGE_OVERVIEW = "/overview";

export const PAGE_CREATE_APPLICANT = "/create_applicant";
export const PAGE_CREATE_COMPANY = "/create_company";
export const PAGE_CREATE_COLLATERAL = "/create_collateral";
export const PAGE_CREATE_LOAN_PART = "/create_loan_part";