import { COUNTRY_OPTIONS } from "constants/dropdownListData";
import { DropdownOptionsModel } from "models/dropdownOptionsModel";

export const GetCountryDropdownModel = (nationality: string) => {
  var country = COUNTRY_OPTIONS.find(
    (country) => country.value === nationality
  );
  var countryValue = country ? country : DUTCH_COUNTRY_DROPDOWN_OPTION;
  return countryValue;
};

export const DUTCH_COUNTRY_DROPDOWN_OPTION =
  (COUNTRY_OPTIONS.find(
    (option) => option.value === "NL"
  ) as DropdownOptionsModel) ?? new DropdownOptionsModel();
