import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import UserDashboard from 'pages/UserDashboard';
import UserManagement from 'pages/UserManagement';
import ObjectWizard from 'pages/ObjectWizard';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './Keycloak';
import PrivateRoute from 'helpers/PrivateRoute';
import ObjectOverview from 'pages/ObjectOverview';
import ObjectApplicantForm from 'pages/ObjectApplicantForm';
import ObjectCollateralForm from 'pages/ObjectCollateralForm';
import ObjectCompanyForm from 'pages/ObjectCompanyForm';
import ObjectLoanPartForm from 'pages/ObjectLoanPartForm';
import UserGlobalValues from 'pages/UserGlobalValues';
import UserRequestHistory from 'pages/UserRequestHistory';
import {
  PAGE_CREATE_APPLICANT,
  PAGE_CREATE_COLLATERAL,
  PAGE_CREATE_COMPANY,
  PAGE_CREATE_LOAN_PART,
  PAGE_DASHBOARD,
  PAGE_GLOBAL_VALUES,
  PAGE_OVERVIEW,
  PAGE_REQUEST_HISTORY,
  PAGE_USER_MANAGEMENT,
  PAGE_WIZARD,
} from 'constants/pageConstants';
import i18n from 'i18n';

const routeMap = [
  { path: PAGE_DASHBOARD, component: <UserDashboard /> },
  { path: PAGE_USER_MANAGEMENT, component: <UserManagement /> },
  { path: PAGE_GLOBAL_VALUES, component: <UserGlobalValues /> },
  { path: PAGE_REQUEST_HISTORY, component: <UserRequestHistory /> },
  { path: PAGE_WIZARD, component: <ObjectWizard /> },
  { path: PAGE_OVERVIEW, component: <ObjectOverview /> },
  { path: PAGE_CREATE_APPLICANT, component: <ObjectApplicantForm /> },
  { path: PAGE_CREATE_COMPANY, component: <ObjectCompanyForm /> },
  { path: PAGE_CREATE_COLLATERAL, component: <ObjectCollateralForm /> },
  { path: PAGE_CREATE_LOAN_PART, component: <ObjectLoanPartForm /> },
];

i18n.changeLanguage('nl');

ReactDOM.render(
  <ReactKeycloakProvider authClient={keycloak}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        {routeMap.map((route) => (
          <Route path={route.path} key={route.path} element={<PrivateRoute>{route.component}</PrivateRoute>} />
        ))}
      </Routes>
    </BrowserRouter>
  </ReactKeycloakProvider>,
  document.getElementById('root')
);
