import './ObjectDataView.css';
import object_background from 'assets/object_background.svg';
import edit_icon from 'assets/icons/edit.svg';
import delete_icon from 'assets/icons/delete.svg';
import NumberFormat from 'react-number-format';

type ObjectDataViewProps = {
  index: number;
  title: string;
  subtitle: string;
  extraTitle?: string;
  extraSubTitle?: string;
  clickEditEvent: (index: number) => void;
  clickDeleteEvent: (index: number) => void;
};

const ObjectDataView = ({ index, title, subtitle, extraTitle, extraSubTitle, clickEditEvent, clickDeleteEvent }: ObjectDataViewProps) => {
  const getTitleValue = (title: string) => {
    const valueToNumber = Number(title);

    if (isNaN(valueToNumber)) {
      return <p className="object_title">{title}</p>;
    } else {
      return (
        <p className="object_title">
          <NumberFormat
            value={title}
            displayType={'text'}
            thousandsGroupStyle="thousand"
            thousandSeparator="."
            prefix={'€'}
            decimalSeparator=","
          />
        </p>
      );
    }
  };

  return (
    <div className="object_container">
      <div className="object_data_container">
        <div>
          {getTitleValue(title)}
          <p className={extraTitle ? 'object_extra_subtitle' : 'object_type'}>{subtitle}</p>
          {extraTitle && extraSubTitle && (
            <div className="object_extra_title">
              {getTitleValue(extraTitle)}
              <p className="object_extra_subtitle">{extraSubTitle}</p>
            </div>
          )}
        </div>

        <img src={object_background} className="object_background" alt="logo" />
      </div>

      <div className="object_actions_container">
        <div className="object_actions_button_container">
          <img src={edit_icon} className="object_edit" alt="logo" onClick={() => clickEditEvent(index)} />
          <img src={delete_icon} className="object_delete" alt="logo" onClick={() => clickDeleteEvent(index)} />
        </div>
      </div>
    </div>
  );
};

export default ObjectDataView;
