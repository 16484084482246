import { UserModel } from "models/userModel";
import { convertUserDataToCSV } from "./convertUserDataToCSV";

export const downloadCSV = (data: Array<UserModel>, filename: string): void => {
	const csvData = convertUserDataToCSV(data);
	const blob = new Blob([csvData], { type: "text/csv" });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.style.display = "none";
	a.href = url;
	a.download = filename;
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);
};
