import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Radio, RadioGroup } from '@mui/material';
import './modal.css';
import { useTranslation } from 'react-i18next';
import { USER_MANAGEMENT_MAIN_ROLE_OPTIONS, USER_MANAGEMENT_ADDITIONAL_ROLE_OPTIONS } from 'constants/dropdownListData';
import { UserModel } from 'models/userModel';
import { ADDITIONAL_ROLES, BASE_URL_API, HEADER_AUTHORIZATION, MAIN_ROLES } from 'constants/constants';
import { useState } from 'react';
import { mapToKeycloakGroup } from 'helpers/mapToKeycloakGroup';
import { useKeycloak } from '@react-keycloak/web';

export const EditUserModal = ({
  initialUser,
  handleClose,
  handleSubmit,
  disabled,
}: {
  initialUser: UserModel;
  handleClose: any;
  handleSubmit: (editedUser: UserModel) => void;
  disabled: boolean;
}) => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation(undefined, { keyPrefix: 'user-management' });
  const [selectedMainRoleOption, setSelectedMainRoleOption] = useState<string>(
    initialUser.roles.find((role) => MAIN_ROLES.includes(role)) || ''
  );
  const [selectedAdditionalRoleOptions, setSelectedAdditionalRoleOptions] = useState<string[]>(
    initialUser.roles.filter((role) => ADDITIONAL_ROLES.includes(role))
  );

  const [resetPasswordButtonDisabled, setResetPasswordButtonDisabled] = useState<boolean>(false);
  const [reset2FAButtonDisabled, setReset2FAButtonDisabled] = useState<boolean>(false);
  const [resetPasswordButtonText, setResetPasswordButtonText] = useState<string>(t('edit-reset-password'));
  const [reset2FAButtonText, setReset2FAButtonText] = useState<string>(t('edit-reset-2fa'));

  const getLastActive = (): string => {
    const lastActive = initialUser.lastActive;
    if (lastActive === undefined || lastActive < 0) {
      return t('edit-not-available');
    }

    return lastActive === 1 ? `${lastActive} ${t('edit-day-ago')}` : `${lastActive} ${t('edit-days-ago')}`;
  };

  const handleSubmitClicked = () => {
    const editedUser = { ...initialUser };
    editedUser.groups = [mapToKeycloakGroup(selectedMainRoleOption)];
    editedUser.roles = [...selectedAdditionalRoleOptions];

    handleSubmit(editedUser);
  };

  const handleResetPassword = async () => {
    setResetPasswordButtonDisabled(true);
    setResetPasswordButtonText(t('edit-reset-password-requested'));

    var requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)

    var requestOptions = {
      method: 'POST',
      headers: requestHeaders,
    };

    await fetch(BASE_URL_API + `api/reset-password?userId=${initialUser.id}`, requestOptions as RequestInit)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to reset password');
        }
        setResetPasswordButtonDisabled(false);
        setResetPasswordButtonText(t('edit-reset-password'));
        return Promise.resolve();
      })
      .catch((error) => console.error('error', error));
  };

  const handleReset2FA = async () => {
    setReset2FAButtonDisabled(true);
    setReset2FAButtonText(t('edit-reset-2fa-requested'));

    var requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)

    var requestOptions = {
      method: 'POST',
      headers: requestHeaders,
    };

    await fetch(BASE_URL_API + `api/reset-2fa?userId=${initialUser.id}`, requestOptions as RequestInit)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to reset 2FA');
        }
        setReset2FAButtonDisabled(false);
        setReset2FAButtonText(t('edit-reset-2fa'));
        return Promise.resolve();
      })
      .catch((error) => console.error('error', error));
  };

  const handleAdditionalRolesChange = (roleOption: string) => {
    if (selectedAdditionalRoleOptions.includes(roleOption)) {
      setSelectedAdditionalRoleOptions(selectedAdditionalRoleOptions.filter((role) => role !== roleOption));
    } else {
      setSelectedAdditionalRoleOptions([...selectedAdditionalRoleOptions, roleOption]);
    }
  };

  return (
    <div className={'modal display-block'}>
      <section className="modal-main">
        <h2 className="modal-title">{t('edit-title')}</h2>
        <form>
          <div className="modal-content">
            <div className="modal-content-column">
              <label>
                <b>{t('edit-name')}</b>
              </label>
              <label>
                <b>{t('edit-email')}</b>
              </label>
              <label>
                <b>{t('edit-broker')}</b>
              </label>
              <label>
                <b>{t('edit-intermediary-number')}</b>
              </label>
              <label>
                <b>{t('edit-last-active')}</b>
              </label>
              <br />
              <label>
                <b>{t('edit-roles')}</b>
              </label>
              <RadioGroup
                value={selectedMainRoleOption}
                onChange={(event) => setSelectedMainRoleOption(event.target.value)}
                name="radio-buttons-group"
              >
                {USER_MANAGEMENT_MAIN_ROLE_OPTIONS.map((option, index) => (
                  <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.label} />
                ))}
              </RadioGroup>

              <br />
              <Button variant="outlined" onClick={handleResetPassword} disabled={resetPasswordButtonDisabled}>
                {resetPasswordButtonText}
              </Button>
              <br />
              <Button variant="outlined" onClick={handleReset2FA} disabled={reset2FAButtonDisabled}>
                {reset2FAButtonText}
              </Button>
            </div>
            <div className="modal-content-column">
              <label>{`${initialUser.firstName} ${initialUser.lastName}`}</label>
              <label>{`${initialUser.email}`}</label>
              <label>{`${initialUser.broker}`}</label>
              <label>{`${initialUser.intermediaryNumber}`}</label>
              <label>{getLastActive()}</label>
              <br />
              <label>
                <b>{t('edit-roles-extra')}</b>
              </label>
              <FormGroup>
                {USER_MANAGEMENT_ADDITIONAL_ROLE_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={selectedAdditionalRoleOptions.includes(option.value)}
                        onChange={() => handleAdditionalRolesChange(option.value)}
                      />
                    }
                    label={option.label}
                  />
                ))}
              </FormGroup>
            </div>
          </div>
          <div className="modal-button-row">
            {disabled ? (
              <div className="modal-button-loading">
                <CircularProgress size={32} color="info" style={{ color: 'white' }} />
              </div>
            ) : (
              <input
                type="button"
                value={t('edit-confirm')}
                className="modal-button-positive"
                onClick={handleSubmitClicked}
                disabled={disabled}
              />
            )}
            <button className="modal-button-negative" type="button" onClick={handleClose} disabled={disabled}>
              {t('edit-cancel')}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};
