export const APPLICANT_TYPE_PERSON = 'Persoon';
export const APPLICANT_TYPE_COMPANY = 'BV';
export const APPLICANT_DATA = 'ApplicantData';

export const COLLATERAL_TYPE = 'CollateralObject';
export const COLLATERAL_DATA = 'CollateralData';

export const FINANCING_TYPE = 'FinancingObject';
export const FINANCING_DATA = 'FinancingData';

export const COMPANY_DATA = 'CompanyData';

export const LOAN_PART_DATA = 'LoanPartData';
export const LOAN_PART_TYPE = 'LoandPartObject';

export const ROLE_ADMIN = 'admin';
export const ROLE_USER = 'user';
export const ROLE_DCMF = 'dcmf-medewerker';
export const ROLE_MIXED_USE = 'mixed-use';
export const ROLE_UPFRONT_FEE = 'upfront-fee';

export const MAIN_ROLES = [ROLE_ADMIN, ROLE_USER, ROLE_DCMF];
export const ADDITIONAL_ROLES = [ROLE_MIXED_USE, ROLE_UPFRONT_FEE];

export const KVK_NUMBER_LENGTH = 8;
export const RSIN_NUMBER_LENGTH = 9;
export const DEPOSIT_AMOUNT_MAX = 1000000;
export const LOAN_DURATION_MIN = 60;
export const LOAN_DURATION_MAX = 360;
export const LOAN_PART_DURATION_MIN = 60;
export const LOAN_PART_DURATION_MAX = 360;
export const LOAN_PART_VALUE_MIN = 10000;
export const LOAN_PART_VALUE_MAX = 3000000;
export const LTV_INTEREST_ONLY_MAX_PERCENTAGE = 75;

export const NAME_MAX_LENGTH = 22;
export const NAME_SUFFIX_DOTS = '...';

export const BASE_URL_API = process.env.REACT_APP_ENVIRONMENT;
export const BASE_KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK;

export const ACTIVE_USER_ID = 'activeUserId';
export const ACTIVE_USER_NAME = 'activeUserName';
export const ACTIVE_TOKEN = 'token';

export const KEY_SUCCESS = 'success';
export const KEY_USER_ID = 'userId';

export const HEADER_MAX_LTV = 'maxLTV';
export const HEADER_MIN_ICR = 'minICR';
export const HEADER_MIN_DSCR = 'minDSCR';
export const HEADER_INTEREST_RATES = 'interestRates';
export const HEADER_UPFRONT_FEES = 'upfrontFees';

export const HEADER_AUTHORIZATION = "Authorization"

export const REQUEST_POST = 'POST';
export const REQUEST_GET = 'GET';

export const DCMF_BASE_COLOR = '#FF7700';
export const SEND = 'Verzenden';

export const UPFRONT_FEES_ROW_COUNT = 10;
export const UPFRONT_FEES_COLUMN_COUNT = 6;
