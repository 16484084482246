import { useTranslation } from "react-i18next";
import "./modal.css";

export const DeleteUserModal = ({
  handleClose,
  handleSubmit,
  show,
  children,
  disabled,
}: {
  handleClose: any;
  handleSubmit: any;
  show: any;
  children: any;
  disabled: boolean;
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "user-management" });
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <h2 className="modal-title">{t("delete-title")}</h2>
        <form>
          <div className="modal-content">{children}</div>
          <div className="modal-button-row">
            <input
              type="button"
              value={t("delete-confirm")}
              className="modal-button-positive"
              onClick={handleSubmit}
              disabled={disabled}
            />
            <button
              className="modal-button-negative"
              type="button"
              onClick={handleClose}
              disabled={disabled}
            >
              {t("delete-cancel")}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};
