import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './css/ObjectWizard.css';
import { Wizard, useWizardStep } from 'react-wizard-primitive';
import WizardStepOne from 'wizard/WizardStepOne';
import WizardStepTwo from 'wizard/WizardStepTwo';
import WizardStepThree from 'wizard/WizardStepThree';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from 'components/header/Header';

const FirstStep = () => {
  const { isActive, nextStep, goToStep } = useWizardStep();
  return isActive ? (
    <div className="wizard_step_container">
      <WizardStepOne
        clickNextHandler={nextStep}
        clickBackHandler={HandleFinishedWizard}
        clickOnNavStepHandler={(stepNumber: number) => {
          goToStep(stepNumber);
        }}
      />
    </div>
  ) : null;
};

const SecondStep = () => {
  const { isActive, nextStep, previousStep, goToStep } = useWizardStep();
  return isActive ? (
    <div className="wizard_step_container">
      <WizardStepTwo
        clickNextHandler={nextStep}
        clickBackHandler={previousStep}
        clickOnNavStepHandler={(stepNumber: number) => {
          goToStep(stepNumber);
        }}
      />
    </div>
  ) : null;
};

const ThirdStep = () => {
  const { isActive, previousStep, goToStep } = useWizardStep();
  return isActive ? (
    <div className="wizard_step_container">
      <WizardStepThree
        clickNextHandler={HandleFinishedWizard}
        clickBackHandler={previousStep}
        clickOnNavStepHandler={(stepNumber: number) => {
          goToStep(stepNumber);
        }}
      />
    </div>
  ) : null;
};

const HandleFinishedWizard = () => {
  alert('Finished wizard');
};

const ObjectWizard = () => {
  const [, setValue] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  var wizardStepIndex = 0;

  if (searchParams.get('wizardStep') != null) {
    wizardStepIndex = Number(searchParams.get('wizardStep'));

    setSearchParams('');
  }

  return (
    <div className="app_container">
      <Header />
      <div className="wizard_container">
        <Wizard
          initialStepIndex={wizardStepIndex}
          onChange={({ newStepIndex, previousStepIndex }) => {
            setValue(newStepIndex);
          }}
        >
          <FirstStep />
          <SecondStep />
          <ThirdStep />
        </Wizard>
      </div>
    </div>
  );
};

export default ObjectWizard;
