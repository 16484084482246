import './css/GlobalValues.css';
import './css/ObjectForm.css';
import './css/UserDossierHistory.css';
import { useKeycloak } from "@react-keycloak/web";
import ellipse_half from 'assets/ellipse_big_half.svg';
import { useEffect, useState } from 'react';
import { MoonLoader } from 'react-spinners';
import {
  ACTIVE_USER_ID,
  APPLICANT_DATA,
  BASE_URL_API,
  COLLATERAL_DATA,
  COMPANY_DATA,
  FINANCING_DATA,
  HEADER_AUTHORIZATION,
  KEY_USER_ID,
  LOAN_PART_DATA,
  REQUEST_GET,
} from 'constants/constants';
import { DataGrid, GridColDef, GridRowsProp, GridSortModel, nlNL } from '@mui/x-data-grid';
import { DossierModel } from 'models/dossierModel';
import { useNavigate } from 'react-router';
import { RequestModel } from 'models/requestModel';
import { IconButton, Stack } from '@mui/material';
import { NO_REQUEST_HISTORY_FOUND } from 'constants/messages/errorMessages';
import Header from 'components/header/Header';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';

const UserRequestHistoryView = (): JSX.Element => {
  let navigate = useNavigate();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'user-request-history',
  });

  const [getPageLoaded, setPageLoaded] = useState<boolean>(false);
  const [getDossiers, setDossiers] = useState<Array<DossierModel>>();
  const [getDossierRows, setDossierRows] = useState<GridRowsProp>();
  const { keycloak } = useKeycloak();
  const [getSortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'dateSent',
      sort: 'desc',
    },
  ]);

  const columns: GridColDef[] = [
    {
      field: 'dateSent',
      headerName: t('date-sent'),
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
      flex: 6,
      maxWidth: 200,
    },
    {
      field: 'midOfficeNumber',
      headerName: t('midoffice-number'),
      flex: 6,
      maxWidth: 200,
    },
    { field: 'firstPerson', headerName: t('first-person'), flex: 8 },
    { field: 'firstCollateral', headerName: t('first-collateral'), flex: 12 },
    {
      field: 'edit',
      headerName: t('edit'),
      flex: 1,
      maxWidth: 100,
      disableColumnMenu: true,
      filterable: false,
      hideable: false,
      sortable: false,

      renderCell: (params) => {
        const onEditClick = (e: any) => {
          e.stopPropagation();

          setPageLoaded(false);

          if (getDossiers) {
            var matchingDossier = getDossiers.find((data) => data.Code === params.id.toString());

            loadDossierDataIntoSession(matchingDossier);
          }

          navigate({
            pathname: '/wizard',
            search: '?wizardStep=0',
          });
        };

        return (
          <IconButton onClick={onEditClick}>
            <EditIcon />
          </IconButton>
        );
      },
    },
  ];

  const formatDate = (date: string): string => {
    var splitDate = date.split(',');
    var day = splitDate[2];
    var month = splitDate[1];
    var year = splitDate[0];

    return `${day}/${month}/${year}`;
  };

  const noRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {NO_REQUEST_HISTORY_FOUND}
      </Stack>
    );
  };

  const noResultsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {NO_REQUEST_HISTORY_FOUND}
      </Stack>
    );
  };

  const createRows = () => {
    var rows: Array<{
      id: string;
      dateSent: string;
      midOfficeNumber: number;
      firstPerson: string;
      firstCollateral: string;
      edit: string;
    }> = [];
    if (getDossiers && getDossiers.length > 0) {
      for (var i = 0; i < getDossiers.length; i++) {
        var dossier = getDossiers[i];
        var date = dossier.CreationDate.split(/[-T:]/);
        rows.push({
          id: dossier.Id,
          dateSent: date.toString(),
          midOfficeNumber: Number(dossier.Code),
          firstPerson: dossier.NaturalPerson,
          firstCollateral: dossier.CollateralObject,
          edit: '',
        });
      }
    }

    setDossierRows(rows);
  };

  const loadData = () => {
    const activeUserId = window.localStorage.getItem(ACTIVE_USER_ID) ?? '';
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append(KEY_USER_ID, activeUserId);
    requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)

    const requestOptions = {
      method: REQUEST_GET,
      headers: requestHeaders,
    };

    fetch(BASE_URL_API + 'api/get-history', requestOptions as RequestInit)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }

        return response.text();
      })
      .then((result) => {
        var dossiers = new Array<DossierModel>();
        dossiers = JSON.parse(result);

        setDossiers(dossiers);

        return Promise.resolve();
      });
  };

  const loadDossierDataIntoSession = (dossier: DossierModel | undefined) => {
    if (!dossier) {
      return;
    }

    var body: RequestModel = JSON.parse(dossier.FormBody);

    sessionStorage.clear();
    sessionStorage.setItem(APPLICANT_DATA, JSON.stringify(body.applicants));
    sessionStorage.setItem(COMPANY_DATA, JSON.stringify(body.companies));
    sessionStorage.setItem(COLLATERAL_DATA, JSON.stringify(body.collaterals));
    sessionStorage.setItem(LOAN_PART_DATA, JSON.stringify(body.loanParts));
    sessionStorage.setItem(FINANCING_DATA, JSON.stringify(body.financing));
  };

  useEffect(() => {
    if (getDossiers) {
      createRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDossiers]);

  useEffect(() => {
    if (getDossierRows) {
      setPageLoaded(true);
    }
  }, [getDossierRows]);

  useEffect(() => {
    const onPageLoad = () => {
      loadData();
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <div className="app_container">
      <Header />
      {getPageLoaded ? (
        <div className="history_page">
          <p className="title_create_object">{t('title')}</p>
          <p className="text padding-top">{t('body-p1')}</p>
          <p className="text">{t('body-p2')}</p>
          <div className="data_grid">
            <DataGrid
              localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
              rows={getDossierRows!}
              columns={columns}
              components={{
                NoRowsOverlay: noRowsOverlay,
                NoResultsOverlay: noResultsOverlay,
              }}
              getRowId={(row: any) => row.midOfficeNumber}
              sortModel={getSortModel}
              onSortModelChange={setSortModel}
            />
          </div>
        </div>
      ) : (
        <div className="loader">
          <MoonLoader color="#FF7700" />
        </div>
      )}

      <img className="background_logo" src={ellipse_half} alt="" />
    </div>
  );
};

export default UserRequestHistoryView;
