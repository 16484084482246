import './css/UserManagement.css';
import './css/ObjectForm.css';
import { AddUserModal } from 'components/modals/addUserModal';
import ellipse_half from 'assets/ellipse_big_half.svg';
import { UserModel } from 'models/userModel';
import * as React from 'react';
import { RefObject, useEffect, useState } from 'react';
import { BASE_URL_API, HEADER_AUTHORIZATION } from 'constants/constants';
import { MoonLoader } from 'react-spinners';
import { DeleteUserModal } from 'components/modals/deleteUserModal';
import Header from 'components/header/Header';
import { DataGrid, nlNL } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { mapToKeycloakGroup } from 'helpers/mapToKeycloakGroup';
import { downloadCSV } from 'helpers/downloadCSV';
import { useTranslation } from 'react-i18next';
import { EditUserModal } from 'components/modals/editUserModal';
import { useKeycloak } from '@react-keycloak/web';

export const UserManagementView = (): JSX.Element => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation(undefined, { keyPrefix: 'user-management' });
  const [userModels, setUserModels] = useState<Array<UserModel>>(new Array<UserModel>());
  const [userData, setUserData] = useState<Array<UserModel>>(userModels);
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);
  const [addModalDisabled, setAddModalDisabled] = useState(false);
  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false);
  const [deleteModalDisabled, setDeleteModalDisabled] = useState(false);
  const [editUserModalVisible, setEditUserModalVisible] = useState(false);
  const [editModalDisabled, setEditModalDisabled] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [rows, setRows] = useState<any[]>([]);
  const [getPageLoaded, setPageLoaded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [userForEdit, setUserForEdit] = useState<UserModel>();

  const userNameInput: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  const userFirstNameInput: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  const userLastNameInput: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  const userEmailInput: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  const userRoleInput: RefObject<HTMLSelectElement> = React.createRef<HTMLSelectElement>();
  const userBrokerInput: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  const userIntermediaryNumberInput: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  const columns = [
    { field: 'name', headerName: 'Naam', flex: 2 },
    { field: 'broker', headerName: 'Broker', flex: 1 },
    {
      field: 'number',
      headerName: 'Tussenpersoon Nummer',
      flex: 1,
    },
    {
      field: 'delete',
      headerName: '',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <>
          {window.localStorage.getItem('activeUserId') !== params.row.id ? (
            <Tooltip title="Verwijder gebruiker" placement="left">
              <IconButton onClick={() => showDeleteUserModal(params.row.id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <div />
          )}
        </>
      ),
    },
    {
      field: 'edit',
      headerName: '',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <>
          {window.localStorage.getItem('activeUserId') !== params.row.id ? (
            <Tooltip title="Edit gebruiker" placement="right">
              <IconButton onClick={() => handleEditUserClicked(params.row.id)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <div />
          )}
        </>
      ),
    },
  ];

  const mapUserModels = (dataSet: Array<UserModel>) => {
    return dataSet.map((model) => ({
      id: model.id,
      name: `${model.firstName} ${model.lastName}`,
      broker: model.broker,
      number: model.intermediaryNumber,
      active: `${model.lastActive > -1 ? model.lastActive : ''} ${
        model.lastActive > -1 ? (model.lastActive === 1 ? ' dag geleden' : ' dagen geleden') : ''
      }`,
      role: model.roles,
    }));
  };

  // Fetch or map users to data rows
  useEffect(() => {
    if (userModels.length > 0) {
      setRows(mapUserModels(userModels));
    } else {
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userModels]);

  // Check page data loaded
  useEffect(() => {
    if (rows.length > 0) {
      setPageLoaded(true);
    }
  }, [rows]);

  useEffect(() => {
    if (userForEdit !== undefined) {
      setEditUserModalVisible(true);
      setEditModalDisabled(false);
    } else {
      setEditUserModalVisible(false);
      setEditModalDisabled(true);
    }
  }, [userForEdit]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      setRows(mapUserModels(userModels));
      return;
    }
    const filteredRows = userData.filter((row) => row.name.toLowerCase().includes(e.target.value.toLowerCase()));

    setRows(mapUserModels(filteredRows));
  };

  const handleEditUserClicked = async (editUserId: string) => {
    var requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)

    var requestOptions = {
      method: 'GET',
      headers: requestHeaders,
    };

    await fetch(BASE_URL_API + `api/user-from-id?id=${editUserId}`, requestOptions as RequestInit)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch user');
        }

        return response.json();
      })
      .then((parsedResult) => {
        const { id, firstName, username, lastName, lastEvent, groups, realmRoles, email, broker, intermediaryNumber } = parsedResult;
        setUserForEdit(
          new UserModel(
            id,
            firstName || username,
            lastName || '',
            lastEvent && lastEvent.activity ? lastEvent.activity : -1,
            groups,
            realmRoles,
            email,
            broker,
            intermediaryNumber
          )
        );
      })
      .catch((error) => console.error('error', error));
  };

  const editUserSubmit = async (editedUser: UserModel) => {
    setEditModalDisabled(true);

    var requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)

    var raw = JSON.stringify({
      id: editedUser.id,
      groups: editedUser.groups,
      realmRoles: editedUser.roles,
    });

    var requestOptions = {
      method: 'PUT',
      headers: requestHeaders,
      body: raw,
    };

    await fetch(BASE_URL_API + 'api/update?userId=' + editedUser.id, requestOptions as RequestInit)
      .then((response) => response.text())
      .then(() => {
        fetchUsers();

        setUserForEdit(undefined);
        return Promise.resolve();
      })
      .catch((error) => console.log('error', error));
  };

  const addUserSubmit = () => {
    setAddModalDisabled(true);

    var firstName = userFirstNameInput?.current?.value;
    var lastName = userLastNameInput?.current?.value;
    var accountName = userNameInput?.current?.value;
    var email = userEmailInput?.current?.value;
    var role = userRoleInput?.current?.value;
    let broker = userBrokerInput?.current?.value;
    let userIntermediary = userIntermediaryNumberInput?.current?.value;
    var groupRole = mapToKeycloakGroup(role);

    var requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('brokerData', broker + '');
    requestHeaders.append('brokerIntermediaryNumber', userIntermediary + '');
    requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)

    var raw = JSON.stringify({
      username: accountName,
      enabled: true,
      totp: false,
      emailVerified: true,
      firstName: firstName,
      lastName: lastName,
      email: email,
      disableableCredentialTypes: [],
      requiredActions: [],
      notBefore: 0,
      realmRoles: [],
      groups: [groupRole],
    });

    var requestOptions = {
      method: 'POST',
      headers: requestHeaders,
      body: raw,
    };

    fetch(BASE_URL_API + 'api/add', requestOptions as RequestInit)
      .then(() => {
        fetchUsers();
        return Promise.resolve();
      })
      .catch((error) => console.error('error', error));
  };

  const deleteUserSubmit = (userId: string) => {
    var requestHeaders = new Headers();
    requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)

    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow',
      headers: requestHeaders
    };

    fetch(BASE_URL_API + 'api/delete?userId=' + userId, requestOptions as RequestInit)
      .then((response) => response.text())
      .then(() => {
        fetchUsers();
        return Promise.resolve();
      })
      .catch((error) => console.log('error', error));
  };

  const fetchUsers = () => {
    var requestHeaders = new Headers();
    requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)
    
    var requestOptions = {
      method: 'GET',
      headers: requestHeaders
    };

    fetch(BASE_URL_API + 'api/user-names', requestOptions as RequestInit)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        return response.json();
      })
      .then((parsedResult) => {
        const models = parsedResult.map((user: any) => {
          const { id, firstName, username, lastName, lastEvent, realmRoles, email, broker, intermediaryNumber } = user;

          if (id === localStorage.getItem('activeUserId')) {
            localStorage.setItem('activeUserBroker', broker);
            localStorage.setItem('activeUserIntermediaryNumber', intermediaryNumber);
          }

          return new UserModel(
            id,
            firstName || username,
            lastName || '',
            lastEvent && lastEvent.activity ? lastEvent.activity : -1,
            [],
            realmRoles,
            email,
            broker,
            intermediaryNumber
          );
        });

        setUserModels(models);
        setUserData([...models]);
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        hideDeleteUserModal();
        setAddUserModalVisible(false);
        setAddModalDisabled(false);
      });
  };

  const showDeleteUserModal = (id: string) => {
    setDeleteUserModalVisible(true);
    setCurrentId(id);
    setDeleteModalDisabled(false);
  };

  const hideDeleteUserModal = () => {
    setDeleteUserModalVisible(false);
  };

  return (
    <div className="app_container">
      <Header />

      {editUserModalVisible && userForEdit && (
        <EditUserModal
          initialUser={userForEdit}
          handleClose={() => setUserForEdit(undefined)}
          handleSubmit={(editedUser: UserModel) => editUserSubmit(editedUser)}
          disabled={editModalDisabled}
        />
      )}

      <DeleteUserModal
        handleClose={() => setDeleteUserModalVisible(false)}
        handleSubmit={() => deleteUserSubmit(currentId)}
        show={deleteUserModalVisible}
        disabled={deleteModalDisabled}
      >
        <label style={{ marginLeft: '8px', paddingTop: '8px' }}>{t('delete-confirmation')}</label>
      </DeleteUserModal>
      <AddUserModal
        show={addUserModalVisible}
        handleClose={() => setAddUserModalVisible(false)}
        handleSubmit={addUserSubmit}
        disabled={addModalDisabled}
      >
        <div className="modal-content-column">
          <label>{t('first-name')}</label>
          <input type="text" id="user-first-name" pattern="[a-zA-Z0-9 ]+" maxLength={50} required tabIndex={1} ref={userFirstNameInput} />
          <label>{t('user-name')}</label>
          <input type="text" id="user-name" pattern="[a-zA-Z0-9 ]+" maxLength={50} required tabIndex={3} ref={userNameInput} />
          <label>{t('user-role')}</label>
          <select required tabIndex={5} id="user-role" ref={userRoleInput}>
            <option value="admin">{t('roles.admin')}</option>
            <option value="dcmf-medewerker">{t('roles.dcmf-employee')}</option>
            <option value="user">{t('roles.user')}</option>
          </select>
          <label>{t('intermediary-number')}</label>
          <input
            type="text"
            id="intermediary-number"
            pattern="[a-zA-Z0-9 ]+"
            maxLength={24}
            required
            tabIndex={7}
            ref={userIntermediaryNumberInput}
          />
        </div>
        <div className="modal-content-column">
          <label>{t('last-name')}</label>
          <input type="text" id="user-last-name" pattern="[a-zA-Z0-9 ]+" maxLength={50} required tabIndex={2} ref={userLastNameInput} />
          <label>{t('email')}</label>
          <input type="email" id="user-email" maxLength={50} required tabIndex={4} ref={userEmailInput} />
          <label>{t('broker')}</label>
          <input type="text" id="user-broker" pattern="[a-zA-Z0-9 ]+" maxLength={50} required tabIndex={6} ref={userBrokerInput} />
        </div>
      </AddUserModal>

      <div className="user_management_container">
        <div className="user_management_title">{t('title')}</div>
        <div className="user_management_toolbar">
          <TextField
            disabled={!getPageLoaded}
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder={t('search-placeholder')}
            size="small"
            style={{ flex: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            disabled={!getPageLoaded}
            variant="text"
            startIcon={<PersonAddIcon />}
            onClick={() => {
              setAddUserModalVisible(true);
            }}
          >
            {t('add-user')}
          </Button>
          <Button
            disabled={!getPageLoaded}
            variant="text"
            startIcon={<DownloadIcon />}
            onClick={() => downloadCSV(userData, t('download-csv-file-name'))}
          >
            {t('download-csv')}
          </Button>
        </div>
        <div className="data_grid">
          {getPageLoaded ? (
            <DataGrid
              localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
              rows={rows}
              columns={columns}
              sortModel={undefined}
            />
          ) : (
            <div className="loader">
              <MoonLoader color="#FF7700" />
            </div>
          )}
        </div>
      </div>

      <img className="background_logo" src={ellipse_half} alt="" />
    </div>
  );
};

export default UserManagementView;
