import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { IWizardStepProps } from "interfaces/IWizardStepProps";
import ObjectFinancingForm from "pages/ObjectFinancingForm";
import Footer from "components/footer/Footer";

const WizardStepThree = ({
  clickNextHandler,
  clickBackHandler,
  clickOnNavStepHandler,
}: IWizardStepProps) => {
  return (
    <div>
      <ObjectFinancingForm
        clickNextHandler={clickNextHandler}
        clickBackHandler={clickBackHandler}
      />
      <Footer value={2} clickOnNavStepHandler={clickOnNavStepHandler} />
    </div>
  );
};

export default WizardStepThree;
