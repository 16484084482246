import { v4 as uuidv4 } from 'uuid';
import { RELATION_DROPDOWN_OPTIONS } from 'constants/dropdownListData';
import { DropdownOptionsModel } from './dropdownOptionsModel';
import { PersonModel } from './personModel';

export class ApplicantModel {
  person: PersonModel = new PersonModel();
  jointlyLiable: string = 'Ja';
  phoneNumber: string = '';
  phoneNumberType: string = 'Werk';
  email: string = '';
  emailType: string = 'Werk';
  addressType: string = 'Residentie';
  zipCode: string = '';
  houseNumber: string = '';
  addition: string = '';
  streetName: string = '';
  city: string = '';
  country: string = 'NL';
  countryTax: string = 'NL';
  type: string = 'Kredietnemer';
  relation: DropdownOptionsModel = RELATION_DROPDOWN_OPTIONS[0];
  relationApplicant: ApplicantModel | undefined;
  key: string = uuidv4();

  relationApplicantKey: string = '';

  isDirector: boolean = false;
  isAuthorizedSignee: boolean = false;
  isUbo: boolean = false;
}
