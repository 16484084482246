import { BASE_URL_API, HEADER_AUTHORIZATION, REQUEST_GET, UPFRONT_FEES_COLUMN_COUNT, UPFRONT_FEES_ROW_COUNT } from 'constants/constants';
import { CollateralModel } from 'models/collateralModel';
import { RateDataModel } from 'models/rateDataModel';
import { LoanPartModel } from 'models/loanPartModel';
import {
  COLLATERAL_DROPDOWN_OPTIONS,
  COLLATERAL_DROPDOWN_OPTIONS_FREE_TARIFF,
  LOAN_PART_REDEMPTION_OPTIONS,
} from 'constants/dropdownListData';

export const calculateInterestValues = async (collaterals: Array<CollateralModel>, loanParts: Array<LoanPartModel>, token: string | undefined) => {
  var requestHeaders = new Headers();
  requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + token)
  const requestOptions: RequestInit = {
    method: REQUEST_GET,
    headers: requestHeaders
  };

  const response = await fetch(BASE_URL_API + 'api/get-global-values', requestOptions);
  if (!response.ok) throw new Error('Failed to fetch global values.');

  const parsedResult = await response.json();
  const interestRates = extractInterestRates(parsedResult['InterestRates']);
  const upfrontFees = extractUpfrontFees(parsedResult['UpfrontFees']);
  const { totalDesiredFinancing, totalValue, totalRent, rentalStorage } = calculateFinancingValues(collaterals, loanParts);

  const ltv = (totalDesiredFinancing / totalValue) * 100;
  const ltvBucket = determineLtvBucket(ltv);

  let totalDesiredFinancingInterestOnly = 0;
  for (let i = 0; i < loanParts.length; i++) {
    if (loanParts[i].redemptionType.value === LOAN_PART_REDEMPTION_OPTIONS[0].value) {
      totalDesiredFinancingInterestOnly += Number(loanParts[i].loanPartAmount);
    }
  }
  const ltvInterestOnly = (totalDesiredFinancingInterestOnly / totalValue) * 100;

  const { totalInterestCosts, totalRepaymentCosts } = calculateCosts(loanParts, interestRates, upfrontFees, ltvBucket, rentalStorage);

  const totalInterestAndRepayment = totalInterestCosts + totalRepaymentCosts;
  const icr = totalRent / totalInterestCosts;
  const dscr = totalRent / totalInterestAndRepayment;

  const interestData = new RateDataModel();
  interestData.ltv = ltv;
  interestData.dscr = dscr;
  interestData.icr = icr;
  interestData.maxLtv = Number(parsedResult['MaximumLtv']);
  interestData.minIcr = Number(parsedResult['MinimumIcr']);
  interestData.minDscr = Number(parsedResult['MinimumDscr']);
  interestData.ltvInterestOnly = ltvInterestOnly;
  interestData.totalValue = totalValue;

  return interestData;
};

const extractInterestRates = (unparsedInterestRates: string): string[][] => {
  const splitInterestRates = unparsedInterestRates.split(';');
  const rowLength = 7;
  const maxRows = 5;
  const interestRates = [];

  for (let i = 0; i < maxRows; i++) {
    interestRates.push(splitInterestRates.splice(0, rowLength));
  }

  return interestRates;
};

const extractUpfrontFees = (unparsedUpfrontFees: string): number[][] => {
  if (unparsedUpfrontFees) {
    const splitUpfrontFees = unparsedUpfrontFees.split(';');
    const maxCols = UPFRONT_FEES_COLUMN_COUNT;
    const maxRows = UPFRONT_FEES_ROW_COUNT;
    let reformedUpfrontFees: Array<Array<number>> = new Array<Array<number>>();

    for (var j = 0; j < maxRows; j++) {
      const splitFeesRow = splitUpfrontFees.splice(0, maxCols);
      const rowArray = new Array<number>();
      for (var k = 0; k < splitFeesRow.length; k++) {
        rowArray.push(Number(splitFeesRow[k]));
      }

      reformedUpfrontFees.push([...rowArray]);
    }
    return reformedUpfrontFees;
  }
  return [];
};

const calculateFinancingValues = (collaterals: Array<CollateralModel>, loanParts: Array<LoanPartModel>) => {
  let totalDesiredFinancing = 0;
  loanParts.forEach((part) => {
    totalDesiredFinancing += Number(part.loanPartAmount);
  });

  let totalValue = 0;
  let rentalStorage = 0;
  let totalRent = 0;

  collaterals.forEach((collateral) => {
    let totalContractRent = 0;
    collateral.rentalContractValues.forEach((value) => {
      totalContractRent += Number(value);
    });
    totalContractRent += Number(collateral.lostThroughVacancy);

    const marketRent =
      collateral.typeCollateral.label === COLLATERAL_DROPDOWN_OPTIONS[4].label
        ? Number(collateral.commercialRentValue) + Number(collateral.residentialRentValue)
        : Number(collateral.marketRent);

    totalRent += totalContractRent > 0 ? Math.min(marketRent, totalContractRent) : marketRent;

    rentalStorage =
      COLLATERAL_DROPDOWN_OPTIONS_FREE_TARIFF.find((option) => (option.label === collateral.typeCollateral.label || option.label === collateral.subtypeCollateral.label))?.rentalStorage ?? 0;
    if (collateral.typeCollateral.label === COLLATERAL_DROPDOWN_OPTIONS[4].label) {
      const marketValue = Number(collateral.commercialMarketValue) + Number(collateral.residentialMarketValue);
      if (collateral.buildingStock === 'Ja') {
        totalValue += Number(collateral.buildingStockWorthAfterRenovation);
      } else {
        totalValue +=
          collateral.roleCollateral === 'Nee' ? Math.min(Number(marketValue), Number(collateral.buyPrice)) : Number(marketValue);
      }
    } else {
      if (collateral.buildingStock === 'Ja') {
        totalValue += Number(collateral.buildingStockWorthAfterRenovation);
      } else {
        totalValue += Math.min(Number(collateral.marketValue), Number(collateral.buyPrice));
      }
    }
  });

  return {
    totalDesiredFinancing,
    totalValue,
    totalRent,
    rentalStorage,
  };
};

const determineLtvBucket = (ltv: number): number => {
  let ltvBucket = 0;
  if (ltv > 80) {
    ltvBucket = 6;
  }
  if (ltv <= 80) {
    ltvBucket = 5;
  }
  if (ltv <= 75) {
    ltvBucket = 4;
  }
  if (ltv <= 70) {
    ltvBucket = 3;
  }
  if (ltv <= 65) {
    ltvBucket = 2;
  }
  if (ltv <= 60) {
    ltvBucket = 1;
  }
  if (ltv <= 50) {
    ltvBucket = 0;
  }
  return ltvBucket;
};

const calculateCosts = (
  loanParts: Array<LoanPartModel>,
  interestRates: string[][],
  upfrontFees: number[][],
  ltvBucket: number,
  rentalStorage: number
) => {
  const monthsInYear = 12;
  let totalInterestCosts = 0;
  let totalRepaymentCosts = 0;

  loanParts.forEach((loanPart) => {
    let interestCost = 0;
    const interestVariabilityPeriod = Number(loanPart.variabilityType!.value);
    const amount = Number(loanPart.loanPartAmount);
    const duration = Number(loanPart.duration);
    const interestTariff = Number(interestRates[interestVariabilityPeriod][ltvBucket].replaceAll(',', '.'));
    let repaymentCost = 0;

    let upfrontFeeDiscount = getUpfrontDiscount(loanParts, upfrontFees);

    if (loanPart.redemptionType.value === '0') {
      interestCost = (interestTariff - upfrontFeeDiscount + rentalStorage) * amount;
    } else if (loanPart.redemptionType.value === '1') {
      repaymentCost = (amount / duration) * monthsInYear;
      for (let i = 0; i < monthsInYear; i++) {
        interestCost += ((interestTariff + rentalStorage) * (amount - i * (amount / duration))) / 12;
      }
    } else if (loanPart.redemptionType.value === '2') {
      const interest = (interestTariff + rentalStorage) / monthsInYear;
      const interestAndRepaymentMonthly = (amount * interest) / (1 - Math.pow(interest + 1, -duration));
      let loanPartAmountCopy = amount;
      for (let i = 0; i < monthsInYear; i++) {
        const intermediaryInterestCosts = interest * loanPartAmountCopy;
        const intermediaryRepaymentCosts = interestAndRepaymentMonthly - intermediaryInterestCosts;
        repaymentCost += intermediaryRepaymentCosts;
        interestCost += intermediaryInterestCosts;
        loanPartAmountCopy -= intermediaryRepaymentCosts;
      }
    }

    totalInterestCosts += interestCost;
    totalRepaymentCosts += repaymentCost;
  });

  return { totalInterestCosts, totalRepaymentCosts };
};

const getLtvInterestOnly = (loanParts: LoanPartModel[]) => {};

export const getUpfrontInterestRate = async (collaterals: Array<CollateralModel>, loanParts: Array<LoanPartModel>, token: string | undefined): Promise<number> => {
  var requestHeaders = new Headers();
  requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + token)

  const requestOptions: RequestInit = {
    method: REQUEST_GET,
    headers: requestHeaders
  };

  const response = await fetch(BASE_URL_API + 'api/get-global-values', requestOptions);
  if (!response.ok) throw new Error('Failed to fetch global values.');

  const parsedResult = await response.json();
  const interestRates = extractInterestRates(parsedResult['InterestRates']);
  const upfrontFees = extractUpfrontFees(parsedResult['UpfrontFees']);

  const { totalDesiredFinancing, totalValue, rentalStorage } = calculateFinancingValues(collaterals, loanParts);
  const ltv = (totalDesiredFinancing / totalValue) * 100;
  const ltvBucket = determineLtvBucket(ltv);

  const getInterestTariff = (loanParts: Array<LoanPartModel>, interestRates: string[][], ltvBucket: number): number => {
    const interestVariabilityPeriod = Number(loanParts[0].variabilityType!.value);
    const interestTariff = Number(interestRates[interestVariabilityPeriod][ltvBucket].replaceAll(',', '.'));

    return interestTariff;
  };

  const interestTariff = getInterestTariff(loanParts, interestRates, ltvBucket);
  const upfrontFeeDiscount = getUpfrontDiscount(loanParts, upfrontFees);

  return interestTariff - upfrontFeeDiscount + rentalStorage;
};

export const getUpfrontDiscount = (loanParts: Array<LoanPartModel>, upfrontFees: number[][]) => {
  const loanPart = loanParts[0];
  if (!loanPart) return 0;

  if (!loanPart.hasUpfrontFee) {
    return 0;
  }

  const upfrontFeeDiscount = upfrontFees[Number(loanPart.upfrontFeeType.value) - 1][Number(loanPart.variabilityType?.value) + 1];
  return upfrontFeeDiscount;
};
