export const COLLATERAL_DROPDOWN_OPTIONS = [
  { value: '0', label: 'Eengezinswoning', isDisabled: false, rentalStorage: 0 },
  { value: '1', label: 'Eengezinswoning met garage', isDisabled: false, rentalStorage: 0 },
  { value: '2', label: 'Flat/appartement', isDisabled: false, rentalStorage: 0 },
  { value: '3', label: 'Flat/appartement met garage', isDisabled: false , rentalStorage: 0 },
  { value: '4', label: 'Combipand', isDisabled: false , rentalStorage: 0.002 },
  { value: '5', label: 'Bungalow', isDisabled: false , rentalStorage: 0 },
  { value: '6', label: 'Recreatiewoning', isDisabled: false , rentalStorage:0.005 },
];

export const COLLATERAL_DROPDOWN_OPTIONS_SUBTYPES = [
  { value: '7', label: 'Verhuur (standaard)', isDisabled: false },
  { value: '8', label: 'Zorgobject', isDisabled: false , rentalStorage: 0.002 },
  { value: '9', label: 'Kamerverhuur', isDisabled: false , rentalStorage: 0 },
]

export const COLLATERAL_DROPDOWN_OPTIONS_FREE_TARIFF = [
  COLLATERAL_DROPDOWN_OPTIONS[4],
  COLLATERAL_DROPDOWN_OPTIONS[6],
  COLLATERAL_DROPDOWN_OPTIONS_SUBTYPES[1],
  COLLATERAL_DROPDOWN_OPTIONS_SUBTYPES[2],
];

export const COLLATERAL_DROPDOWN_OPTIONS_NON_FREE_TARIFF = [
  COLLATERAL_DROPDOWN_OPTIONS[0],
  COLLATERAL_DROPDOWN_OPTIONS[1],
  COLLATERAL_DROPDOWN_OPTIONS[2],
  COLLATERAL_DROPDOWN_OPTIONS[3],
  COLLATERAL_DROPDOWN_OPTIONS[5],
];


export const COLLATERAL_RENTAL_CONTRACTS_COUNT = [
  { value: '0', label: 'Niet verhuurd', isDisabled: false },
  { value: '1', label: '1', isDisabled: false },
  { value: '2', label: '2', isDisabled: false },
  { value: '3', label: '3', isDisabled: false },
  { value: '4', label: '4', isDisabled: false },
  { value: '5', label: '5', isDisabled: false },
  { value: '6', label: '6', isDisabled: false },
  { value: '7', label: '7', isDisabled: false },
  { value: '8', label: '8', isDisabled: false },
  { value: '9', label: '9', isDisabled: false },
  { value: '10', label: '10', isDisabled: false },
  { value: '11', label: '11', isDisabled: false },
  { value: '12', label: '12', isDisabled: false },
  { value: '13', label: '13', isDisabled: false },
  { value: '14', label: '14', isDisabled: false },
  { value: '15', label: '15', isDisabled: false },
];

export const RELATION_DROPDOWN_OPTIONS = [
  { value: '0', label: 'Alleenstaand', isDisabled: false },
  {
    value: '1',
    label: 'Gehuwd in beperkte gemeenschap van goederen',
    isDisabled: false,
  },
  {
    value: '2',
    label: 'Gehuwd in gemeenschap van goederen',
    isDisabled: false,
  },
  {
    value: '3',
    label: 'Gehuwd onder huwelijkse voorwaarden',
    isDisabled: false,
  },
  {
    value: '4',
    label: 'Geregistreerd partnerschap met gemeenschap van goederen',
    isDisabled: false,
  },
  {
    value: '5',
    label: 'Geregistreerd partnerschap met beperkte gemeenschap van goederen',
    isDisabled: false,
  },
  {
    value: '6',
    label: 'Geregistreerd partnerschap zonder gemeenschap van goederen',
    isDisabled: false,
  },
  {
    value: '7',
    label: 'Samenwonend met samenlevingscontract',
    isDisabled: false,
  },
  {
    value: '8',
    label: 'Samenwonend zonder samenlevingscontract',
    isDisabled: false,
  },
];

export const COMPANY_DROPDOWN_OPTIONS = [
  { value: '0', label: 'Eenmanszaak', isDisabled: false },
  { value: '1', label: 'Maatschap', isDisabled: false },
  { value: '2', label: 'V.O.F', isDisabled: false },
  { value: '3', label: 'Besloten Vennootschap', isDisabled: false },
  { value: '4', label: 'Stichting', isDisabled: false },
];

export const LOAN_PART_PERIOD_OPTIONS = [
  { value: '-1', label: 'Kies een rentevaste periode', isDisabled: true },
  { value: '0', label: '12 maanden', isDisabled: false },
  { value: '1', label: '36 maanden', isDisabled: false },
  { value: '2', label: '60 maanden', isDisabled: false },
  { value: '3', label: '84 maanden', isDisabled: false },
  { value: '4', label: '120 maanden', isDisabled: false },
];

export const LOAN_PART_UPFRONT_FEE_OPTIONS = [
  { value: '0', label: 'Geen upfront fee', isDisabled: false },
  { value: '1', label: '1%', isDisabled: false },
  { value: '2', label: '2%', isDisabled: false },
  { value: '3', label: '3%', isDisabled: false },
  { value: '4', label: '4%', isDisabled: false },
  { value: '5', label: '5%', isDisabled: false },
  { value: '6', label: '6%', isDisabled: false },
  { value: '7', label: '7%', isDisabled: false },
  { value: '8', label: '8%', isDisabled: false },
  { value: '9', label: '9%', isDisabled: false },
  { value: '10', label: '10%', isDisabled: false },  
];

export const LOAN_PART_REDEMPTION_OPTIONS = [
  { value: '0', label: 'Aflossingsvrij', isDisabled: false },
  { value: '1', label: 'Lineair', isDisabled: false },
  { value: '2', label: 'Annuïtair', isDisabled: false },
];

export const USER_MANAGEMENT_MAIN_ROLE_OPTIONS = [
  {value: 'admin', label: 'Admin'},
  {value: 'dcmf-medewerker', label: 'DCMF medewerker'},
  {value: 'user', label: 'Gebruiker'},
];

export const USER_MANAGEMENT_ADDITIONAL_ROLE_OPTIONS = [
  {value: 'mixed-use', label: 'Combipand'},
  {value: 'upfront-fee', label: 'Upfront fee'},
];

export const COUNTRY_OPTIONS = [
  { label: 'Afghanistan', value: 'AF', isDisabled: false },
  { label: 'Åland', value: 'AX', isDisabled: false },
  { label: 'Albanië', value: 'AL', isDisabled: false },
  { label: 'Algerije', value: 'DZ', isDisabled: false },
  { label: 'Amerikaans-Samoa', value: 'AS', isDisabled: false },
  {
    label: 'Amerikaanse Maagdeneilanden',
    value: 'VI',
    isDisabled: false
  },
  { label: 'Andorra', value: 'AD', isDisabled: false },
  { label: 'Angola', value: 'AO', isDisabled: false },
  { label: 'Anguilla', value: 'AI', isDisabled: false },
  { label: 'Antarctica', value: 'AQ', isDisabled: false },
  { label: 'Antigua en Barbuda', value: 'AG', isDisabled: false },
  { label: 'Argentinië', value: 'AR', isDisabled: false },
  { label: 'Armenië', value: 'AM', isDisabled: false },
  { label: 'Aruba', value: 'AW', isDisabled: false },
  { label: 'Australië', value: 'AU', isDisabled: false },
  { label: 'Azerbeidzjan', value: 'AZ', isDisabled: false },
  { label: "Bahama's", value: 'BS', isDisabled: false },
  { label: 'Bahrein', value: 'BH', isDisabled: false },
  { label: 'Bangladesh', value: 'BD', isDisabled: false },
  { label: 'Barbados', value: 'BB', isDisabled: false },
  { label: 'België', value: 'BE', isDisabled: false },
  { label: 'Belize', value: 'BZ', isDisabled: false },
  { label: 'Benin', value: 'BJ', isDisabled: false },
  { label: 'Bermuda', value: 'BM', isDisabled: false },
  { label: 'Bhutan', value: 'BT', isDisabled: false },
  { label: 'Bolivia', value: 'BO', isDisabled: false },
  { label: 'Botswana', value: 'BW', isDisabled: false },
  { label: 'Bouvet', value: 'BV', isDisabled: false },
  { label: 'Brazilië', value: 'BR', isDisabled: false },
  {
    label: 'Brits Territorium in de Indische Oceaan',
    value: 'IO',
    isDisabled: false
  },
  { label: 'Britse Maagdeneilanden', value: 'VG', isDisabled: false },
  { label: 'Brunei', value: 'BN', isDisabled: false },
  { label: 'Bulgarije', value: 'BG', isDisabled: false },
  { label: 'Burkina Faso', value: 'BF', isDisabled: false },
  { label: 'Burundi', value: 'BI', isDisabled: false },
  { label: 'Cambodja', value: 'KH', isDisabled: false },
  { label: 'Canada', value: 'CA', isDisabled: false },
  { label: 'Caribisch Nederland', value: 'BQ', isDisabled: false },
  {
    label: 'Centraal-Afrikaanse Republiek',
    value: 'CF',
    isDisabled: false
  },
  { label: 'Chili', value: 'CL', isDisabled: false },
  { label: 'China', value: 'CN', isDisabled: false },
  { label: 'Christmaseiland', value: 'CX', isDisabled: false },
  { label: 'Cocoseilanden', value: 'CC', isDisabled: false },
  { label: 'Colombia', value: 'CO', isDisabled: false },
  { label: 'Comoren', value: 'KM', isDisabled: false },
  { label: 'Cookeilanden', value: 'CK', isDisabled: false },
  { label: 'Costa Rica', value: 'CR', isDisabled: false },
  { label: 'Curaçao', value: 'CW', isDisabled: false },
  { label: 'Cyprus', value: 'CY', isDisabled: false },
  { label: 'Dem. Rep. van Congo', value: 'CD', isDisabled: false },
  {
    label: 'Democratische Volksrepubliek Laos',
    value: 'LA',
    isDisabled: false
  },
  { label: 'Denemarken', value: 'DK', isDisabled: false },
  { label: 'Djibouti', value: 'DJ', isDisabled: false },
  { label: 'Dominica', value: 'DM', isDisabled: false },
  { label: 'Dominicaanse Republiek', value: 'DO', isDisabled: false },
  { label: 'Duitsland', value: 'DE', isDisabled: false },
  { label: 'Ecuador', value: 'EC', isDisabled: false },
  { label: 'Egypte', value: 'EG', isDisabled: false },
  { label: 'El Salvador', value: 'SV', isDisabled: false },
  { label: 'Equatoriaal-Guinea', value: 'GQ', isDisabled: false },
  { label: 'Eritrea', value: 'ER', isDisabled: false },
  { label: 'Estland', value: 'EE', isDisabled: false },
  { label: 'Ethiopië', value: 'ET', isDisabled: false },
  { label: 'Faeröer', value: 'FO', isDisabled: false },
  { label: 'Falklandeilanden', value: 'FK', isDisabled: false },
  { label: 'Fiji', value: 'FJ', isDisabled: false },
  { label: 'Filipijnen', value: 'PH', isDisabled: false },
  { label: 'Finland', value: 'FI', isDisabled: false },
  { label: 'Frankrijk', value: 'FR', isDisabled: false },
  { label: 'Frans-Guyana', value: 'GF', isDisabled: false },
  { label: 'Frans-Polynesië', value: 'PF', isDisabled: false },
  {
    label: 'Franse Zuidelijke en Antarctische Gebieden',
    value: 'TF',
    isDisabled: false
  },
  { label: 'Gabon', value: 'GA', isDisabled: false },
  { label: 'Gambia', value: 'GM', isDisabled: false },
  { label: 'Georgië', value: 'GE', isDisabled: false },
  { label: 'Ghana', value: 'GH', isDisabled: false },
  { label: 'Gibraltar', value: 'GI', isDisabled: false },
  { label: 'Grenada', value: 'GD', isDisabled: false },
  { label: 'Griekenland', value: 'GR', isDisabled: false },
  { label: 'Groenland', value: 'GL', isDisabled: false },
  { label: 'Guadeloupe', value: 'GP', isDisabled: false },
  { label: 'Guam', value: 'GU', isDisabled: false },
  { label: 'Guatemala', value: 'GT', isDisabled: false },
  { label: 'Guernsey', value: 'GG', isDisabled: false },
  { label: 'Guinee', value: 'GN', isDisabled: false },
  { label: 'Guinee-Bissau', value: 'GW', isDisabled: false },
  { label: 'Guyana', value: 'GY', isDisabled: false },
  { label: 'Haïti', value: 'HT', isDisabled: false },
  {
    label: 'Heard en McDonaldeilanden',
    value: 'HM',
    isDisabled: false
  },
  { label: 'Honduras', value: 'HN', isDisabled: false },
  { label: 'Hongarije', value: 'HU', isDisabled: false },
  { label: 'Hongkong', value: 'HK', isDisabled: false },
  { label: 'IJsland', value: 'IS', isDisabled: false },
  { label: 'Ierland', value: 'IE', isDisabled: false },
  { label: 'India', value: 'IN', isDisabled: false },
  { label: 'Indonesië', value: 'ID', isDisabled: false },
  { label: 'Irak', value: 'IQ', isDisabled: false },
  { label: 'Isle of Man', value: 'IM', isDisabled: false },
  { label: 'Israël', value: 'IL', isDisabled: false },
  { label: 'Italië', value: 'IT', isDisabled: false },
  { label: 'Ivoorkust', value: 'CI', isDisabled: false },
  { label: 'Jamaica', value: 'JP', isDisabled: false },
  { label: 'Japan', value: 'JP', isDisabled: false },
  { label: 'Jemen', value: 'YE', isDisabled: false },
  { label: 'Jersey', value: 'JE', isDisabled: false },
  { label: 'Jordanië', value: 'JO', isDisabled: false },
  { label: 'Kaaimaneilanden', value: 'KY', isDisabled: false },
  { label: 'Kaapverdië', value: 'CV', isDisabled: false },
  { label: 'Kameroen', value: 'CM', isDisabled: false },
  { label: 'Kazachstan', value: 'KZ', isDisabled: false },
  { label: 'Kenia', value: 'KE', isDisabled: false },
  { label: 'Kirgizië', value: 'KG', isDisabled: false },
  { label: 'Kiribati', value: 'KI', isDisabled: false },
  {
    label: 'Kleine Pacifische eilanden of USA',
    value: 'UM',
    isDisabled: false
  },
  { label: 'Koeweit', value: 'KW', isDisabled: false },
  { label: 'Kroatië', value: 'HR', isDisabled: false },
  { label: 'Lesotho', value: 'LS', isDisabled: false },
  { label: 'Letland', value: 'LV', isDisabled: false },
  { label: 'Libanon', value: 'LB', isDisabled: false },
  { label: 'Liberia', value: 'LR', isDisabled: false },
  { label: 'Libië', value: 'LY', isDisabled: false },
  { label: 'Liechtenstein', value: 'LI', isDisabled: false },
  { label: 'Litouwen', value: 'LT', isDisabled: false },
  { label: 'Luxemburg', value: 'LU', isDisabled: false },
  { label: 'Macau', value: 'MO', isDisabled: false },
  { label: 'Madagaskar', value: 'MG', isDisabled: false },
  { label: 'Malawi', value: 'MW', isDisabled: false },
  { label: 'Maldiven', value: 'MV', isDisabled: false },
  { label: 'Maleisië', value: 'MY', isDisabled: false },
  { label: 'Mali', value: 'ML', isDisabled: false },
  { label: 'Malta', value: 'MT', isDisabled: false },
  { label: 'Marokko', value: 'MA', isDisabled: false },
  { label: 'Marshalleilanden', value: 'MH', isDisabled: false },
  { label: 'Martinique', value: 'MQ', isDisabled: false },
  { label: 'Mauritanië', value: 'MR', isDisabled: false },
  { label: 'Mauritius', value: 'MU', isDisabled: false },
  { label: 'Mayotte', value: 'YT', isDisabled: false },
  { label: 'Mexico', value: 'MX', isDisabled: false },
  { label: 'Micronesia', value: 'FM', isDisabled: false },
  { label: 'Moldavië', value: 'MD', isDisabled: false },
  { label: 'Monaco', value: 'MC', isDisabled: false },
  { label: 'Mongolië', value: 'MN', isDisabled: false },
  { label: 'Montserrat', value: 'MS', isDisabled: false },
  { label: 'Mozambique', value: 'MZ', isDisabled: false },
  { label: 'Myanmar', value: 'MM', isDisabled: false },
  { label: 'Namibië', value: 'NA', isDisabled: false },
  { label: 'Nauru', value: 'NR', isDisabled: false },
  { label: 'Nederland', value: 'NL', isDisabled: false },
  { label: 'Nepal', value: 'NP', isDisabled: false },
  { label: 'Nicaragua', value: 'NI', isDisabled: false },
  { label: 'Nieuw-Caledonië', value: 'NC', isDisabled: false },
  { label: 'Nieuw-Zeeland', value: 'NZ', isDisabled: false },
  { label: 'Niger', value: 'NE', isDisabled: false },
  { label: 'Nigeria', value: 'NG', isDisabled: false },
  { label: 'Niue', value: 'NU', isDisabled: false },
  { label: 'Noordelijke Marianen', value: 'MP', isDisabled: false },
  { label: 'Noorwegen', value: 'NO', isDisabled: false },
  { label: 'Norfolk', value: 'NF', isDisabled: false },
  { label: 'Oeganda', value: 'UG', isDisabled: false },
  { label: 'Oekraïne', value: 'UA', isDisabled: false },
  { label: 'Oezbekistan', value: 'UZ', isDisabled: false },
  { label: 'Oman', value: 'OM', isDisabled: false },
  { label: 'Oost-Timor', value: 'TL', isDisabled: false },
  { label: 'Oostenrijk', value: 'AT', isDisabled: false },
  { label: 'Pakistan', value: 'PK', isDisabled: false },
  { label: 'Palau', value: 'PW', isDisabled: false },
  { label: 'Palestina', value: 'PS', isDisabled: false },
  { label: 'Panama', value: 'PA', isDisabled: false },
  { label: 'Papoea-Nieuw-Guinea', value: 'PG', isDisabled: false },
  { label: 'Paraguay', value: 'PY', isDisabled: false },
  { label: 'Peru', value: 'PE', isDisabled: false },
  { label: 'Pitcairneilanden', value: 'PN', isDisabled: false },
  { label: 'Polen', value: 'PL', isDisabled: false },
  { label: 'Portugal', value: 'PT', isDisabled: false },
  { label: 'Puerto Rico', value: 'PR', isDisabled: false },
  { label: 'Qatar', value: 'QA', isDisabled: false },
  { label: 'Roemenië', value: 'RO', isDisabled: false },
  { label: 'Rusland', value: 'RU', isDisabled: false },
  { label: 'Rwanda', value: 'RW', isDisabled: false },
  { label: 'Réunion', value: 'RE', isDisabled: false },
  { label: 'Saint Kitts en Nevis', value: 'KN', isDisabled: false },
  { label: 'Saint Lucia', value: 'LC', isDisabled: false },
  {
    label: 'Saint Vincent en de Grenadines',
    value: 'VC',
    isDisabled: false
  },
  { label: 'Saint-Barthélemy', value: 'BL', isDisabled: false },
  { label: 'Saint-Pierre en Miquelon', value: 'PM', isDisabled: false },
  { label: 'Salomonseilanden', value: 'SB', isDisabled: false },
  { label: 'Samoa', value: 'WS', isDisabled: false },
  { label: 'San Marino', value: 'SM', isDisabled: false },
  { label: 'Sao Tomé en Principe', value: 'ST', isDisabled: false },
  { label: 'Saoedi-Arabië', value: 'SA', isDisabled: false },
  { label: 'Senegal', value: 'SN', isDisabled: false },
  { label: 'Seychellen', value: 'SC', isDisabled: false },
  { label: 'Sierra Leone', value: 'SL', isDisabled: false },
  { label: 'Singapore', value: 'SG', isDisabled: false },
  { label: 'Sint-Helena', value: 'SH', isDisabled: false },
  { label: 'Slovenië', value: 'SI', isDisabled: false },
  { label: 'Slowakije', value: 'SK', isDisabled: false },
  { label: 'Somalië', value: 'SO', isDisabled: false },
  { label: 'Spanje', value: 'ES', isDisabled: false },
  { label: 'Spitsbergen en Jan Mayen', value: 'SJ', isDisabled: false },
  { label: 'Sri Lanka', value: 'LK', isDisabled: false },
  { label: 'St. Maarten', value: 'SX', isDisabled: false },
  { label: 'Suriname', value: 'SR', isDisabled: false },
  { label: 'Swaziland', value: 'SZ', isDisabled: false },
  { label: 'Tadzjikistan', value: 'TJ', isDisabled: false },
  { label: 'Taiwan', value: 'TW', isDisabled: false },
  { label: 'Tanzania', value: 'TZ', isDisabled: false },
  { label: 'Thailand', value: 'TH', isDisabled: false },
  { label: 'Togo', value: 'TG', isDisabled: false },
  { label: 'Tokelau-eilanden', value: 'TK', isDisabled: false },
  { label: 'Tonga', value: 'TO', isDisabled: false },
  { label: 'Trinidad en Tobago', value: 'TT', isDisabled: false },
  { label: 'Tsjaad', value: 'TD', isDisabled: false },
  { label: 'Tsjechië', value: 'CZ', isDisabled: false },
  { label: 'Tunesië', value: 'TN', isDisabled: false },
  { label: 'Turkije', value: 'TR', isDisabled: false },
  { label: 'Turkmenistan', value: 'TM', isDisabled: false },
  { label: 'Turks- en Caicoseilanden', value: 'TC', isDisabled: false },
  { label: 'Tuvalu', value: 'TV', isDisabled: false },
  { label: 'Uruguay', value: 'UY', isDisabled: false },
  { label: 'Vanuatu', value: 'VU', isDisabled: false },
  { label: 'Vaticaanstad', value: 'VA', isDisabled: false },
  { label: 'Venezuela', value: 'VE', isDisabled: false },
  { label: 'Verenigd Koninkrijk', value: 'GB', isDisabled: false },
  {
    label: 'Verenigde Arabische Emiraten',
    value: 'AE',
    isDisabled: false
  },
  { label: 'Vietnam', value: 'VN', isDisabled: false },
  { label: 'Wallis en Futuna', value: 'WF', isDisabled: false },
  { label: 'Westelijke Sahara', value: 'EH', isDisabled: false },
  { label: 'Wit-Rusland', value: 'BY', isDisabled: false },
  { label: 'Zambia', value: 'ZM', isDisabled: false },
  { label: 'Zimbabwe', value: 'ZW', isDisabled: false },
  { label: 'Zuid-Afrika', value: 'ZA', isDisabled: false },
  {
    label: 'Zuid-Georgië en de Zuidelijke Sandwicheilanden',
    value: 'GS',
    isDisabled: false
  },
  { label: 'Zuid-Korea', value: 'KR', isDisabled: false },
  { label: 'Zuid-Soedan', value: 'SS', isDisabled: false },
  { label: 'Zweden', value: 'SE', isDisabled: false },
  { label: 'Zwitserland', value: 'CH', isDisabled: false }
];
