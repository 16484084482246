import plus from 'assets/icons/plus.svg';
import add_person from 'assets/icons/add_person.svg';
import {
  ACTIVE_TOKEN,
  ACTIVE_USER_ID,
  COLLATERAL_DATA,
  NAME_MAX_LENGTH,
  NAME_SUFFIX_DOTS,
  ROLE_ADMIN,
  ROLE_DCMF,
  ROLE_MIXED_USE,
} from 'constants/constants';
import { DropdownOptionsModel } from 'models/dropdownOptionsModel';
import {
  COLLATERAL_DROPDOWN_OPTIONS,
  COLLATERAL_DROPDOWN_OPTIONS_FREE_TARIFF,
  COLLATERAL_DROPDOWN_OPTIONS_NON_FREE_TARIFF,
  COLLATERAL_DROPDOWN_OPTIONS_SUBTYPES,
} from 'constants/dropdownListData';
import { CollateralModel } from 'models/collateralModel';
import { useKeycloak } from '@react-keycloak/web';
import { PersonModel } from 'models/personModel';

export const getNextButtonClass = (objectCount: number): string => {
  if (objectCount > 0) {
    return '';
  }

  return 'hidden';
};

export const getIcon = (icon: string) => {
  switch (icon) {
    case 'plus':
      return plus;
    case 'add_person':
      return add_person;
  }
};

export const getSubTypeCollateralOptions = (
  isEditing: boolean = false, 
  currentSelectedType: DropdownOptionsModel | undefined = undefined
): DropdownOptionsModel[] => {
  let collaterals: CollateralModel[] = [];
  const savedValues = sessionStorage.getItem(COLLATERAL_DATA) || '';
  if (savedValues !== '') {
    collaterals = JSON.parse(savedValues);
  }

  let allOptions = new Array<DropdownOptionsModel>();
  let tariffOptions = new Array<DropdownOptionsModel>();

  // Make deep copies of the options
  COLLATERAL_DROPDOWN_OPTIONS_SUBTYPES.forEach((option) =>
    allOptions.push({
      value: option.value,
      label: option.label,
      isDisabled: option.isDisabled,
    })
  );
  COLLATERAL_DROPDOWN_OPTIONS_FREE_TARIFF.forEach((option) =>
    tariffOptions.push({
      value: option.value,
      label: option.label,
      isDisabled: option.isDisabled,
    })
  );

  if (currentSelectedType) {
    // Check if the currently selected typeOption is a tarrif then only enable non-tariff options
    const typeIsTariff = tariffOptions.some((option) => option.value === currentSelectedType.value);
    if (typeIsTariff) {
      for (var i = 0; i < allOptions.length; i++) {
        allOptions[i].isDisabled = tariffOptions.some((option) => option.value === allOptions[i].value);
      }
      return allOptions;
    }
  }

  // If this is the first collateral or user is editing the only collateral, return all the options
  if (collaterals.length < 1 || (collaterals.length === 1 && isEditing)) {
    return allOptions;
  }

  let targetCollateral = collaterals[0];

  for (var i = 0; i < allOptions.length; i++) {
    allOptions[i].isDisabled = !(targetCollateral.subtypeCollateral.value === allOptions[i].value);
  }

  return allOptions;
}

export const getTypeCollateralOptions = (
  skipKeycloakHook: boolean = false, 
  isEditing: boolean = false, 
  currentSelectedSubType: DropdownOptionsModel | undefined = undefined
) => {
  let collaterals: CollateralModel[] = [];
  const savedValues = sessionStorage.getItem(COLLATERAL_DATA) || '';
  if (savedValues !== '') {
    collaterals = JSON.parse(savedValues);
  }

  let allOptions = new Array<DropdownOptionsModel>();
  let tariffOptions = new Array<DropdownOptionsModel>();
  let nonTariffOptions = new Array<DropdownOptionsModel>();
  let currentOptions = new Array<DropdownOptionsModel>();

  // Make deep copies of the options
  COLLATERAL_DROPDOWN_OPTIONS.forEach((option) =>
    allOptions.push({
      value: option.value,
      label: option.label,
      isDisabled: option.isDisabled,
    })
  );
  COLLATERAL_DROPDOWN_OPTIONS_FREE_TARIFF.forEach((option) =>
    tariffOptions.push({
      value: option.value,
      label: option.label,
      isDisabled: option.isDisabled,
    })
  );
  COLLATERAL_DROPDOWN_OPTIONS_NON_FREE_TARIFF.forEach((option) =>
    nonTariffOptions.push({
      value: option.value,
      label: option.label,
      isDisabled: option.isDisabled,
    })
  );
  COLLATERAL_DROPDOWN_OPTIONS.forEach((option) => {
    return currentOptions.push({
      value: option.value,
      label: option.label,
      isDisabled: option.isDisabled,
    });
  });

  // Check if there are collaterals already defined and take the first one as a base model
  let targetCollateral: CollateralModel | undefined = undefined;
  if (collaterals.length > 0) {
    targetCollateral = collaterals[0];
  }

  // Set the allowed options to the non-tariff options or single tariff option
  if (targetCollateral && !(collaterals.length === 1 && isEditing)) {
    if (tariffOptions.some((option) => option.value === targetCollateral?.typeCollateral.value)) {
      currentOptions = tariffOptions.filter((option) => option.value === targetCollateral?.typeCollateral.value);
    } else {
      currentOptions = nonTariffOptions;
    }

    // Set options to enabled or disabled based on the returnOptions
    for (var i = 0; i < allOptions.length; i++) {
      allOptions[i].isDisabled = !currentOptions.some((option) => option.value === allOptions[i].value);
    }
  } else if (currentSelectedSubType) {
    if (currentSelectedSubType) {
      // Check if the currently selected subTypeOption is a tarrif then only enable non-tariff options
      const subTypeIsTariff = tariffOptions.some((option) => option.value === currentSelectedSubType.value)
      if (subTypeIsTariff) {
        currentOptions = nonTariffOptions;
      }
    }
    
    // Set options to enabled or disabled based on the returnOptions
    for (var i = 0; i < allOptions.length; i++) {
      allOptions[i].isDisabled = !currentOptions.some((option) => option.value === allOptions[i].value);
    }
  }

  var mixedUseCollateral = (collaterals.length > 1 && collaterals.some((collateral) => collateral.typeCollateral.label !== COLLATERAL_DROPDOWN_OPTIONS[4].label));

  if ((!skipKeycloakHook && !IsMixedUseUser()) || mixedUseCollateral) {
    allOptions[allOptions.findIndex((option) => option.label === 'Combipand')].isDisabled = true;
  }

  if (!skipKeycloakHook && !(IsAdmin() || IsDCMF())) {
    allOptions[allOptions.findIndex((option) => option.label === 'Recreatiewoning')].isDisabled = true;
  }

  return allOptions;
};

export const IsAdmin = (): boolean => {
  const { keycloak } = useKeycloak();

  let roles = keycloak.tokenParsed?.realm_access?.roles;
  let isAdmin = false;

  window.localStorage.setItem(ACTIVE_USER_ID, keycloak.tokenParsed?.sub + '');

  roles?.forEach((role) => {
    if (role === ROLE_ADMIN) {
      window.localStorage.setItem(ACTIVE_TOKEN, keycloak.token!.toString());
      isAdmin = true;
    }
  });

  return isAdmin;
};

export const IsDCMF = (): boolean => {
  const { keycloak } = useKeycloak();

  let roles = keycloak.tokenParsed?.realm_access?.roles;
  let isDCMF = false;

  window.localStorage.setItem(ACTIVE_USER_ID, keycloak.tokenParsed?.sub + '');

  roles?.forEach((role) => {
    if (role === ROLE_DCMF) {
      window.localStorage.setItem(ACTIVE_TOKEN, keycloak.token!.toString());
      isDCMF = true;
    }
  });

  return isDCMF;
};

const IsMixedUseUser = (): boolean => {
  const { keycloak } = useKeycloak();

  let roles = keycloak.tokenParsed?.realm_access?.roles;
  let isMixedUseUser = false;

  window.localStorage.setItem(ACTIVE_USER_ID, keycloak.tokenParsed?.sub + '');

  roles?.forEach((role) => {
    if (role === ROLE_MIXED_USE) {
      window.localStorage.setItem(ACTIVE_TOKEN, keycloak.token!.toString());
      isMixedUseUser = true;
    }
  });

  return isMixedUseUser;
};

export const GetFullNameForPerson = (person: PersonModel): string => {
  const personFullName = `${person.initials} ${person.lastNameInsertion} ${person.lastName}`;
  return personFullName.length > NAME_MAX_LENGTH ? personFullName.substring(0, NAME_MAX_LENGTH) + NAME_SUFFIX_DOTS : personFullName;
};
