import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { useState } from "react";
import { CollateralModel } from "models/collateralModel";
import { getNextButtonClass } from "helpers/helpers";
import ObjectCollateralCreateButton from "components/ObjectCollateralCreateButton";
import { IWizardStepProps } from "interfaces/IWizardStepProps";
import { COLLATERAL_DATA } from "constants/constants";
import { Link, useNavigate } from "react-router-dom";
import { PAGE_CREATE_COLLATERAL } from "constants/pageConstants";
import ObjectDataView from "components/views/ObjectDataView";
import Footer from "components/footer/Footer";
import { useTranslation } from "react-i18next";

const WizardStepTwo = ({
  clickNextHandler,
  clickBackHandler,
  clickOnNavStepHandler,
}: IWizardStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, { keyPrefix: "wizard-step-two" });

  const savedValue = sessionStorage.getItem(COLLATERAL_DATA) || "";
  let collaterals: CollateralModel[] = [];

  if (savedValue !== "") {
    collaterals = JSON.parse(savedValue);
  }
  const [getValue, setValue] = useState<CollateralModel[]>(collaterals);

  const clickEditHandler = (index: number) => {
    navigate({
      pathname: "/create_collateral",
      search: "?index=" + index,
    });
  };

  const clickDeleteHandler = (index: number) => {
    const collaterals = getValue;

    collaterals.splice(index, 1);
    setValue([...collaterals]);
    sessionStorage.setItem(COLLATERAL_DATA, JSON.stringify(collaterals));
  };

  return (
    <div>
      <div className="center">
        <p className="step_title">{t("collaterals")}</p>
        <div className="scroll_container_content">
          <div className="content">
            {getValue.map(
              (
                { streetName, houseNumber, addition, city, key }: any,
                index
              ) => {
                return (
                  <ObjectDataView
                    index={index}
                    title={getValue[index].typeCollateral.label}
                    subtitle={`${streetName} ${houseNumber}${addition} te ${city}`}
                    key={key}
                    clickEditEvent={clickEditHandler}
                    clickDeleteEvent={clickDeleteHandler}
                  />
                );
              }
            )}
          </div>
          <Link to={PAGE_CREATE_COLLATERAL} className="button_link">
            <ObjectCollateralCreateButton
              text={t("add-collateral")}
              icon="plus"
            />
          </Link>

          <div className="button_next_container">
            <div className="nav_button button_back" onClick={clickBackHandler}>
              <p className="nav_button_text button_back_text">
                {t("previous")}
              </p>
            </div>
            <div
              className={
                "nav_button button_next " + getNextButtonClass(getValue.length)
              }
              onClick={clickNextHandler}
            >
              <p className="nav_button_text button_next_text">{t("next")}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer value={1} clickOnNavStepHandler={clickOnNavStepHandler} />
    </div>
  );
};

export default WizardStepTwo;
